///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/edit"/>
import * as $ from "jquery";
import * as _ from "underscore";
import { WizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/lib/ajax";
import BulkEditorTab = require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkEditTab");
import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";
class BulkAdminEditEntityWizardStep extends WizardStep<any> {
    Tabs: ko.ObservableArray<any>;    
    EntityType: any;
    EntityTypeName: any;
    EntityId: any;
    EntitiesIds: any;
    EntitiesCount: any;
    Wizard: any;
    
    constructor(editWizard: { entityType: string; entityId: any; entitiesIds: string | any[]; }){
        super(globalization.getLabelTranslation("#Core/edit"), "Core:templates/Administration/Bulk/Edit.html");
        this.Tabs = ko.observableArray([]);
        this.EntityType = editWizard.entityType;
        this.EntityTypeName = globalization.getPropertyTranslation('#' + editWizard.entityType);
        this.EntityId = editWizard.entityId;
        this.EntitiesIds = editWizard.entitiesIds;
        this.EntitiesCount = editWizard.entitiesIds.length;
        this.Wizard = editWizard;
        this.load();
    }

    onOpenBack() {
        _(this.Tabs()).each((tab) => {
            const tabModule = tab.modules[tab.tabId];
            if (tabModule && tabModule.onOpenBack) {
                tabModule.onOpenBack();
            }
        });
    }

    load() {
        if (this.Tabs().length > 0) {
            return $.Deferred().resolve(this.Tabs());
        }

        return this.loadTabs().pipe((tabs: any) => {
            return this.parseTabs(tabs);
        });
    }
    
    loadTabs() {
        if (isNullOrUndefined(this.EntityType)) {
            return $.Deferred().reject();
        }

        return rpc.ajax("SettingsManager", "GetTabs", {
            data: serialization.toJSON({
                modelType: this.EntityType
            })
        });
    }
    
    parseTabs(adminTabs: any) {
        const adminTabInfo = _.map(adminTabs, (tabA) => {
            const type = isNullOrUndefined(tabA.RelatedType) ? tabA.ForType : tabA.RelatedType;
            return tabA.ViewName + "_" + type;
        });

        return ajax("BulkAdminTemplate/ExistsBulkAdminView", {
            data: { viewInfo: adminTabInfo },
            traditional: true
        }).pipe((bulkAdminTabNames: any) => {
            bulkAdminTabNames.push("IdentityProviders");
            const tabs = _.map(bulkAdminTabNames, (bulkTabName) => {
                const adminTab = _.where(adminTabs, { ViewName: bulkTabName });
                const bulkAdminTab = new BulkEditorTab(adminTab[0]);
                bulkAdminTab.tabViewModel = this.Wizard.DefaultViewModel;

                return bulkAdminTab;
            });
            this.Tabs(tabs);
            return tabs;
        });
    }
    
    validate() {
        let validationResult = true;
        
        _(this.Tabs()).each((tab) => {
            const tabModule = tab.modules[tab.tabId];
            if (tabModule && tabModule.validate) {
                validationResult = validationResult && tabModule.validate();
            }
        });
       
        return validationResult;
    }
    
    save(container: any) {
        _(this.Tabs()).each((tab) => {
            const tabModule = tab.modules[tab.tabId];
            if (tabModule && tabModule.save) {
                tabModule.save(container);
            }
        });
    }
    
    dispose() {
        _(this.Tabs()).each((tab) => {
            const tabModules = tab.modules || [];

            _(tabModules).each((tabModule) => {
                if (tabModule && tabModule.dispose) {
                    tabModule.dispose();
                }
            });
            
            tab.modules = [];
        });
    }
}

export function create(editWizard: { entityType: string; entityId: any; entitiesIds: string | any[]; }) {
    return new BulkAdminEditEntityWizardStep(editWizard);
}