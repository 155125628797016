/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/reactIntegration/reactBinding"/>
import * as ko from "knockout";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { createRoot, Root } from "react-dom/client";
import * as propsValidator from "./propsValidator";
import * as mode from "../development/mode";
import { ReactBindingParams } from "./reactBindingParams";
import { isSetTimeoutOnReactBindingEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

/*
 * props should not contain any Knockout observables to reduce accidental complexity of React/Knockout interoperability handling
 * in scenarios like shallow state comparisons or when using PureComponents.
 *
 * There are other ways of communication that could be used instead, depending on use case:
 *  - eventBus
 *  - Rendering root component *after* all needed data is fetched so we can pass final state of data in props
 *  - passing callback method to props (as property)
 *  - getState (if state is needed by view model above React component)
 */

const rootList = new Map<any, Root>();
function getRoot(el:any) {
    if(!rootList.has(el)){
        rootList.set(el, createRoot(el));
    }
    return rootList.get(el);
}

export function register() {
    ko.bindingHandlers.react = {
        init(el: any, valueAccessor: any) {
            const config = ko.unwrap(valueAccessor()) as ReactBindingParams<any, any>;
            return { controlsDescendantBindings: true };
        },

        update(el: any, valueAccessor: any) {
            const config = ko.unwrap(valueAccessor()) as ReactBindingParams<any, any>;

            if (!mode.isProduction() && !propsValidator.isValid(config.props)) {
                throw new Error("Passing observables to react binding is not allowed, as it could cause interoperability problems between React and Knockout");
            }

            if (config.componentClass && config.functionComponent) {
                throw new Error("You need to pass either class component or function one");
            }

            if (config.functionComponent && config.getState) {
                throw new Error("You cannot access state from function component");
            }

            const RootComponent = config.componentClass || config.functionComponent;

            const refFunction = (rootComponent: any) => {
                if (config.componentClass) {
                    config.getState = () => rootComponent.state;
                }
            };

            const root = (
                config.componentClass
                    ? <RootComponent {...config.props} ref={refFunction}></RootComponent>
                    : <RootComponent {...config.props}></RootComponent>
            );

            el.classList.add("react-root");
            const renderRoot = getRoot(el);

            const disposeAndUnmountReact = () => {
                if (config.componentClass) {
                    config.getState = () => ({});
                }        
                renderRoot.unmount();
            };

            ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
                if (isSetTimeoutOnReactBindingEnabled()) {
                    setTimeout(() => disposeAndUnmountReact());
                } else {
                    disposeAndUnmountReact();
                }
            });

            renderRoot.render(root);

            return { controlsDescendantBindings: true };
        }
    };
}
