/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/supplierInvoiceDatesUpdater"/>
import { MediusPercentage } from "Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/paymentTerm";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as ko from "knockout";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");

export interface Entity {
    readonly Id: ko.Observable<number>;
}

export interface Date {
    toJSON(): string;
}

export interface ComputedDate {
    isComputed: boolean;
    computedDate: Date;
    toJSON(): string;
}

export interface SupplierInvoice extends Entity {
    readonly Company: ko.Observable<Entity>;
    readonly Supplier: ko.Observable<Entity>;
    readonly InvoiceDate: ko.Observable<Date>;
    readonly FinalBookingDate: ko.Observable<Date>;
    readonly $type: ko.Observable<string>;
    
    readonly ImportDate: ko.Observable<Date | null>;
    readonly PaymentTerm: ko.Observable<Entity | null>;

    readonly ComputedDueDate: ko.Observable<ComputedDate>;
    readonly ComputedPreferredPaymentDate: ko.Observable<ComputedDate>;
    readonly DueDate: ko.Observable<Date>;
    readonly IsDueDateImported: ko.Observable<boolean>;
    readonly IsPreferredPaymentDateImported: boolean;
    readonly IsDueDateSetManually: ko.Observable<boolean>;
    readonly IsPreferredPaymentDateSetManually: ko.Observable<boolean>;
    readonly PostingAndPaymentDatesAlignedByDefault: ko.Observable<boolean>;
    readonly PreferredPaymentDate: ko.Observable<Date>;
    readonly CashDiscount: MediusPercentage;

    readonly ImportedPreferredPayment: ko.Observable<any>;
    readonly ManuallySetPreferredPayment: ko.Observable<any>;
}

export interface RecalculatedInvoiceDates {
    readonly dueDate: Date;
    readonly preferredPaymentDate: Date;
    readonly cashDiscount: number;
}

export interface Disposable {
    dispose(): void;
}

export class SupplierInvoiceDatesUpdater {
    public static forCreateStep(
        invoice: SupplierInvoice,
        updatePreferredPaymentDate: (invoiceDates: RecalculatedInvoiceDates) => void,
        onPreferredPaymentDateUpdated?: () => void): Disposable {

        return SupplierInvoiceDatesUpdater.create(
            invoice,
            _ => true,
            updatePreferredPaymentDate,
            onPreferredPaymentDateUpdated);
    }

    public static forEdiStep(
        invoice: SupplierInvoice,
        updatePreferredPaymentDate: (invoiceDates: RecalculatedInvoiceDates) => void,
        onPreferredPaymentDateUpdated?: () => void): Disposable {

        return SupplierInvoiceDatesUpdater.create(
            invoice,
            SupplierInvoiceDatesUpdater.preferredPaymentDateUpdateConditionForEdiStep,
            updatePreferredPaymentDate,
            onPreferredPaymentDateUpdated);
    }

    public static forWorkflowStep(
        invoice: SupplierInvoice,
        updatePreferredPaymentDate: (invoiceDates: RecalculatedInvoiceDates) => void,
        onPreferredPaymentDateUpdated?: () => void): Disposable {

        return SupplierInvoiceDatesUpdater.create(
            invoice,
            SupplierInvoiceDatesUpdater.preferredPaymentDateUpdateConditionForWorkflowStep,
            updatePreferredPaymentDate,
            onPreferredPaymentDateUpdated);
    }

    private static create(
        invoice: SupplierInvoice,
        updatePreferredPaymentDateCondition: (invoice: SupplierInvoice) => boolean,
        updatePreferredPaymentDate: (invoiceDates: RecalculatedInvoiceDates) => void,
        onPreferredPaymentDateUpdated?: () => void): Disposable {
        onPreferredPaymentDateUpdated = onPreferredPaymentDateUpdated || (() => { });
        SupplierInvoiceDatesUpdater.ensureExpectedShapeOf(invoice);
        return new Updater(invoice, updatePreferredPaymentDateCondition, updatePreferredPaymentDate, onPreferredPaymentDateUpdated);
    }

    private static preferredPaymentDateUpdateConditionForEdiStep(invoice: SupplierInvoice): boolean {

        if (SupplierInvoiceDatesUpdater.isNotSet(invoice.ImportedPreferredPayment))
            return true;

        const importedPreferredPayment = invoice.ImportedPreferredPayment();
        return SupplierInvoiceDatesUpdater.isNotSet(importedPreferredPayment.Date)
            && SupplierInvoiceDatesUpdater.isNotSet(importedPreferredPayment.Percentage);
    }

    private static preferredPaymentDateUpdateConditionForWorkflowStep(invoice: SupplierInvoice): boolean {
        return SupplierInvoiceDatesUpdater.preferredPaymentDateUpdateConditionForEdiStep(invoice)
            && SupplierInvoiceDatesUpdater.isNotSet(invoice.ManuallySetPreferredPayment);
    }

    private static isNotSet(property: ko.Observable<any>): boolean {
        return !property || property() == null;
    }

    private static ensureExpectedShapeOf(invoice: SupplierInvoice): void {
        function unfulfilledExpectationsAs(what: string, fieldName: string) {
            return new Error(`Expecting to get SupplierInvoice with field: '${fieldName}' as ${what}.`);
        }

        function ensureReadableObservable(field: any, name: string) {
            if (!ko.isObservable(field)) {
                throw unfulfilledExpectationsAs("ReadableObservable", name);
            }
        }

        function ensureWriteableObservable(field: any, name: string) {
            if (!ko.isWriteableObservable(field)) {
                throw unfulfilledExpectationsAs("WriteableObservable", name);
            }
        }

        ensureReadableObservable(invoice.Company, "Company");
        ensureReadableObservable(invoice.Supplier, "Supplier");
        ensureReadableObservable(invoice.InvoiceDate, "InvoiceDate");
        ensureReadableObservable(invoice.FinalBookingDate, "FinalBookingDate");
        ensureReadableObservable(invoice.ImportDate, "ImportDate");
        ensureReadableObservable(invoice.PaymentTerm, "PaymentTerm");
        ensureReadableObservable(invoice.IsDueDateImported, "IsDueDateImported");
        ensureReadableObservable(invoice.ImportedPreferredPayment, "ImportedPreferredPayment");
        ensureWriteableObservable(invoice.ComputedDueDate, "ComputedDueDate");
        ensureWriteableObservable(invoice.PreferredPaymentDate, "PreferredPaymentDate");
        ensureWriteableObservable(invoice.CashDiscount, "CashDiscount");
    }
}

interface Change {
    company?: Entity;
    supplier?: Entity;
    invoiceDate?: Date;
    paymentTerm?: Entity;
    dueDate?: Date;
    finalBookingDate?: Date;
}


class Updater implements Disposable {
    private readonly companySub: ko.Subscription;
    private readonly supplierSub: ko.Subscription;
    private readonly invoiceDateSub: ko.Subscription;
    private readonly paymentTermSub: ko.Subscription;
    private readonly dueDateSub: ko.Subscription;
    private readonly finalBookingDateSub: ko.Subscription;
    private readonly updateDates: (invoiceDates: RecalculatedInvoiceDates) => void;
    private shouldCallForInvoiceDates: boolean;

    public constructor(
        private readonly invoice: SupplierInvoice,
        updatePreferredPaymentDateCondition: (invoice: SupplierInvoice) => boolean,
        updatePreferredPaymentDate: (invoiceDates: RecalculatedInvoiceDates) => void,
        onPreferredPaymentDateAttemptedUpdate: () => void) {

        this.shouldCallForInvoiceDates = true;

        const tryUpdatePreferredPaymentDate = (invoiceDates: any) => {
            if (updatePreferredPaymentDateCondition(this.invoice))
                updatePreferredPaymentDate(invoiceDates);

            onPreferredPaymentDateAttemptedUpdate();
        };

        this.updateDates = this.withoutServiceCall(invoiceDates => {
            if (!this.invoice.IsDueDateImported())
                this.invoice.ComputedDueDate(
                    {
                        isComputed: true,
                        computedDate: invoiceDates.dueDate,
                        toJSON: invoiceDates.dueDate.toJSON
                    });

            tryUpdatePreferredPaymentDate(invoiceDates);
        });

        this.companySub = invoice.Company.subscribe(company => this.handleChangeBy(this.updateDates, this.withDueDate({ company })));
        this.supplierSub = invoice.Supplier.subscribe(supplier => this.handleChangeBy(this.updateDates, this.withDueDate({ supplier })));
        this.invoiceDateSub = invoice.InvoiceDate.subscribe(invoiceDate => this.handleChangeBy(this.updateDates, this.withDueDate({ invoiceDate })));
        this.paymentTermSub = invoice.PaymentTerm.subscribe(paymentTerm => this.handleChangeBy(this.updateDates, this.withDueDate({ paymentTerm })));
        this.dueDateSub = invoice.ComputedDueDate.subscribe(dueDate => this.handleChangeBy(tryUpdatePreferredPaymentDate, { dueDate: !!dueDate ? dueDate : null }));
        this.finalBookingDateSub = invoice.FinalBookingDate.subscribe(finalBookingDate => this.handleChangeBy(this.updateDates, { finalBookingDate: !!finalBookingDate ? finalBookingDate : null }));
    }

    public apply(): void {
        this.handleChangeBy(this.updateDates, {});
    }

    public dispose(): void {
        this.companySub.dispose();
        this.supplierSub.dispose();
        this.invoiceDateSub.dispose();
        this.paymentTermSub.dispose();
        this.dueDateSub.dispose();
        this.finalBookingDateSub.dispose();
    }

    private withDueDate(change: Change): Change {
        change.dueDate = this.invoice.IsDueDateImported()
            ? this.invoice.DueDate()
            : null;

        return change;
    }

    private withoutServiceCall(action: (invoiceDates: RecalculatedInvoiceDates) => void) {
        return (invoiceDates: RecalculatedInvoiceDates) => {
            this.shouldCallForInvoiceDates = false;
            action(invoiceDates);
            this.shouldCallForInvoiceDates = true;
        };
    }

    private handleChangeBy(onResponse: (invoiceDates: RecalculatedInvoiceDates) => void, change: Change): void {
        const shouldResetPaymentDatesManuallySet = !!change.company || !!change.supplier;
        change.company = change.company || this.invoice.Company();
        change.supplier = change.supplier || this.invoice.Supplier();
        change.invoiceDate = change.invoiceDate || this.invoice.InvoiceDate();
        change.paymentTerm = change.paymentTerm || this.invoice.PaymentTerm();

        if (!this.isSufficientForRequest(change)) {
            return;
        }

        if (this.shouldCallForInvoiceDates) {
            if(shouldResetPaymentDatesManuallySet)
            {
                this.invoice.IsDueDateSetManually(false);
                this.invoice.IsPreferredPaymentDateSetManually(false);
            }

            this.requestDatesBy(change)
                .done(onResponse);
        }
    }

    private requestDatesBy(change: Change): JQueryPromise<RecalculatedInvoiceDates> {
        const companyId = change.company.Id();
        const supplierId = change.supplier.Id();
        const invoiceDate = change.invoiceDate.toJSON();
        
        let request: JQueryXHR;

        if(this.isNewDocument()){
            const dueDate = !!change.dueDate ? change.dueDate.toJSON() : "";
            const invoiceType = type.getTypeName(this.invoice.$type());
            const finalBookingDate = this.invoice.FinalBookingDate().toJSON();
            const preferredPaymentDateSetManuallyOrImported = this.invoice.ComputedPreferredPaymentDate()?.toJSON();
            const dueDateSetManuallyOrImported = this.invoice.ComputedDueDate()?.toJSON();
            const isDueDateSetManuallyOrImported = this.invoice.IsDueDateSetManually() || this.invoice.IsDueDateImported();
            const isPreferredPaymentDateSetManuallyOrImported = this.invoice.IsPreferredPaymentDateSetManually() || this.invoice.IsPreferredPaymentDateImported;

            request = rest.get("supplier", `${supplierId}/payment-term/invoice-dates?companyId=${companyId}&invoiceDate=${invoiceDate}&dueDate=${dueDate}&invoiceType=${invoiceType}&finalBookingDate=${finalBookingDate}`
                +`&preferredPaymentDateSetManuallyOrImported=${preferredPaymentDateSetManuallyOrImported}&dueDateSetManuallyOrImported=${dueDateSetManuallyOrImported}`
                +`&isDueDateSetManuallyOrImported=${isDueDateSetManuallyOrImported}&isPreferredPaymentDateSetManuallyOrImported=${isPreferredPaymentDateSetManuallyOrImported}`);
        }
        else{
            request = rest.get("invoice", `${this.invoice.Id()}/dates?supplierId=${supplierId}&companyId=${companyId}&invoiceDate=${invoiceDate}&paymentTermId=${change.paymentTerm.Id()}`);
        }

        return request.then(
            data => data as RecalculatedInvoiceDates,
            xhr => backendErrorHandler.handleAnyError(xhr));
    }

    private isValidEntity(entity: Entity): boolean {
        return entity && !!(entity.Id());
    }

    private isNewDocument(): boolean {
        return !this.isValidEntity(this.invoice);
    }

    private isSufficientForRequest(change: Change): boolean {
        return this.isValidEntity(change.company)
            && this.isValidEntity(change.supplier)
            && change.invoiceDate != null
            && (this.isValidEntity(change.paymentTerm)
            || this.isNewDocument())
            && (!change.finalBookingDate || (this.isNewDocument() && this.invoice.PostingAndPaymentDatesAlignedByDefault()));
    }
}
