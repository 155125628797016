///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/BulkAdminIdentityProviders"/>
import * as ko from "knockout";
import {BulkAdminIdentityProvidersComponent} from "./BulkAdminIdentityProvidersComponent";

class BulkAdminIdentityProviders {
    componentConfig: ko.Computed;
    enableIdentityProvider: boolean = false;
    disableIdentityProvider: boolean = false;
    
    handleEnableIdentityProviderChanged = (enableIdentityProvider: boolean) =>{
        this.enableIdentityProvider = enableIdentityProvider;
    }

    handleDisableIdentityProviderChanged = (disableIdentityProvider: boolean) =>{
        this.disableIdentityProvider = disableIdentityProvider;
    }

    constructor() {
        this.componentConfig = ko.computed(() => {
            return ({
                functionComponent: BulkAdminIdentityProvidersComponent,
                props: {
                    onEnableIdentityProviderChanged: this.handleEnableIdentityProviderChanged, 
                    onDisableIdentityProviderChanged: this.handleDisableIdentityProviderChanged,
                }
            })
        });
    }
    
    validate() {
        return true;
    }

    save(modifications:any[]) {
        if(this.enableIdentityProvider){
            modifications.push({
                Name: "#Core/identityStoreAdded",
                Key: "AddIdentityProvider",
                Value: {}
            });
        }
        else if(this.disableIdentityProvider){
            modifications.push({
                Name: "#Core/identityStoreDeleted",
                Key: "DeleteIdentityProvider",
                Value: {}
            });
        }
    }

    dispose() {
    }
}

export = function(entityIds:any) {
    return new BulkAdminIdentityProviders();
};