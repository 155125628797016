/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/userSettingsSlice"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";

interface UserSettings {
    catalogViewMode: string,
    catalogGridPageSize: number,
    catalogListPageSize: number,
    checkoutNewLineLevelPropertiesNotificationVisible: boolean,
    checkoutMultipleOrdersNotificationVisible: boolean,
    checkoutMultipleOrdersCausedByDeliveryAdressesNotificationVisible: boolean,
    yourOrdersGridSortingField: string,
    yourOrdersGridSortingDir: string
}

const initialState: UserSettings = { 
    catalogViewMode: "Grid", 
    catalogGridPageSize: 12, 
    catalogListPageSize: 16, 
    checkoutNewLineLevelPropertiesNotificationVisible: true,
    checkoutMultipleOrdersNotificationVisible: true,
    checkoutMultipleOrdersCausedByDeliveryAdressesNotificationVisible: true,
    yourOrdersGridSortingField: "requestedDeliveryDate",
    yourOrdersGridSortingDir: "asc"
};

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        setCatalogViewMode: (state, action: PayloadAction<string>) => {
            state.catalogViewMode = action.payload;
        },
        setCatalogGridPageSize: (state, action: PayloadAction<number>) => {
            state.catalogGridPageSize = action.payload;
        },
        setCatalogListPageSize: (state, action: PayloadAction<number>) => {
            state.catalogListPageSize = action.payload;
        },
        setCheckoutNewLineLevelPropertiesNotificationVisible: (state, action: PayloadAction<boolean>) => {
            state.checkoutNewLineLevelPropertiesNotificationVisible = action.payload;
        },
        setCheckoutMultipleOrdersNotificationVisibility: (state, action: PayloadAction<boolean>) => {
            state.checkoutMultipleOrdersNotificationVisible = action.payload;
        },
        setCheckoutMultipleOrdersCausedByDeliveryAddressesNotificationVisibility: (state, action: PayloadAction<boolean>) => {
            state.checkoutMultipleOrdersCausedByDeliveryAdressesNotificationVisible = action.payload;
        },
        setYourOrdersSortingField: (state, action: PayloadAction<string>) => {
            state.yourOrdersGridSortingField = action.payload;
        },
        setYourOrdersSortingDir: (state, action: PayloadAction<string>) => {
            state.yourOrdersGridSortingDir = action.payload;
        }
    }
});

export const { 
    setCatalogViewMode, 
    setCatalogGridPageSize, 
    setCatalogListPageSize,
    setCheckoutNewLineLevelPropertiesNotificationVisible,
    setCheckoutMultipleOrdersNotificationVisibility,
    setCheckoutMultipleOrdersCausedByDeliveryAddressesNotificationVisibility,
    setYourOrdersSortingField,
    setYourOrdersSortingDir
} = userSettingsSlice.actions;

export function userSettingsSelector(s: RootState) { return s.procurement.userSettings; }
