/// <amd-module name="Core/Medius.Core.Web/Scripts/Bifrost/communication"/>

export function notifyAboutDocumentCount(count: number) {
    window.top.postMessage({ version: 1, origin: "Midgard", type: "inbox-count", data: {
        count
    } }, '*');
}

export function notifyOnLoad(){
    window.top.postMessage({ version: 1, origin: "Midgard", type: "loaded", data: {
        application: "APA"
    } }, '*');
}