/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/cart/cartSlice"/>
import * as _ from "underscore";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { WritableDraft } from "immer";
import { ActiveDimension, CompanyConfiguration, ProcurementConfiguration } from "../currentCompany/configuration";
import { Cart, NoteToSupplier, Comment, CartLine, CartItem, CartCodingLine, Address, CartDimensionValue, CartTaxCode, Requisition, CartCategory, CartDetails, CartPurchasePolicyData, CodingLineBudgetPosition, OurReferenceRole } from "./cart";
import { CodingValues, CodingAndTax } from "./coding";
import {
    findCatalogCartLineByProductId, findCartLineByFreeTextItem, findCartLineByPunchoutItem, getCartLineById,
    getCartLineTotal, mapProductToCartLine, mapFreeTextItemToCartLine, mapPunchoutItemToCartLine,
    mapRequsitionLineToCartLine, mapRequisitionLineToCartItem, defaultTaxIndicator, getCartLineIndexForTemplate
} from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cartHelpers";
import { ProductDto } from "./productDto";
import { FreeTextFormField, FreeTextItem } from "./freeTextItem";
import { PunchoutItem } from "./punchoutItem";
import { Attachment, AttachmentUploaded } from "./attachment";
import { PurchaseRequisitionLineSupplierCurrencyValidator, ValidatorConfiguration }
    from "../validatorConfiguration/validatorConfiguration";
import { ResolutionConfigurationDto } from "../resolutionConfiguration/resolutionConfiguration";
import { newCartLineId } from "../cartLineId";
import { compareAddressesWithNullCheck } from "../addressUtils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export type Coding = Readonly<{
    displayed: boolean,
    snapshot: CartLine[] | null,
    focused: number | boolean,
}>;

type Filters = Readonly<{
    showAllItems: boolean,
    showAllLines: boolean
}>;

export type CartValidationErrors = Readonly<{
    discardedItems: string[],
    discardedDetails: string[],
}>;

export type BudgetPosition = Readonly<{
    hasActiveBudgetConfiguration: boolean,
    positions: CodingLineBudgetPosition[],
    isLoading: boolean
}>;

export type CopyRequisitionInformation = Readonly<{
    codingOrTaxCleared: boolean,
    categoryCleared: boolean,
    headerInformationChanged: string
}>;

export type CartWithCoding =
    Readonly<Cart
        & { coding: Coding }
        & { filters: Filters }
        & { validationErrors: CartValidationErrors }
        & { budgetPosition: BudgetPosition }
        & { copyReqInformation: CopyRequisitionInformation }>;


const initialState: CartWithCoding = {
    cartLines: [],
    details: {
        noteToSupplier: undefined,
        deliveryDate: null,
        invoiceAddress: undefined,
        deliveryAddress: undefined,
        comments: [] as Comment[],
        attachments: [] as Attachment[],
        entityViewId: undefined,
        shippingMarks: "",
        ourReferenceRole: undefined,
        orderTypeId: undefined,
        buyerRoleId: undefined,
        goodsReceiptByRoleId: undefined,
        purchasePolicyConfirmationPending: undefined,
        isDeliveryAddressSelectedByUser: false,
        copySource: undefined
    },
    coding: {
        displayed: false,
        snapshot: null,
        focused: false
    },
    filters: {
        showAllItems: true,
        showAllLines: true
    },
    validationErrors: {
        discardedItems: [],
        discardedDetails: [],
    },
    budgetPosition: {
        hasActiveBudgetConfiguration: false,
        positions: [] as CodingLineBudgetPosition[],
        isLoading: false
    },
    copyReqInformation: {
        codingOrTaxCleared: false,
        categoryCleared: false,
        headerInformationChanged: null
    }
};

function modifyExistingItemCategory(cartLine: WritableDraft<CartLine>, payload: CartItemWithCategoryPayload) {
    cartLine.item.category = payload.category;
}

function modifyExistingItemQuantity(cartLine: WritableDraft<CartLine>, modifier: (qty: number) => number) {
    const newQuantity = modifier(cartLine.quantity.value);
    cartLine.quantity.value = newQuantity >= 0 ? newQuantity : 0;

    updateCartLineCodingAmount(cartLine.codingLines, newQuantity * cartLine.item.unitPrice.value, cartLine.amountResolution);
}

function modifyExistingItemAmount(cartLine: WritableDraft<CartLine>, modifier: (amount: number) => number) {
    const newAmount = modifier(cartLine.item.unitPrice.value);
    cartLine.item.unitPrice.value = newAmount >= 0 ? newAmount : 0;

    updateCartLineCodingAmount(cartLine.codingLines, newAmount, cartLine.amountResolution);
}

function modifyCodingLineAmount(cartLine: WritableDraft<CartLine>, payload: CartItemWithCodingLineAmountActionPayload) {
    const codingLine = cartLine.codingLines[payload.codingLineNumber - 1] as WritableDraft<CartCodingLine>;

    updateCartLineCodingAmount(
        [codingLine],
        payload.amount, cartLine.amountResolution);
}

function modifyRemainingAmountToCode(cartLine: WritableDraft<CartLine>, { amount }: CartItemWithAmountActionPayload) {
    cartLine.remainingAmountToCode = amount;
}

function modifyExistingDimensionValues(cartLine: WritableDraft<CartLine>, payload: CartItemWithDimensionValuesActionPayload) {
    const codingLine = cartLine.codingLines[payload.codingLineNumber - 1] as WritableDraft<CartCodingLine>;
    codingLine.dimensionValues = payload.dimensionValues;
}

function modifyExistingDimensionValue(cartLine: WritableDraft<CartLine>, payload: CartItemWithDimensionValueActionPayload) {
    const codingLine = cartLine.codingLines[payload.codingLineNumber - 1] as WritableDraft<CartCodingLine>;
    const dimensionIndex = codingLine.dimensionValues.findIndex(obj => obj.dimensionNumber == payload.dimensionValue.dimensionNumber);
    codingLine.dimensionValues[dimensionIndex] = payload.dimensionValue;
}

function modifyExistingTaxCode(cartLine: WritableDraft<CartLine>, { taxCode }: CartItemWithTaxCodeActionPayload) {
    cartLine.taxCodes = cartLine.taxCodes.map(tc =>
        tc.indicatorNumber == taxCode.indicatorNumber
            ? taxCode : tc);
}

function modifyExistingDeliveryDate(cartLine: WritableDraft<CartLine>, date: string) {
    cartLine.deliveryDate = date;
}

function modifyExistingDeliveryAddress(cartLine: WritableDraft<CartLine>, address: Address) {
    cartLine.deliveryAddress = address;
}

function modifyDatabaseId(cartLine: WritableDraft<CartLine>, databaseId: number) {
    cartLine.databaseId = databaseId;
}

function modifyDimensionApprovalObjectId(cartLine: WritableDraft<CartLine>, dimensionApprovalObjectId: number) {
    cartLine.dimensionApprovalObjectId = dimensionApprovalObjectId;
}

function modifyCartLineDistributionMode(cartLine: WritableDraft<CartLine>, distributeEvenly: boolean) {
    cartLine.distributeAmountEvenly = distributeEvenly;
}

function distributeCartLineAmount(cartLine: WritableDraft<CartLine>, amounts: number[]) {
    const { codingLines, distributeAmountEvenly } = cartLine;

    if (distributeAmountEvenly) {
        for (let i = 0; i < codingLines.length; i++)
            codingLines[i].amount = amounts[i];
    }
}

function updateCartLineCodingAmount(codingLines: CartCodingLine[], newAmount: number, resolution: number) {
    if (codingLines && codingLines.length > 0) {
        (codingLines[0] as WritableDraft<CartCodingLine>).amount = Number(newAmount.toFixed(resolution));
        //TODO: when coding is editable in the checkout, and quantity can also be changed (in checkout/cart), need to handle splits here also!
    }
}

function addNewLineToCoding(cartLine: WritableDraft<CartLine>, amount: number) {
    const splitCodingLine = _.last(cartLine.codingLines);

    cartLine.codingLines.push({
        ...splitCodingLine,
        ...(splitCodingLine.databaseId && {databaseId: 0}),
        amount: amount > 0 ? amount : 0
    });
}

function removeExistingCodingLine(cartLine: WritableDraft<CartLine>, payload: CartItemWithCodingLineActionPayload) {
    cartLine.codingLines.splice(payload.codingLineNumber - 1, 1);
}

function removeExistingCartLine(cartItems: WritableDraft<CartLine[]>, index: number) {
    cartItems.splice(index, 1);
}

function insertNewCartLines(cartItems: WritableDraft<CartLine[]>, index: number, newCartLines: CartLine[]) {
    cartItems.splice(index, 1, ...newCartLines);
}

function addCodingToCartItem(cartLine: WritableDraft<CartLine>, coding: CodingValues[], configuration: CompanyConfiguration) {
    const getDimensionsArray = (preCoding: CodingValues, activeDimensions: ActiveDimension[]): CartDimensionValue[] => {
        const { dimension1, dimension2, dimension3, dimension4, dimension5, dimension6, dimension7,
            dimension8, dimension9, dimension10, dimension11, dimension12, freeText1, freeText2, freeText3, freeText4, freeText5 } = preCoding;

        const dimensionArray = [dimension1, dimension2, dimension3, dimension4, dimension5, dimension6, dimension7, dimension8,
            dimension9, dimension10, dimension11, dimension12];

        const freeTextDimensionArray = [freeText1, freeText2, freeText3, freeText4, freeText5];

        return activeDimensions.map(x => {
            const value = dimensionArray.find(d => d?.dimensionId === x.id);
            if (value)
                return {
                    id: value?.id,
                    value: value?.value,
                    description: value?.description,
                    dimensionNumber: x.id,
                    isFixed: value?.isFixed,
                    isReadOnly: value?.isReadOnly,
                    errors: value?.errors
                };

            const freeTextValue = freeTextDimensionArray.find(d => d?.dimensionId === x.id);
            return {
                id: null,
                value: freeTextValue?.value,
                description: null,
                dimensionNumber: x.id,
                isFixed: freeTextValue?.isFixed,
                isReadOnly: freeTextValue?.isReadOnly,
                errors: freeTextValue?.errors
            };
        });
    };

    const currentDimensionNames = configuration
        ? configuration.activeDimensions.map(x => ({
            dimensionTitle: x.title,
            dimensionName: x.dimensionName,
            dimensionNumber: x.id,
            dimensionMaxLength: x.maxLength
        })) : [];

    const toMap = coding.length > 0 ? coding : [{
        amount: getCartLineTotal(cartLine),
    } as CodingValues];

    cartLine.codingLines = toMap.map(x => ({
        amount: x.amount,
        dimensionNames: currentDimensionNames,
        dimensionValues: getDimensionsArray(x, configuration.activeDimensions)
    }));
}

function modifyExistingItemAdditionalInformation(cartLine: WritableDraft<CartLine>, payload: string) {
    if(payload) {
        cartLine.additionalInformation += " " + payload.trim();
    }
}

export function convertDateTimestampToString(timestamp: number) {
    const dateObject = new Date(timestamp);
    return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate()).toDateString();
}

export type CartItemActionPayload = Readonly<{
    item: CartItem;
}>;

export type CartItemWithQuantityActionPayload = Readonly<{
    item: CartItem;
    quantity: number;
}>;

export type CartItemWithAmountActionPayload = Readonly<{
    item: CartItem;
    amount: number;
}>;

export type CartItemWithCodingLineActionPayload = Readonly<{
    item: CartItem;
    codingLineNumber: number;
}>;

export type CartItemWithCodingLineAmountActionPayload = Readonly<{
    item: CartItem;
    codingLineNumber: number;
    amount: number;
}>;

export type CartItemWithDimensionValuesActionPayload = Readonly<{
    item: CartItem;
    codingLineNumber: number;
    dimensionValues: CartDimensionValue[];
}>;

export type CartItemWithDimensionValueActionPayload = Readonly<{
    item: CartItem;
    codingLineNumber: number;
    dimensionValue: CartDimensionValue;
}>;

export type CartItemWithTaxCodeActionPayload = Readonly<{
    item: CartItem;
    taxCode: CartTaxCode;
}>;

export type CartItemWithDistributionModePayload = Readonly<{
    item: CartItem;
    distributeAmountEvenly: boolean;
}>;

export type CartItemWithAmountsToDistributePayload = Readonly<{
    item: CartItem;
    amounts?: number[]
}>;

export type CartItemWithCategoryPayload = Readonly<{
    item: CartItem;
    category: CartCategory
}>;

export type CartItemWithAdditionalInformation = Readonly<{
    item: CartItem;
    additionalInformation: string
}>;

type AdditionalCodingPayload = {
    codingAndTax: CodingAndTax;
    configuration: CompanyConfiguration;
    procurementConfiguration?: ProcurementConfiguration;
    validatorConfiguration?: ValidatorConfiguration;
};

type CopySourcePayload = {
    sourceDocumentIdentifier: string,
    copyingUserId: number,
    copiedTimestamp: string
};

type SplitCartLinesPayload = {
    splittedLineId: string,
    cartLines: CartLine[]
};

export type SetCartItemCodingAndTaxPayload = Readonly<{
    item: CartItem;
} & AdditionalCodingPayload>;

export type AddProductAndCodingToCartPayload = Readonly<{
    product: ProductDto;
    cartLineId: string;
    quantity?: number;
    resolutionConfigurations: ResolutionConfigurationDto[];
    copySource?: CopySourcePayload;
    omitProductExistenceCheck?: boolean;
} & AdditionalCodingPayload>;

export type AddFreeTextAndCodingToCartPayload = Readonly<{
    item: FreeTextItem;
    cartLineId: string;
    quantity?: number;
    freeTextFormId?: number;
    freeTextFormFields?: FreeTextFormField[];
    resolutionConfigurations: ResolutionConfigurationDto[];
    cartPosition?: number;
    lineDeliveryDate?: string;
    lineDeliveryAddress?: Address;
    copySource?: CopySourcePayload;
    databaseId?: number,
    dimensionApprovalObjectId?: number,
    omitFreeTextItemExistenceCheck?: boolean
} & AdditionalCodingPayload>;

export type AddPunchoutAndCodingToCartPayload = Readonly<{
    item: PunchoutItem;
    quantity: number;
    resolutionConfigurations: ResolutionConfigurationDto[];
} & AdditionalCodingPayload>;

export type ApplyTemplatePayload = Readonly<{
    templateId: number;
    item: Requisition;
    resolutionConfigurations: ResolutionConfigurationDto[];
} & AdditionalCodingPayload>;

export type SetLineNoteToSupplierPayload = Readonly<{
    lineNoteToSupplier: string;
    lineId: string;
}>;

export type SetLineDeliveryDatePayload = Readonly<{
    lineDeliveryDateTimestamp: number;
    lineId: string;
}>;

export type SetLineDeliveryAddressPayload = Readonly<{
    lineId: string;
    address: Address;
    isDeliveryAddressSelectedByUser?: boolean;
}>;

export type LineLevelOverwriteMode =
    "Overwrite all"
    | "Keep existing & overwrite rest"
    | "Only head level"
    | "Only unchanged by user"
    | "Only undefined";
export type SetLineDeliveryDatesToHeadDeliveryDatePayload = Readonly<{
    headDeliveryDateTimestamp: number;
    mode: LineLevelOverwriteMode
}>;

export type SetLineDeliveryAddresesToHeadDeliveryDatePayload = Readonly<{
    address: Address;
    mode: LineLevelOverwriteMode;
    selectedLineIds?: string[];
}>;

export type SetLineAdditionalInformationPayload = Readonly<{
    lineId: string;
    additionalInformation: string;
}>;


export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: _state => initialState,

        addProductAndCodingToCart: (state, action: PayloadAction<AddProductAndCodingToCartPayload>) => {
            const cartLine = action.payload.omitProductExistenceCheck 
                ? null 
                : findCatalogCartLineByProductId(state.cartLines, action.payload.product.entityId);
            const isCorrectCurrency = state.cartLines[0] ? state.cartLines[0].item.unitPrice.currencyId === action.payload.product.unitPrice.currencyId : true;

            const isSupplierCurrencyValidatorActive = action.payload.validatorConfiguration ?
                action.payload.validatorConfiguration.activeValidators.some(val => val === PurchaseRequisitionLineSupplierCurrencyValidator)
                : false;

            const isSupplierCurrencyMatchingCart = !(isSupplierCurrencyValidatorActive
                && state.cartLines[0]
                && action.payload.product.supplierDefaultCurrencyId ?
                state.cartLines[0].item.unitPrice.currencyId !== action.payload.product.supplierDefaultCurrencyId
                : false);

            const isOnlyOneSupplierConfigValid = action.payload.procurementConfiguration?.isOnlyOneSupplierAllowed ?
                (state.cartLines[0] ?
                    state.cartLines[0].item.supplier?.id === action.payload.product.supplierId
                    : true)
                : true;

            if (cartLine || !isCorrectCurrency || !isOnlyOneSupplierConfigValid || !isSupplierCurrencyMatchingCart)
                return;

            const newCartLine = mapProductToCartLine(
                action.payload.product,
                action.payload.cartLineId,
                action.payload.quantity,
                action.payload.codingAndTax.taxIndicator1,
                action.payload.codingAndTax.taxIndicator2,
                action.payload.configuration,
                action.payload.resolutionConfigurations,
                state.details.deliveryDate,
                state.details.deliveryAddress
            );
            addCodingToCartItem(newCartLine, action.payload.codingAndTax.codings, action.payload.configuration);
            state.cartLines.push(newCartLine);
        },

        addFreeTextAndCodingToCart: (state, action: PayloadAction<AddFreeTextAndCodingToCartPayload>) => {
            const cartLine = action.payload.omitFreeTextItemExistenceCheck 
                ? null 
                : findCartLineByFreeTextItem(state.cartLines, action.payload.item);
            const isCorrectCurrency = state.cartLines[0] ? state.cartLines[0].item.unitPrice.currencyId === action.payload.item.currencyId : true;

            if (cartLine || !isCorrectCurrency)
                return;

            const newCartLine = mapFreeTextItemToCartLine(
                action.payload.item,
                action.payload.cartLineId,
                action.payload.codingAndTax.taxIndicator1,
                action.payload.codingAndTax.taxIndicator2,
                action.payload.configuration,
                action.payload.resolutionConfigurations,
                state.details.deliveryDate,
                state.details.deliveryAddress,
                action.payload.quantity,
                action.payload.freeTextFormId,
                action.payload.freeTextFormFields,
            );
            addCodingToCartItem(newCartLine, action.payload.codingAndTax.codings, action.payload.configuration);

            if (action.payload.cartPosition != null) {
                state.cartLines.splice(action.payload.cartPosition, 0, newCartLine);
                modifyExistingDeliveryDate(newCartLine, action.payload.lineDeliveryDate);
                modifyExistingDeliveryAddress(newCartLine, action.payload.lineDeliveryAddress);
                modifyDatabaseId(newCartLine, action.payload.databaseId);
                modifyDimensionApprovalObjectId(newCartLine, action.payload.dimensionApprovalObjectId);
                //update needed for triggering default address calculation
                state.coding.snapshot = state.cartLines;
            }
            else
                state.cartLines.push(newCartLine);
        },

        addPunchoutAndCodingToCart: (state, action: PayloadAction<AddPunchoutAndCodingToCartPayload>) => {
            const cartLine = findCartLineByPunchoutItem(state.cartLines, action.payload.item);
            if (cartLine)
                return;

            const newCartLine = mapPunchoutItemToCartLine(
                action.payload.item,
                action.payload.quantity,
                action.payload.codingAndTax.taxIndicator1,
                action.payload.codingAndTax.taxIndicator2,
                action.payload.configuration,
                action.payload.resolutionConfigurations,
                state.details.deliveryDate,
                state.details.deliveryAddress
            );
            addCodingToCartItem(newCartLine, action.payload.codingAndTax.codings, action.payload.configuration);
            state.cartLines.push(newCartLine);
        },

        setCartItemCodingAndTax: (
            { cartLines }, { payload: { item, codingAndTax, configuration } }: PayloadAction<SetCartItemCodingAndTaxPayload>
        ) => {
            const cartLine = getCartLineById(cartLines, item.lineId);
            if (!cartLine)
                return;

            const { codings, taxIndicator1, taxIndicator2 } = codingAndTax;
            addCodingToCartItem(cartLine, codings, configuration);

            modifyExistingTaxCode(cartLine, {
                item,
                taxCode: taxIndicator1 ?? defaultTaxIndicator(1)
            });

            modifyExistingTaxCode(cartLine, {
                item,
                taxCode: taxIndicator2 ?? defaultTaxIndicator(2)
            });
        },

        incrementQuantity: (state, action: PayloadAction<CartItemActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemQuantity(cartLine, qty => qty + 1);
        },

        decrementQuantity: (state, action: PayloadAction<CartItemActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemQuantity(cartLine, qty => qty - 1);
        },

        setQuantity: (state, action: PayloadAction<CartItemWithQuantityActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemQuantity(cartLine, () => action.payload.quantity);
        },

        setAmount: (state, action: PayloadAction<CartItemWithAmountActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemAmount(cartLine, () => action.payload.amount);
        },

        setCodingLineAmount: (state, action: PayloadAction<CartItemWithCodingLineAmountActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyCodingLineAmount(cartLine, action.payload);
        },

        setRemainingAmountToCode: ({ cartLines }, { payload }: PayloadAction<CartItemWithAmountActionPayload>) => {
            const cartLine = getCartLineById(cartLines, payload.item.lineId);
            if (!cartLine)
                return;

            modifyRemainingAmountToCode(cartLine, payload);
        },

        setCodingLineDimensionValues: (state, action: PayloadAction<CartItemWithDimensionValuesActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingDimensionValues(cartLine, action.payload);
        },

        setCodingLineDimensionValue: (state, action: PayloadAction<CartItemWithDimensionValueActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingDimensionValue(cartLine, action.payload);
        },

        splitCodingLine: (state, action: PayloadAction<CartItemWithAmountActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            addNewLineToCoding(cartLine, action.payload.amount);
        },

        removeCodingLine: (state, action: PayloadAction<CartItemWithCodingLineActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            removeExistingCodingLine(cartLine, action.payload);
        },

        setTaxIndicator: (state, action: PayloadAction<CartItemWithTaxCodeActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingTaxCode(cartLine, action.payload);
        },

        setAmountDistributionMode: (state, action: PayloadAction<CartItemWithDistributionModePayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyCartLineDistributionMode(cartLine, action.payload.distributeAmountEvenly);
        },

        distributeAmountOnCodingLines: (state, action: PayloadAction<CartItemWithAmountsToDistributePayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            distributeCartLineAmount(cartLine, action.payload.amounts);
        },

        setCategory: (state, action: PayloadAction<CartItemWithCategoryPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemCategory(cartLine, action.payload);
        },

        remove: (state, action: PayloadAction<CartItemActionPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            const lineIndex = state.cartLines.indexOf(cartLine);
            removeExistingCartLine(state.cartLines, lineIndex);
        },

        splitLines: (state, action: PayloadAction<SplitCartLinesPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.splittedLineId);
            const lineIndex = state.cartLines.indexOf(cartLine);
            insertNewCartLines(state.cartLines, lineIndex, action.payload.cartLines);
        },

        setNoteToSupplier: (state, action: PayloadAction<NoteToSupplier | undefined>) => {
            state.details.noteToSupplier = action.payload;
        },

        addComment: (state, action: PayloadAction<Comment>) => {
            state.details.comments.unshift(action.payload);
        },

        saveCommentChanges: (state, action: PayloadAction<Comment[]>) => {
            state.details.comments = action.payload;
        },

        removeComment: (state, action: PayloadAction<number>) => {
            state.details.comments = state.details.comments.filter(x => x.localId !== action.payload);
        },

        setInvoiceAddress: (state, action: PayloadAction<Address>) => {
            state.details.invoiceAddress = action.payload;
        },

        setDeliveryAddress: (state, action: PayloadAction<Address>) => {
            state.details.deliveryAddress = action.payload;
        },

        setIsDeliveryAddressSelectedByUser: (state, action: PayloadAction<boolean>) => {
            state.details.isDeliveryAddressSelectedByUser = action.payload;
        },

        showCoding: (state) => {
            if (state.coding.displayed)
                return;

            state.coding.snapshot = state.cartLines;
            state.coding.displayed = true;
        },

        showCodingFor: (state, action: PayloadAction<number>) => {
            if (state.coding.displayed)
                return;

            state.coding.snapshot = state.cartLines;
            state.coding.focused = action.payload;
            state.coding.displayed = true;
        },

        discardCoding: (state) => {
            if (!state.coding.displayed)
                return;

            state.cartLines = state.coding.snapshot;
            state.coding = initialState.coding;
        },

        acceptCoding: (state) => {
            if (!state.coding.displayed)
                return;

            state.coding = initialState.coding;
        },

        setShowAllItems: (state, action: PayloadAction<boolean>) => {
            state.filters.showAllItems = action.payload;
        },

        setShowAllLines: (state, action: PayloadAction<boolean>) => {
            state.filters.showAllLines = action.payload;
        },

        setDeliveryDate: (state, action: PayloadAction<string>) => {
            state.details.deliveryDate = action.payload;
        },

        addAttachment: (state, action: PayloadAction<Attachment>) => {
            state.details.attachments.push(action.payload);
        },

        removeAttachment: (state, action: PayloadAction<string>) => {
            state.details.attachments = state.details.attachments.filter(a => a.hash !== action.payload);
        },

        updateAttachment: (state, action: PayloadAction<Attachment>) => {
            const attachment = action.payload;
            state.details.attachments = state.details.attachments.map(a => a.hash === attachment.hash ? attachment : a);
        },

        attachmentUploaded: (state, action: PayloadAction<AttachmentUploaded>) => {
            const newState = action.payload.attachment;
            const temporaryHash = action.payload.temporaryHash;
            state.details.attachments = state.details.attachments.map(a => a.hash === temporaryHash ? newState : a);
        },

        setEntityViewId: (state, action: PayloadAction<string>) => {
            state.details.entityViewId = action.payload;
        },

        applyTemplate: ({ cartLines, details }, { payload }: PayloadAction<ApplyTemplatePayload>) => {
            const isCurrencyMatching = cartLines[0]
                ? payload.item.lines.every(x => x.unitPrice.currencyId === cartLines[0].item.unitPrice.currencyId)
                : true;

            const isSupplierMatching = payload.procurementConfiguration?.isOnlyOneSupplierAllowed
                ? (cartLines[0]
                    ? cartLines[0].item.supplier?.id === payload.item.commonSupplierId
                    : true)
                : true;

            if (!isSupplierMatching || !isCurrencyMatching)
                return;

            const mappedCartLines = payload.item.lines.map(line => {
                const temporaryCartItem = mapRequisitionLineToCartItem(line, newCartLineId());
                const cartLineIndex = getCartLineIndexForTemplate(cartLines, temporaryCartItem);

                if (cartLineIndex >= 0) {
                    if (line.isQuantityBased)
                        modifyExistingItemQuantity(cartLines[cartLineIndex], qty => qty + line.quantity.value);
                    else
                        modifyExistingItemAmount(cartLines[cartLineIndex], () => cartLines[cartLineIndex].item.unitPrice.value + line.unitPrice.value);

                    return;
                }

                const newLine = mapRequsitionLineToCartLine(
                    line,
                    payload.configuration,
                    payload.templateId,
                    payload.resolutionConfigurations,
                    details.deliveryDate,
                    details.deliveryAddress
                );
                addCodingToCartItem(newLine, line.codingAndTax.codings, payload.configuration);

                return newLine;
            }).filter(x => x);

            cartLines.push(...mappedCartLines);
        },

        setShippingMarks: (state, action: PayloadAction<string>) => {
            state.details.shippingMarks = action.payload;
        },
        setOurReferenceRole: (state, action: PayloadAction<OurReferenceRole>) => {
            state.details.ourReferenceRole = action.payload;
        },
        setOrderTypeId: (state, action: PayloadAction<number>) => {
            state.details.orderTypeId = action.payload;
        },
        setBuyerRoleId: (state, action: PayloadAction<number>) => {
            state.details.buyerRoleId = action.payload;
        },
        setGoodsReceiptByRoleId: (state, action: PayloadAction<number>) => {
            state.details.goodsReceiptByRoleId = action.payload;
        },

        addToAdditionalInformation: (state, action: PayloadAction<CartItemWithAdditionalInformation>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.item.lineId);
            if (!cartLine)
                return;

            modifyExistingItemAdditionalInformation(cartLine, action.payload.additionalInformation);
        },

        setCartLines: (state, action: PayloadAction<CartLine[]>) => {
            state.cartLines = action.payload;
        },

        setCartDetails: (state, action: PayloadAction<CartDetails>) => {
            state.details = action.payload;
        },

        clearComments: (state) => {
            state.details.comments = [];
        },

        setDiscardedItems: (state, action: PayloadAction<string[]>) => {
            state.validationErrors.discardedItems = action.payload;
        },

        setDiscardedDetails: (state, action: PayloadAction<string[]>) => {
            state.validationErrors.discardedDetails = action.payload;
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clearValidationErrors: (state, action: PayloadAction<any>) => {
            state.validationErrors = initialState.validationErrors;
        },

        setPurchasePolicyConfirmPending: (state, action: PayloadAction<boolean>) => {
            state.details.purchasePolicyConfirmationPending = action.payload;
        },

        setLineNoteToSupplier: (state, action: PayloadAction<SetLineNoteToSupplierPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.lineId) as WritableDraft<CartLine>;
            cartLine.additionalInformation = action.payload.lineNoteToSupplier;
        },

        setCodingLineBudgetPositions: (state, action: PayloadAction<CodingLineBudgetPosition[]>) => {
            state.budgetPosition.positions = action.payload;
        },

        setHasActiveBudgetConfiguration: (state, action: PayloadAction<boolean>) => {
            state.budgetPosition.hasActiveBudgetConfiguration = action.payload;
        },

        setLoadingBudgetPositions: (state, action: PayloadAction<boolean>) => {
            state.budgetPosition.isLoading = action.payload;
        },

        setLineDeliveryDate: (state, action: PayloadAction<SetLineDeliveryDatePayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.lineId) as WritableDraft<CartLine>;
            const dateString = action.payload.lineDeliveryDateTimestamp === null
                ? null
                : convertDateTimestampToString(action.payload.lineDeliveryDateTimestamp);

            cartLine.deliveryDate = dateString;
        },

        setLineDeliveryAddress: (state, action: PayloadAction<SetLineDeliveryAddressPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.lineId) as WritableDraft<CartLine>;
            cartLine.deliveryAddress = action.payload.address;
            cartLine.isDeliveryAddressSelectedByUser = action.payload.isDeliveryAddressSelectedByUser;
        },

        setHeadAndLineDeliveryDates: (state, action: PayloadAction<SetLineDeliveryDatesToHeadDeliveryDatePayload>) => {
            const date = action.payload.headDeliveryDateTimestamp;
            const dateString = date == null
                ? null
                : convertDateTimestampToString(date);

            const previousDate = state.details.deliveryDate == null ? null : state.details.deliveryDate;
            state.details.deliveryDate = dateString;
            if (action.payload.mode === "Only head level") {
                return;
            }

            if (action.payload.mode === "Overwrite all") {
                for (const line of state.cartLines) {
                    line.deliveryDate = dateString;
                }
            } else {
                for (const line of state.cartLines) {
                    if (line.deliveryDate === previousDate || line.deliveryDate === null) {
                        line.deliveryDate = dateString;
                    }
                }
            }
        },

        setHeadAndLineDeliveryAddresses: (state, action: PayloadAction<SetLineDeliveryAddresesToHeadDeliveryDatePayload>) => {
            const address = action.payload.address;
            const selectedLinesIds = action.payload.selectedLineIds;
         
            const previousAddress = state.details.deliveryAddress;
            state.details.deliveryAddress = address;
            if (action.payload.mode === "Only head level") {
                return;
            }

            if (action.payload.mode === "Only unchanged by user") {
                for (const line of state.cartLines) {
                    if (line.isDeliveryAddressSelectedByUser !== true) 
                        line.deliveryAddress = address;
                }
                return;
            }

            if (action.payload.mode === "Only undefined") {
                for (const line of state.cartLines) {
                    if (isNullOrUndefined(line.deliveryAddress))
                        line.deliveryAddress = address;
                }
                return;
            }

            if (action.payload.mode === "Overwrite all") {
                for (const line of state.cartLines) {
                    const isApplicableForLine = isNullOrUndefined(selectedLinesIds) 
                        ? true
                        : selectedLinesIds.some(id => id === line.id);

                    if (!isApplicableForLine) continue;

                    line.deliveryAddress = address;
                }
            } else {
                for (const line of state.cartLines) {
                    if (line.deliveryAddress == null || compareAddressesWithNullCheck(line.deliveryAddress, previousAddress)) {
                        const isApplicableForLine = isNullOrUndefined(selectedLinesIds)
                            ? true
                            : selectedLinesIds.some(id => id === line.id);

                        if (!isApplicableForLine) continue;

                        line.deliveryAddress = address;
                    }
                }
            }
        },

        setLineAdditionalInformation: (state, action: PayloadAction<SetLineAdditionalInformationPayload>) => {
            const cartLine = getCartLineById(state.cartLines, action.payload.lineId) as WritableDraft<CartLine>;
            cartLine.additionalInformation = action.payload.additionalInformation;
        },

        setCodingOrTaxCleared: (state, action: PayloadAction<boolean>) => {
            state.copyReqInformation.codingOrTaxCleared = action.payload;
        },

        setCategoryCleared: (state, action: PayloadAction<boolean>) => {
            state.copyReqInformation.categoryCleared = action.payload;
        },

        setHeaderInformationChanged: (state, action: PayloadAction<string>) => {
            state.copyReqInformation.headerInformationChanged = action.payload;
        },
	
	setCopySource: (state, action: PayloadAction<CopySourcePayload>) => {
            state.details.copySource = {
                sourceDocumentIdentifier: action.payload.sourceDocumentIdentifier,
                copyingUserId: action.payload.copyingUserId,
                copiedTimestamp: action.payload.copiedTimestamp
            };
        }
    }
});

export const {
    addProductAndCodingToCart,
    addFreeTextAndCodingToCart,
    addPunchoutAndCodingToCart,
    setCartItemCodingAndTax,
    incrementQuantity,
    decrementQuantity,
    setQuantity,
    setAmount,
    setCodingLineAmount,
    setRemainingAmountToCode,
    setNoteToSupplier,
    setInvoiceAddress,
    setDeliveryAddress,
    setCodingLineDimensionValues,
    setCodingLineDimensionValue,
    splitCodingLine,
    removeCodingLine,
    setTaxIndicator,
    setAmountDistributionMode,
    distributeAmountOnCodingLines,
    setCategory,
    remove,
    clearCart,
    addComment,
    removeComment,
    saveCommentChanges,
    showCoding,
    showCodingFor,
    discardCoding,
    acceptCoding,
    setShowAllItems,
    setShowAllLines,
    setDeliveryDate,
    addAttachment,
    removeAttachment,
    updateAttachment,
    attachmentUploaded,
    setEntityViewId,
    applyTemplate,
    setShippingMarks,
    setOurReferenceRole,
    setOrderTypeId,
    setBuyerRoleId,
    setGoodsReceiptByRoleId,
    addToAdditionalInformation,
    setCartLines,
    setCartDetails,
    clearComments,
    setDiscardedItems,
    setDiscardedDetails,
    clearValidationErrors,
    setPurchasePolicyConfirmPending,
    setLineNoteToSupplier,
    setCodingLineBudgetPositions,
    setHasActiveBudgetConfiguration,
    setLoadingBudgetPositions,
    setIsDeliveryAddressSelectedByUser,
    setLineDeliveryDate,
    setHeadAndLineDeliveryDates,
    setLineDeliveryAddress,
    setHeadAndLineDeliveryAddresses,
    setCodingOrTaxCleared,
    setCategoryCleared,
    setLineAdditionalInformation,
    setHeaderInformationChanged,
    setCopySource,
    splitLines
} = cartSlice.actions;

export const selectCartLines = (s: RootState) => s.procurement.cart.cartLines;

export const noteToSupplier = (s: RootState) => s.procurement.cart.details.noteToSupplier;

export const hasMultipleNotesToSupplierSelector = (s: RootState) => s.procurement.cart.details.noteToSupplier
    && s.procurement.cart.details.noteToSupplier.note !== ""
    && s.procurement.cart.cartLines.some(x => x.additionalInformation && x.additionalInformation !== "");

export const deliveryDate = (s: RootState) => s.procurement.cart.details.deliveryDate;

export const hasMultipleDatesSelector = (s: RootState) => {
    const allDates =
        [s.procurement.cart.details.deliveryDate, s.procurement.cart.cartLines.map(x => x.deliveryDate)]
            .flat()
            .filter(x => x != null && x !== "");

    return allDates.length > 1 && allDates.some(x => x !== allDates[0]);
};

export const hasMultipleAddressesSelector = (s: RootState) => {
    const allAddresses =
        [s.procurement.cart.details.deliveryAddress, s.procurement.cart.cartLines.map(x => x.deliveryAddress)]
            .flat()
            .filter(x => x != null);

    return allAddresses.length > 1 && allAddresses.some(x => !compareAddressesWithNullCheck(x, allAddresses[0]));
};

export const selectInvoiceAddress = (s: RootState) => s.procurement.cart.details.invoiceAddress;

export const selectDeliveryAddress = (s: RootState) => s.procurement.cart.details.deliveryAddress;

export const comments = (s: RootState) => s.procurement.cart.details.comments;

export const cart = (s: RootState) => s.procurement.cart as Cart;

export const selectCoding = (s: RootState) => s.procurement.cart.coding;

export const selectShowAllItemsFilter = (s: RootState) => s.procurement.cart.filters.showAllItems;

export const selectShowAllLinesFilter = (s: RootState) => s.procurement.cart.filters.showAllLines;

export const attachments = (s: RootState) => s.procurement.cart.details.attachments;

export const entityViewId = (s: RootState) => s.procurement.cart.details.entityViewId;

export const cartCurrencyInfo = (s: RootState) => s.procurement.cart.cartLines[0]?.item.unitPrice;

export const selectCartSupplierInfo = (s: RootState) => s.procurement.cart.cartLines[0]?.item.supplier;
export const selectNumberOfUniqueCartSuppliers = (s: RootState) => s.procurement.cart.cartLines
    .filter(c => c.item.supplier)
    .map(cl => cl.item.supplier.id)
    .filter((s, i, array) => array.indexOf(s) === i)
    .length;

export const selectShippingMarks = (s: RootState) => s.procurement.cart.details.shippingMarks;
export const selectOurReferenceRole = (s: RootState) => s.procurement.cart.details.ourReferenceRole;
export const selectOrderTypeId = (s: RootState) => s.procurement.cart.details.orderTypeId;
export const selectBuyerRoleId = (s: RootState) => s.procurement.cart.details.buyerRoleId;
export const selectGoodsReceiptByRoleId = (s: RootState) => s.procurement.cart.details.goodsReceiptByRoleId;

export const discardedItemsSelector = (s: RootState) => s.procurement.cart.validationErrors.discardedItems;
export const discardedDetailsSelector = (s: RootState) => s.procurement.cart.validationErrors.discardedDetails;

const getDistinctPurchasePolicies = (cartLines: CartLine[]) => cartLines.filter(l => l.item.purchasePolicies !== undefined).reduce(
    (ret, cl) => [...ret, ...cl.item.purchasePolicies.filter(sought => ret.find(existing => existing.id == sought.id) === undefined)],
    []);

export const selectCartPurchasePolicies = 
    createSelector([(state: RootState) => state.procurement.cart.cartLines], getDistinctPurchasePolicies);

export const selectCodingLineDimensionValues = (s: RootState) => s.procurement.cart.cartLines.flatMap(cl => cl.codingLines.flatMap(d => d.dimensionValues).flatMap(dv => dv.id)).join();

export const selectCodingLineAmounts = (s: RootState) => s.procurement.cart.cartLines.flatMap(cartLine => cartLine.codingLines.flatMap(codingLine => codingLine.amount)).join();

const isConfirmationRequired = (purchasePolicies: CartPurchasePolicyData[]) => purchasePolicies.some(pp => pp.isConfirmationRequired);

export const selectPurchasePolicyConfirmPending = (s: RootState) =>
    isConfirmationRequired(getDistinctPurchasePolicies(s.procurement.cart.cartLines)) ? s.procurement.cart.details.purchasePolicyConfirmationPending ?? true : false;

export const selectCartLine = (lineId: string) => (s: RootState) => s.procurement.cart.cartLines.filter(x => x.id === lineId)[0];
export function budgetPositionsSelector(s: RootState) { return s.procurement.cart.budgetPosition.positions ?? []; }

export const selectIsBudgetPositionLoading = (s: RootState) => s.procurement.cart.budgetPosition.isLoading ?? false;

export const selectHasActiveBudgetConfiguration = (s: RootState) => s.procurement.cart.budgetPosition.hasActiveBudgetConfiguration ?? false;

export const selectCodingOrTaxCleared = (s: RootState) => s.procurement.cart.copyReqInformation.codingOrTaxCleared ?? false;
export const selectCategoryCleared = (s: RootState) => s.procurement.cart.copyReqInformation.categoryCleared ?? false;
export const selectHeaderInformationChanged = (s: RootState) => s.procurement.cart.copyReqInformation.headerInformationChanged;

export const selectCopySource = (s: RootState) => s.procurement.cart.details.copySource;

export const reducer = cartSlice.reducer;