/// <amd-module name="Core/Medius.Core.Web/Scripts/documentRoleApplicationAccess"/>
export const expenseApplicationRoleName = "Expense Invoice Application Access";
export const orderbasedApplicationRoleName = "Orderbased Invoice Application Access";
export const procurementApplicationRoleName = "Procurement Application Access";
export const documentApprovalApplicationRoleName = "Document approval application access role";
export const contractbasedApplicationRoleName = "Contractbased Invoice Application Access";
export const supplierContractApplicationRoleName = "Supplier Contract Application Access";
export const coreApplicationRoleName = "Core standard application role";
export const paymentRequestApplicationRoleName = "Payment Request Application Access";
export const prepaymentInvoiceApplicationRoleName = "Prepayment Invoice Application Access";
export const supplierStatementApplicationRoleName = "Supplier Statement Controller";

export const applicationAccessRoles = {
    "Medius.ContractbasedInvoice.Entities.ContractbasedInvoice": contractbasedApplicationRoleName,
    "Medius.Enterprise.Entities.Contracts.SupplierContract": supplierContractApplicationRoleName,
    "Medius.Contract.Entities.SupplierContractDocument": supplierContractApplicationRoleName,
    "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice": orderbasedApplicationRoleName,
    "Medius.PurchaseToPay.Entities.AdjustmentRequest.AdjustmentRequest": orderbasedApplicationRoleName,
    "Medius.ExpenseInvoice.Entities.ExpenseInvoice": expenseApplicationRoleName,
    "Medius.ExpenseInvoice.Entities.PaymentRequest": paymentRequestApplicationRoleName,
    "Medius.Procurement.Entities.PurchaseRequisition": procurementApplicationRoleName,
    "Medius.Procurement.Entities.PurchaseOrderDocument": procurementApplicationRoleName,
    "Medius.PurchaseToPay.DocumentApproval.Entities.DocumentApproval": documentApprovalApplicationRoleName,
    "Medius.Apa.Persistence.PrepaymentInvoice.PrepaymentSupplierInvoice": prepaymentInvoiceApplicationRoleName,
    "Medius.Apa.SupplierConversations": supplierStatementApplicationRoleName
};
