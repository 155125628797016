///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PredefinedQuestionButtons"/>
import * as React from "react";
import { 
    displayedPredefinedQuestionTranslationTags, 
    shouldIApproveThisInvoice,
    summarizeTheInvoiceContext, 
    tellMeMoreAboutTheSupplier
} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Button, Card, IconActionPinFill, IconActionUnpinFill, IconArrowsChevronDownRegular, IconArrowsChevronUpRegular, Text } from "@medius/ui-controls";
import { useCopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext";
import { IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";

function getIsPinnedFromLocalStorage(){
    const storageValue = localStorage.getItem("pinnedExpandedPredefinedQuestions");
    if(storageValue === "expanded" || storageValue === "collapsed")
        return true;
    return false;
}

function subscribeToLocalStorageChanges(callback: () => void) {
    window.addEventListener("pinnedExpandedPredefinedQuestionsUpdated", callback);

    return () => {
        window.removeEventListener("pinnedExpandedPredefinedQuestionsUpdated", callback);
    };
}

interface PredefinedQuestionsProps {
    disabled: boolean,
    predefinedQuestionsList: IPredefinedQuestion[],
    onPredefinedQuestionClick: (predefinedQuestion: string) => Promise<void>,
}

export const PredefinedQuestions = ({
    onPredefinedQuestionClick,
    predefinedQuestionsList,
    disabled
}: PredefinedQuestionsProps) => {
    const { workflowStepName, isStreamActive } = useCopilotContext();
    const [isCardOpen, setIsCardOpen] = React.useState(localStorage.getItem("pinnedExpandedPredefinedQuestions") !== "collapsed");
    const areExpandedPredefinedQuestionsPinned = React.useSyncExternalStore(subscribeToLocalStorageChanges, getIsPinnedFromLocalStorage);

    //Card should hide when response stream starts unless it's pinned.
    const [prevIsStreamActive, setPrevIsStreamActive] = React.useState(null);
    if(prevIsStreamActive !== isStreamActive) { 
        setPrevIsStreamActive(isStreamActive);
        if(isStreamActive && !areExpandedPredefinedQuestionsPinned && isCardOpen) {
            setIsCardOpen(false);
        }
    }

    const toggleCardHeader = () => {
        setIsCardOpen(prev => !prev);
    };

    const displayedPredefinedQuestions = predefinedQuestionsList.filter(question => 
        displayedPredefinedQuestionTranslationTags.includes(question.value)
    );

    return displayedPredefinedQuestions.length > 0 ?
        <Card 
            onOpenToggle={() => {}}
            isCollapsible 
            height="content"
            open={isCardOpen}
            appearTransition={false} 
            renderCustomHeader={() => (
                <CustomCardHeaderContent 
                    isCardOpen={isCardOpen} 
                    disabled={disabled} 
                    onPredefinedQuestionClick={onPredefinedQuestionClick}
                    stepName={workflowStepName} 
                    toggleCardHeader={toggleCardHeader}
                    pinnedState={areExpandedPredefinedQuestionsPinned}
                />
            )}
        >
            <div className="mediusAgent__promptInput__predefinedQuestions" data-testid="predefinedQuestions-container">
                {displayedPredefinedQuestions.map((question, index) => 
                    <div key={index} >
                        <Button
                            label={getLabelTranslation(question.value)}
                            height="small"
                            variant="tertiary"
                            onClick={() => onPredefinedQuestionClick(question.value)}
                            disabled={disabled}
                            data-testid={`predefinedQuestion-${question.categorizationId}`}
                        />
                    </div>
                )}
            </div>
    </Card> : 
    <></>;
};

interface ICardHeaderCustomProps{
    stepName: string, 
    disabled: boolean,
    onPredefinedQuestionClick: (predefinedQuestion: string) => Promise<void>
    isCardOpen: boolean;
    toggleCardHeader?: () => void;
    pinnedState: boolean;
}

function CustomCardHeaderContent({ stepName, disabled, onPredefinedQuestionClick, isCardOpen, toggleCardHeader, pinnedState }: ICardHeaderCustomProps){
    const getHeaderClass = () => {
        let headerClass = "mediusAgent__promptInput__cardHeaderPredefinedQuestions";
        if(isCardOpen)
            headerClass+= " mediusAgent__promptInput__cardHeaderPredefinedQuestions__fadeout";
        return headerClass;
    };

    const updateLocalStorageItem = (value: string | null) => {
        if(!value) {
            localStorage.removeItem("pinnedExpandedPredefinedQuestions");
        } else {
            localStorage.setItem("pinnedExpandedPredefinedQuestions", value);
        }
        window.dispatchEvent(new Event("pinnedExpandedPredefinedQuestionsUpdated"));
    };

    const handleCardHeaderToggle = () => {
        updateLocalStorageItem(null);
        toggleCardHeader();
    };

    const pinExpandedPredefinedQuestions = () => {
        if(pinnedState){
            updateLocalStorageItem(null);
            return;
        }
        if(isCardOpen){
            updateLocalStorageItem("expanded");
        } else {
            updateLocalStorageItem("collapsed");
        }
    };

    return (
        <div className="mediusAgent__promptInput__predefinedQuestions__customHeaderContainer" data-testid="mediusAgent__predefinedQuestions__cardHeaderContainer">
            <div className="mediusAgent__promptInput__predefinedQuestions__customHeaderContainer__innerContainer">
                <div className="mediusAgent__promptInput__predefinedQuestions__customHeaderContainer__innerContainer__titlePinContainer">
                    <Text variant="heading3" style={{marginRight: "5px"}}>
                        {getLabelTranslation("#Core/PredefinedPrompts")}
                    </Text>
                    <div 
                         data-testid="predefinedQuestions-cardHeaderPin"
                        onClick={pinExpandedPredefinedQuestions} 
                        aria-label={pinnedState ? getLabelTranslation("#Core/hideExpandedPredefinedQuestions") : getLabelTranslation("#Core/pinExpandedPredefinedQuestions")}
                        style={{cursor: "pointer", display: "flex"}}
                    >
                        {pinnedState ? <IconActionUnpinFill  /> : <IconActionPinFill />}
                    </div>
                </div>
                <div
                    className="mediusAgent__promptInput__toggleIcon"
                    data-testid="predefinedQuestions-cardHeaderToggle"
                    onClick={handleCardHeaderToggle}
                    aria-label={isCardOpen ? getLabelTranslation("#Core/SeeLessPredefinedPrompts") : getLabelTranslation("#Core/SeeMorePredefinedPrompts")}
                    style={{cursor: "pointer", display: "flex"}}
                >
                    {isCardOpen ? <IconArrowsChevronDownRegular /> : <IconArrowsChevronUpRegular />}
                </div>
            </div>
            <div className={getHeaderClass()}>
            {
                stepName === "ApproveDocument" ? <>
                    <Button 
                        onClick={() => onPredefinedQuestionClick(shouldIApproveThisInvoice)} 
                        label={getLabelTranslation(shouldIApproveThisInvoice)} 
                        variant="tertiary" 
                        height="small" 
                        disabled={disabled}
                    /> 
                    <Button 
                        onClick={() => onPredefinedQuestionClick(summarizeTheInvoiceContext)} 
                        label={getLabelTranslation(summarizeTheInvoiceContext)} 
                        variant="tertiary" 
                        height="small" 
                        disabled={disabled}
                    /> 
                </> : <>
                    <Button 
                        onClick={() => onPredefinedQuestionClick(summarizeTheInvoiceContext)} 
                        label={getLabelTranslation(summarizeTheInvoiceContext)} 
                        variant="tertiary" 
                        height="small" 
                        disabled={disabled}
                    />
                    <Button 
                        onClick={() => onPredefinedQuestionClick(tellMeMoreAboutTheSupplier)} 
                        label={getLabelTranslation(tellMeMoreAboutTheSupplier)} 
                        variant="tertiary" 
                        height="small" 
                        disabled={disabled}
                    /> 
                </>
            }</div>
        </div>
    );
}