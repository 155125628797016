/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/cart/cartHelpers"/>
import { isEqual } from "underscore";
import { newCartLineId } from "../cartLineId";
import { CompanyConfiguration, TaxIndicator } from "../currentCompany/configuration";
import { ResolutionConfigurationDto } from "../resolutionConfiguration/resolutionConfiguration";
import { Attachment } from "./attachment";
import { Address, CartItem, CartLine, CartLineType, CartQuantity, RequisitionLine } from "./cart";
import { TaxGroup } from "./coding";
import { FreeTextFormField, FreeTextItem, FreeTextFormFieldType } from "./freeTextItem";
import { ProductDto } from "./productDto";
import { PunchoutItem } from "./punchoutItem";

export function getCartLineById(cartLines: CartLine[], id: string): CartLine | null {
    const index = cartLines.findIndex(x => x.id === id);
    return index >= 0 ? cartLines[index] : null;
}

export function getCartLineIndexForTemplate(cartLines: CartLine[], item: CartItem) {
    switch (item.lineType) {
        case CartLineType.Catalog:
            return item.productId > 0 ? cartLines.findIndex(line => line.item.productId === item.productId) : undefined;
        case CartLineType.FreeText:
            return getCartLineIndexByFreeTextDetails(cartLines, { description: item.description, partNumber: item.partNumber, supplierId: item.supplier?.id, isQuantityBased: item.isQuantityBased, categoryId: item.category?.id });
        case CartLineType.PunchOut:
            return getCartLineIndexByPunchoutDetails(cartLines, { description: item.description, supplierId: item.supplier.id });
        case CartLineType.FreeTextForm:
            return getCartLineIndexByFreeTextFormDetails(cartLines, { freeTextFormId: item.freeTextFormId, freeTextFormFields: item.freeTextFormFields, partNumber: item.partNumber, supplierId: item.supplier?.id, isQuantityBased: item.isQuantityBased, categoryId: item.category?.id });
        default:
            throw "Unexpected line type!";
    }
}

export function findCatalogCartLineByProductId(cartLines: CartLine[], productId: number): CartLine {
    const index = cartLines.findIndex(line => line.item.lineType === CartLineType.Catalog && line.item.productId === productId);
    return index >= 0 ? cartLines[index] : null;
}

export function findCatalogCartLinesByProductId(cartLines: CartLine[], productId: number): CartLine[] {
    return cartLines.filter(line => line.item.lineType === CartLineType.Catalog && line.item.productId === productId);
}

export function findCartLineByFreeTextItem(cartLines: CartLine[], details: FreeTextItem): CartLine {
    const index = details.freeTextFormId > 0
        ? getCartLineIndexByFreeTextFormDetails(cartLines, details)
        : getCartLineIndexByFreeTextDetails(cartLines, details);
    return index >= 0 ? cartLines[index] : null;
}

export function findCartLineByPunchoutItem(cartLines: CartLine[], details: PunchoutItem): CartLine {
    const index = getCartLineIndexByPunchoutDetails(cartLines, details);
    return index >= 0 ? cartLines[index] : null;
}

function getCartLineIndexByFreeTextDetails(
    cartLines: CartLine[], details: { description: string, partNumber?: string, supplierId?: number, freeTextFormId?: number, isQuantityBased: boolean, categoryId?: number }
): number {
    const index = cartLines.findIndex(line =>
        line.item.lineType === CartLineType.FreeText
        && line.item.isQuantityBased === details.isQuantityBased
        && line.item.description === details.description
        && line.item.partNumber === details.partNumber
        && line.item.supplier?.id === details.supplierId
        && line.item.category?.id === details.categoryId);

    return index;
}

function getCartLineIndexByPunchoutDetails(
    cartLines: CartLine[], details: { description: string, supplierId: number }
): number {
    const index = cartLines.findIndex(line =>
        line.item.lineType == CartLineType.PunchOut
        && line.item.description === details.description
        && line.item.supplier.id === details.supplierId);

    return index;
}

function getCartLineIndexByFreeTextFormDetails(cartLines: CartLine[], details: {
    freeTextFormId?: number, freeTextFormFields?: FreeTextFormField[],
    partNumber: string, supplierId?: number, isQuantityBased: boolean, categoryId?: number
}): number {
    const index = cartLines.findIndex(line =>
        line.item.lineType == CartLineType.FreeTextForm
        && line.item.freeTextFormId === Number(details.freeTextFormId)
        && isEqual(line.item.freeTextFormFields?.map(x => mapToComparableType(x.fieldId, x.fieldType, x.fieldValue)), details.freeTextFormFields?.map(x => mapToComparableType(x.fieldId, x.fieldType, x.fieldValue)))
        && line.item.isQuantityBased === details.isQuantityBased
        && line.item.partNumber === details.partNumber
        && line.item.supplier?.id === details.supplierId
        && line.item.category?.id === details.categoryId
    );

    return index;
}

function mapToComparableType(fieldId: number, fieldType: FreeTextFormFieldType, fieldValue: any) {
    return {
        fieldId: fieldId,
        fieldType: fieldType,
        fieldValue: fieldValue
    };
}

export function getItemEditableQuantityOrAmount({ item, quantity }: CartLine) {
    return item.isQuantityBased
        ? quantity.value
        : item.unitPrice.value;
}

export function getCartLineTotal({ item, quantity, amountResolution }: CartLine) {
    if (item.isQuantityBased)
        return Number((item.unitPrice.value * quantity.value).toFixed(amountResolution));
    else
        return item.unitPrice.value;
}

export function getCartNominalCurrency(cartLines: CartLine[]) {
    if (cartLines.length > 0) {
        const unitPrice = cartLines[0].item.unitPrice;
        return { id: unitPrice.currencyId, code: unitPrice.currencyCode };
    }

    return { id: null, code: null };
}

export function mapProductToCartLine(
    product: ProductDto,
    cartLineId: string,
    quantity: number,
    taxIndicator1: TaxGroup,
    taxIndicator2: TaxGroup,
    configuration: CompanyConfiguration,
    resolutionConfigurations: ResolutionConfigurationDto[],
    headDeliveryDate: string,
    headDeliveryAddress: Address,
): CartLine {
    const taxIndicators = configuration
        ? configuration.taxIndicators
        : [];

    const quantityResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === product.unitDescription && rc.typeIdentifier.includes("QuantityConfiguration"))?.resolution ?? 2;
    const unitPriceResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === product.unitPrice.currencyCode && rc.typeIdentifier.includes("UnitPriceConfiguration"))?.resolution ?? 2;
    const amountResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === product.unitPrice.currencyCode && rc.typeIdentifier.includes("AmountConfiguration"))?.resolution ?? 2;

    return {
        id: cartLineId,
        item: mapProductToCartItem(product, cartLineId),
        quantity: { value: quantity ?? 1 },
        taxCodes: getTaxIndicatorsArray(taxIndicator1, taxIndicator2, taxIndicators),
        taxIndicators,
        codingLines: [],
        remainingAmountToCode: 0,
        distributeAmountEvenly: false,
        quantityResolution,
        unitPriceResolution,
        amountResolution,
        additionalInformation: null,
        deliveryDate: headDeliveryDate && headDeliveryDate.length > 0 ? headDeliveryDate : null,
        deliveryAddress: headDeliveryAddress && isAddressPopulated(headDeliveryAddress) ? headDeliveryAddress : null
    };
}

export function mapRequsitionLineToCartLine(
    line: RequisitionLine, 
    configuration: CompanyConfiguration, 
    templateId: number, 
    resolutionConfigurations: ResolutionConfigurationDto[],
    headDeliveryDate: string,
    headDeliveryAddress: Address
): CartLine {
    const taxIndicators = configuration
        ? configuration.taxIndicators
        : [];

    const lineId = newCartLineId();
    const cartItemMap = mapRequisitionLineToCartItem(line, lineId);

    const cartQuantityMap = {
        value: line.quantity.value ?? 1
    } as CartQuantity;

    const taxCodes = getTaxIndicatorsArray(
        line.codingAndTax.taxIndicator1,
        line.codingAndTax.taxIndicator2,
        taxIndicators);

    const quantityResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === cartItemMap.unit.description && rc.typeIdentifier.includes("QuantityConfiguration"))?.resolution ?? 2;
    const unitPriceResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === cartItemMap.unitPrice.currencyCode && rc.typeIdentifier.includes("UnitPriceConfiguration"))?.resolution ?? 2;
    const amountResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === cartItemMap.unitPrice.currencyCode && rc.typeIdentifier.includes("AmountConfiguration"))?.resolution ?? 2;

    return {
        id: lineId,
        item: cartItemMap,
        quantity: cartQuantityMap,
        taxCodes,
        taxIndicators,
        codingLines: [],
        remainingAmountToCode: 0,
        distributeAmountEvenly: false,
        templateId,
        templateLineId: line.templateLineId,
        quantityResolution,
        unitPriceResolution,
        amountResolution,
        additionalInformation: line.additionalInformation,
        deliveryDate: headDeliveryDate && headDeliveryDate.length > 0 ? headDeliveryDate: null,
        deliveryAddress: headDeliveryAddress && isAddressPopulated(headDeliveryAddress) ? headDeliveryAddress : null
    };
}

const getTaxIndicatorsArray = (taxIndicator1: TaxGroup, taxIndicator2: TaxGroup, indicators: TaxIndicator[]) => {
    const taxIndicators = [taxIndicator1, taxIndicator2];

    return indicators.map(x => {
        const tax = taxIndicators
            .find(t => t?.indicatorNumber === x.indicatorNumber);

        return {
            indicatorNumber: x.indicatorNumber,
            id: tax?.id,
            description: tax?.description,
            code: tax?.code
        };
    });
};

export function mapFreeTextItemToCartLine(
    freeText: FreeTextItem,
    cartLineId: string,
    taxIndicator1: TaxGroup,
    taxIndicator2: TaxGroup,
    configuration: CompanyConfiguration,
    resolutionConfigurations: ResolutionConfigurationDto[],
    headDeliveryDate: string,
    headDeliveryAddress: Address,
    quantity?: number,
    freeTextFormId?: number,
    freeTextFormFields?: FreeTextFormField[],
): CartLine {
    const taxIndicators = configuration
        ? configuration.taxIndicators
        : [];

    const quantityResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === freeText.unitDescription && rc.typeIdentifier.includes("QuantityConfiguration"))?.resolution ?? 2;
    const unitPriceResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === freeText.currencyCode && rc.typeIdentifier.includes("UnitPriceConfiguration"))?.resolution ?? 2;
    const amountResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === freeText.currencyCode && rc.typeIdentifier.includes("AmountConfiguration"))?.resolution ?? 2;

    return {
        id: cartLineId,
        item: mapFreeTextItemToCartItem(freeText, cartLineId, freeTextFormId, freeTextFormFields),
        quantity: { value: freeText.isQuantityBased ? (quantity ?? 1.0) : 1.0 },
        taxCodes: getTaxIndicatorsArray(taxIndicator1, taxIndicator2, taxIndicators),
        taxIndicators,
        codingLines: [],
        remainingAmountToCode: 0,
        distributeAmountEvenly: false,
        quantityResolution,
        unitPriceResolution,
        amountResolution,
        additionalInformation: freeText.additionalInformation?.trim() ?? null,
        deliveryDate: headDeliveryDate && headDeliveryDate.length > 0 ? headDeliveryDate : null,
        deliveryAddress: headDeliveryAddress && isAddressPopulated(headDeliveryAddress) ? headDeliveryAddress : null
    };
}

export function mapPunchoutItemToCartLine(
    punchout: PunchoutItem,
    quantity: number,
    taxIndicator1: TaxGroup,
    taxIndicator2: TaxGroup,
    configuration: CompanyConfiguration,
    resolutionConfigurations: ResolutionConfigurationDto[],
    headDeliveryDate: string,
    headDeliveryAddress: Address
): CartLine {
    const taxIndicators = configuration
        ? configuration.taxIndicators
        : [];

    const quantityResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === punchout.unitDescription && rc.typeIdentifier.includes("QuantityConfiguration"))?.resolution ?? 2;
    const unitPriceResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === punchout.currencyCode && rc.typeIdentifier.includes("UnitPriceConfiguration"))?.resolution ?? 2;
    const amountResolution = resolutionConfigurations.find(rc => rc.numberIdentifier === punchout.currencyCode && rc.typeIdentifier.includes("AmountConfiguration"))?.resolution ?? 2;

    const lineId = newCartLineId();
    return {
        id: lineId,
        item: mapPunchoutItemToCartItem(punchout, lineId),
        quantity: { value: punchout.isQuantityBased ? (quantity ?? 1.0) : 1.0 },
        taxCodes: getTaxIndicatorsArray(taxIndicator1, taxIndicator2, taxIndicators),
        taxIndicators,
        codingLines: [],
        remainingAmountToCode: 0,
        distributeAmountEvenly: false,
        quantityResolution,
        unitPriceResolution,
        amountResolution,
        additionalInformation: null,
        deliveryDate: headDeliveryDate && headDeliveryDate.length > 0 ? headDeliveryDate : null,
        deliveryAddress: headDeliveryAddress && isAddressPopulated(headDeliveryAddress) ? headDeliveryAddress : null
    };
}

export function mapFreeTextItemToCartItem(freeText: FreeTextItem, cartLineId: string, freeTextFormId?: number, freeTextFormFields?: FreeTextFormField[]): CartItem {
    return {
        lineId: cartLineId,
        description: freeText.description?.trim(),
        productId: null,
        imageHash: null,
        imageType: null,
        category: { id: freeText.categoryId, code: freeText.categoryCode, description: freeText.categoryDescription },
        isPreferredItem: false,
        isPreferredSupplier: false,
        isQuantityBased: freeText.isQuantityBased,
        lineType: freeText.freeTextFormId > 0 ? CartLineType.FreeTextForm : CartLineType.FreeText,
        partNumber: freeText.partNumber,
        unitPrice: { currencyId: freeText.currencyId, currencyCode: freeText.currencyCode, value: freeText.unitPrice },
        supplier: { id: freeText.supplierId, name: freeText.supplier },
        details: "",
        contract: { id: freeText.contractId, title: freeText.contract },
        unit: { id: freeText.unitId, description: freeText.unitDescription },
        freeTextFormId: freeTextFormId,
        freeTextFormFields: freeTextFormFields,
        purchasePolicies: freeText.purchasePolicies
    };
}

export function mapProductToCartItem(product: ProductDto, cartLineId: string, lineType: CartLineType = CartLineType.Catalog): CartItem {
    return {
        lineId: cartLineId,
        description: product.description,
        productId: product.entityId,
        imageHash: product.imageHash,
        imageType: product.imageType,
        category: { id: product.categoryId, code: product.categoryCode, description: product.categoryDescription },
        isPreferredItem: product.isPreferredItem,
        isPreferredSupplier: product.isPreferredSupplier,
        isQuantityBased: !product.isServiceBased,
        lineType: lineType,
        partNumber: product.partNumber,
        unitPrice: product.unitPrice,
        supplier: { id: product.supplierId, name: product.supplierName },
        details: product.details,
        contract: null,
        unit: { id: product.unitId, description: product.unitDescription },
        purchasePolicies: product.purchasePolicies
    };
}

const getAddressPropsToValidate = (address: Address) => 
    [address?.building, address?.city, address?.country, address?.division, 
        address?.email, address?.fax, address?.homepage, address?.recipient, 
        address?.state, address?.addressLine, address?.addressLine2, 
        address?.phone, address?.zip, address?.location];

export function isAddressPopulated(address: Address): boolean {
    return (address && getAddressPropsToValidate(address).some(addrField => addrField?.trim()?.length > 0)) ?? false;
}

export function areAllAttachmentsUploaded(attachments: Attachment[]) {
    return attachments.filter(a => a.progress !== undefined || a.errorMessage !== undefined).length == 0;
}

export function mapPunchoutItemToCartItem(punchout: PunchoutItem, cartLineId: string): CartItem {
    return {
        lineId: cartLineId,
        category: { id: punchout.categoryId, code: punchout.categoryCode, description: punchout.categoryDescription },
        contract: null,
        description: punchout.description,
        details: "",
        imageHash: null,
        imageType: null,
        isPreferredItem: false,
        isPreferredSupplier: false,
        isQuantityBased: punchout.isQuantityBased,
        lineType: CartLineType.PunchOut,
        partNumber: punchout.itemNumber,
        productId: null,
        supplier: { id: punchout.supplierId, name: punchout.supplier },
        unit: { id: punchout.unitId, description: punchout.unitDescription },
        unitPrice: { currencyCode: punchout.currencyCode, currencyId: punchout.currencyId, value: punchout.unitPrice },
        purchasePolicies: punchout.purchasePolicies
    };
}

export function mapRequisitionLineToCartItem(product: RequisitionLine, cartLineId: string): CartItem {
    return {
        lineId: cartLineId,
        lineType: product.lineType,
        productId: product.item.productId ?? null,
        description: product.item.partDescription,
        details: product.details ?? "",
        category: { id: product.category?.id, code: product.category?.code, description: product.category?.description },
        contract: product.supplierContract ?? null,
        partNumber: product.item.partNumber,
        supplier: product.supplier ? { id: product.supplier.id, name: product.supplier.name } : null,
        unitPrice: { currencyCode: product.unitPrice.currencyCode, currencyId: product.unitPrice.currencyId, value: product.unitPrice.value },
        unit: { description: product.quantity.unitDescription, id: product.quantity.unitId },
        isQuantityBased: product.isQuantityBased,
        imageHash: product.imageHash ?? null,
        imageType: product.imageType ?? null,
        isPreferredItem: product.isPreferredItem,
        isPreferredSupplier: product.isPreferredSupplier,
        purchasePolicies: product.item.purchasePolicies
    };
}

export function defaultTaxIndicator(indicatorNumber: number): TaxGroup {
    return {
        id: null,
        code: null,
        description: null,
        indicatorNumber
    };
}