/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/OpenPeriods/openPeriodConfigurationTemplateRegistry"/>
const templateRegistry = {} as {[type: string]: string};

export function registerTemplate(type: string, template: string) {
    templateRegistry[type] = template;
}

export function getTemplate(type: string) {
    const template = templateRegistry[type];
    return template ? template : "Core:AdminPages/Company/OpenPeriods/openPeriodConfiguration.html";
}
