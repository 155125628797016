/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/registerCustomActions"/>
import * as CompanyCustomActions from "Core/Medius.Core.Web/Scripts/AdminPages/Company/customActions";
import * as AdministrationFavouriteLinkCustomActions from "Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/customActions";
import * as UserCustomActions from "Core/Medius.Core.Web/Scripts/AdminPages/User/customActions";

export function register() {
    CompanyCustomActions.register();
    AdministrationFavouriteLinkCustomActions.register();
    UserCustomActions.register();
}
