///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CopilotComments"/>
import * as React from "react";
import {
    IconCommunicationTranslateRegular
} from "@medius/ui-controls";
import { copilotEvents } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { getCommentsTranslation } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

type TokenType = 'TEXT' | 'MENTION';

interface Token {
    tokenType: TokenType;
    tokenValue: TokenValue;
}

interface TokenValue {
    title: string;
    description: string;
    value: string;
}

export interface CommentToTranslate {
    commentId: number;
    comment: string;
}

interface ICopilotComment {
    id: number;
    tokenizedText: Token[];
    author: string;
    createdDate: Date;
    hash: string;
    isFirstCarriedOver: boolean;
    canBeDeleted: boolean;
    deleteRequested: () => void;
    originalComment?: string;
    translatedComment?: string;
    originalLanguage?: string;
}

interface ICopilotCommentProps extends ICopilotComment {
    showOriginalComment?: boolean;
}

interface ICopilotCommentsProps {
    documentId: number;
    visibleComments: ICopilotComment[];
}

interface TooltipProps {
    title: string;
    description: string;
}

interface MentionProps {
    tokenValue: TokenValue;
}

interface TranslateIconWithTooltipProps {
    originalLanguage: string;
    originalComment: string;
}

const Tooltip = ({ title, description }: TooltipProps) => (
    <div className="copilotComment__tooltip">
        <div>
            {title && <span className="copilotComment__tooltip__title">{title}</span>}
            {description && <span className="copilotComment__tooltip__description">{description}</span>}
        </div>
    </div>
);
  
const Mention = ({ tokenValue }: MentionProps) => {
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

    return (
        <span 
            className="copilotComment__mention"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {tokenValue.value}
            {showTooltip && 
                <Tooltip title={tokenValue.title} description={tokenValue.description} />
            }
        </span>
    );
};

const TranslateIconWithTooltip = ({ originalLanguage, originalComment }: TranslateIconWithTooltipProps) => {
    const [alignClass, setAlignClass] = React.useState<string>("align-right");
    const iconRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const checkAlignment = () => {
            if (iconRef.current) {
                const rect = iconRef.current.getBoundingClientRect();
                const isNearRightEdge = window.innerWidth - rect.right < 300;
                setAlignClass(isNearRightEdge ? "align-left" : "align-right");
            }
        };

        checkAlignment();

        window.addEventListener("resize", checkAlignment);
        return () => window.removeEventListener("resize", checkAlignment);
    }, []);

    return (
        <div
            className={`copilotComment__translate__icon`}
            ref={iconRef}
        >
            <IconCommunicationTranslateRegular color="container" />
            {originalLanguage && originalComment && (
                <div className={`copilotComment__tooltip__dark ${alignClass}`}>
                    <span className="copilotComment__tooltip__title">
                        {getLabelTranslation("#Core/copilotTranslatedFromWithOriginalText", originalLanguage, originalComment)}
                    </span>
                </div>
            )}
        </div>
    );
};

const CopilotCommentContent = ({ 
    tokenizedText,
    showOriginalComment,
    originalComment,
    translatedComment,
    originalLanguage
}: { 
    tokenizedText: Token[],
    showOriginalComment: boolean,
    originalComment: string,
    translatedComment: string,
    originalLanguage: string
}) => {
    return(
        <div className="copilotComment">
            {!showOriginalComment && translatedComment && translatedComment !== "" ? (
                <div className="copilotComment__presentationContainer" data-testid="comment-content">
                    <div className="copilotComment__content">
                        {translatedComment}
                    </div>
                    <div className="copilotComment__translatedIcon">
                        <TranslateIconWithTooltip originalLanguage={originalLanguage!} originalComment={originalComment!} />
                    </div>
                </div>
            ) : (
                tokenizedText.map((token, index) => {
                    if (token.tokenType === 'TEXT') {
                        return token.tokenValue.value;
                    }
                    if (token.tokenType === 'MENTION') {
                        return <Mention key={index} tokenValue={token.tokenValue} />;
                    }
                    return null;
                })
            )}
            
        </div>
    );
};

const CopilotComment = ( comment: ICopilotCommentProps ) => {
    const formattedDate = new Date(comment.createdDate).toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    });

    return (
        <>
        {comment.isFirstCarriedOver && (
            <div className="row">
                <h4 className="center-align">{getLabelTranslation("#Core/commentsForPreviousVersionOfDocument")}</h4>
            </div>
        )}
        <div className="row display-card" data-testid="comment-single">
            <header className="comment__header">
                <h4 data-testid="comment-author">{comment.author}</h4>
                <h6 data-testid="comment-datetime">{formattedDate}</h6>
            </header>
            <div className="comment__body">
                <div className="comment__content" data-testid="comment-content">
                    <CopilotCommentContent 
                        tokenizedText={comment.tokenizedText} 
                        showOriginalComment={comment.showOriginalComment}
                        translatedComment={comment.translatedComment}
                        originalComment={comment.originalComment}
                        originalLanguage={comment.originalLanguage}
                    />
                </div>
                {comment.canBeDeleted && (
                    <div className="comment__button">
                        <button className="btn btn-small" onClick={() => comment.deleteRequested()}>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export function CopilotComments({ documentId, visibleComments }: ICopilotCommentsProps) {
    const [showOriginalComments, setShowOriginalComments] = React.useState<boolean>(true);
    const [isTranslating, setIsTranslating] = React.useState<boolean>(false);
    const [commentsToDisplay, setCommentsToDisplay] = React.useState<ICopilotComment[]>(visibleComments);

    const commentsRef = React.useRef<ICopilotComment[]>(visibleComments);
    const translationCache = React.useRef(new Map<number, { translation: string, originalLanguage: string }>());

    React.useEffect(() => {
        setCommentsToDisplay(visibleComments);
        commentsRef.current = visibleComments;
        window.postMessage({ type: copilotEvents.ShowOriginalComments }, "*");
    }, [visibleComments]);

    React.useEffect(() => {      
        const handleMessage = (event: MessageEvent) => {
            const { type } = event.data;
        
            if (type === copilotEvents.TranslateComments) {   
                if (!isTranslating){
                    translateComments();
                }                
            } else if (type === copilotEvents.ShowOriginalComments) {
                setShowOriginalComments(true);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const translateComments = async () => {
        try {
            setIsTranslating(true);

            const commentsToTranslate = commentsRef.current.filter(comment => 
                !translationCache.current.has(comment.id)
            ).map(comment => ({
                commentId: comment.id,
                comment: comment.tokenizedText.map(token => token.tokenValue.value).join(" ")
            }));

            let translatedComments: any[] = [];

            if (commentsToTranslate.length > 0) {
                const response = await getCommentsTranslation(documentId, commentsToTranslate);
                translatedComments = response.translatedComments;

                // Store new translations in the cache
                translatedComments.forEach(tc => {
                    translationCache.current.set(tc.commentId, { 
                        translation: tc.translation, 
                        originalLanguage: tc.originalLanguage 
                    });
                });
            }

            const updatedComments = commentsRef.current.map(comment => {
                const cachedTranslation = translationCache.current.get(comment.id);

                return {
                    ...comment,
                    originalComment: comment.tokenizedText.map(token => token.tokenValue.value).join(" "),
                    translatedComment: cachedTranslation?.translation ?? comment.translatedComment,
                    originalLanguage: cachedTranslation?.originalLanguage ?? comment.originalLanguage
                };
            });

            const hasTranslatedComments = updatedComments.some(comment => comment.translatedComment);

            if (hasTranslatedComments) {
                setCommentsToDisplay(updatedComments);
                setShowOriginalComments(false);
                window.postMessage({ type: copilotEvents.CommentsTranslated }, "*");
            } else {
                setShowOriginalComments(true);
                window.postMessage({ type: copilotEvents.ShowOriginalComments }, "*");
            }
        } catch (error) {
            setShowOriginalComments(true);
            window.postMessage({ type: copilotEvents.ShowOriginalComments }, "*");
        } finally {
            setIsTranslating(false);
        }
    };

    return (
        <>
            {commentsToDisplay.map(comment => (
                <CopilotComment
                    key={comment.hash}
                    {...comment}
                    showOriginalComment={showOriginalComments}
                />
            ))}
        </>
    );
}