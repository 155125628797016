/// <amd-module name="Core/Medius.Core.Web/Scripts/remediumMigratedViews"/>
// Auto-generated file - Generated with GenerateRemediumMigratedViewsPath.ps1 - DO NOT EDIT MANUALLY 
export const remediumMigratedViews = [
  "Display/SaveToEditView",
  "Display/Medius.Core.Entities.Address/AddressesTab",
  "Display/Medius.Core.Entities.Company/Editor",
  "Display/Medius.Core.Entities.CompanyIdentifier/IdentifiersTab",
  "Display/Medius.Core.Entities.Currency/Editor",
  "Display/Medius.Core.Entities.CurrencyRate/Editor",
  "Display/Medius.Core.Entities.CustomFields.CustomFieldsCompanyConfiguration/CustomFieldsConfiguration",
  "Display/Medius.Core.Entities.CustomFields.ListCustomField/Values",
  "Display/Medius.Core.Entities.Dashboard.DashboardContext/ActionAndControlTab",
  "Display/Medius.Core.Entities.Dashboard.Gadget/AccessControlTab",
  "Display/Medius.Core.Entities.Dashboard.Gadget/Editor",
  "Display/Medius.Core.Entities.Dashboard.RoleDashboard/RoleDashboardTab",
  "Display/Medius.Core.Entities.Dashboard.UserDashboard/UserDashboardTab",
  "Display/Medius.Core.Entities.DataAccessSchema/DataAccessSchemaTab",
  "Display/Medius.Core.Entities.DataAccessSchema/Editor",
  "Display/Medius.Core.Entities.DataAccessSchemaProperty/DataAccessSchemaPropertyTab",
  "Display/Medius.Core.Entities.DataObject/Editor",
  "Display/Medius.Core.Entities.DataObjectEditor.DateField/EditorFor",
  "Display/Medius.Core.Entities.DataObjectEditor.Field/EditorFor",
  "Display/Medius.Core.Entities.DataObjectEditor.OtherField/EditorFor",
  "Display/Medius.Core.Entities.DataObjectEditor.RelatedObjectField/EditorFor",
  "Display/Medius.Core.Entities.DataObjectEditor.TextField/EditorFor",
  "Display/Medius.Core.Entities.DataSearch.DataSearchUserConfiguration/AdvancedSearch",
  "Display/Medius.Core.Entities.DataSearch.RoleDataSearch/RoleDataSearchTab",
  "Display/Medius.Core.Entities.DataSearch.SharedDataSearch/ColumnsConfigurationTab",
  "Display/Medius.Core.Entities.DataSearch.SharedDataSearch/Editor",
  "Display/Medius.Core.Entities.DataSearch.UserDataSearch/UserDataSearchTab",
  "Display/Medius.Core.Entities.Integration.EDIDocumentImport/MappedDocument",
  "Display/Medius.Core.Entities.Integration.EDIDocumentImport/RawDocument",
  "Display/Medius.Core.Entities.Integration.EDIDocumentImport/StraightToArchiveArchiveConfirmationModal",
  "Display/Medius.Core.Entities.Integration.EDIDocumentImport/StraightToArchiveCreateConfirmationModal",
  "Display/Medius.Core.Entities.Integration.EDIDocumentMapper/DocumentSource",
  "Display/Medius.Core.Entities.Integration.EDIDocumentMapper/Editor",
  "Display/Medius.Core.Entities.Integration.MasterDataError/Editor",
  "Display/Medius.Core.Entities.Integration.MasterDataError/Errors",
  "Display/Medius.Core.Entities.Integration.Messages.ErrorMessage/Errors",
  "Display/Medius.Core.Entities.MessageTemplate/Body",
  "Display/Medius.Core.Entities.MessageTemplate/Edit",
  "Display/Medius.Core.Entities.MessageTemplate/FullBodyTemplate",
  "Display/Medius.Core.Entities.MessageTemplate/PartialBodyTemplate",
  "Display/Medius.Core.Entities.NewInbox.Folder/ColumnsRelation",
  "Display/Medius.Core.Entities.NewInbox.Folder/Editor",
  "Display/Medius.Core.Entities.ReminderDetails/ReminderDetails",
  "Display/Medius.Core.Entities.Reminders.CompanyReminderConfiguration/DefaultReminders",
  "Display/Medius.Core.Entities.Role/Services",
  "Display/Medius.Core.Entities.Security.IdentityProvider/Editor",
  "Display/Medius.Core.Entities.Security.RoleDataObjectAuthorization/DataObjectAuthorizationRowTpl",
  "Display/Medius.Core.Entities.Settings.AdministrationFavouriteLink/Editor",
  "Display/Medius.Core.Entities.Settings.AdministrationFavouriteLink/Roles",
  "Display/Medius.Core.Entities.Unit/Editor",
  "Display/Medius.Core.Entities.Validation.DocumentImportValidationRuleConfiguration/Editor",
  "Display/Medius.Data.DataDefinition/CustomFields",
  "Display/Medius.Data.DataDefinition/Editor",
  "Display/Medius.Data.DataDefinition/Relation",
  "Display/Medius.Data.DataDefinition/SimpleRelationEditor",
  "Display/Medius.Data.Document/BulkHandle",
  "Display/Medius.Data.Document/Creator",
  "Display/Medius.Data.Document/NamedCreator",
  "Display/Medius.Data.Entity/Attachments",
  "Display/Medius.Data.Entity/Comments",
  "Display/Medius.Data.Entity/DocumentImageTab",
  "Display/Medius.Data.Entity/EntityAttachments",
  "Display/Medius.Data.Entity/Errors",
  "Display/Medius.Data.Entity/HashFiles",
  "Display/Medius.Data.Entity/Links",
  "Display/Medius.Data.Entity/WorkflowHistory",
  "Display/Medius.Data.ImportConfiguration/IntegrationUpdate",
  "Display/Medius.Data.WorkflowMetadata.ActivityDefinition/DataAccessSchemaActivityTab",
  "Display/Medius.Data.XslTemplate/TemplateTab",
  "Display/Medius.Schedulers.ScheduledJobConfiguration/Editor",
  "Display/Medius.Validation.ValidatorConfiguration/Context",
  "Display/Medius.Validation.ValidatorConfiguration/Editor",
  "Shared/KeyboardShortcutsModal",
  "Shared/PendingAsyncExportsModal",
  "SpaApp/Templates/Administration/ObjectBrowser",
  "SpaApp/Templates/Document/Index",
  "SpaApp/Templates/Document/TypePicker",
  "SpaApp/Templates/Inbox/Home",
  "SpaApp/Templates/Inbox/TaskLabels",
  "SpaApp/Templates/UserSettings/Modal",
  "SpaApp/Templates/UserSettings/Tabs/DataSearchConfiguration",
  "SpaApp/Templates/UserSettings/Tabs/General"
];
