///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { CopilotEventPayload, IHashFile, IMessage, ITaskDocument, StreamCallScenario } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import {
    copilotEvents,
    copilotUnsupportedImageType,
    summarizeThisInvoiceQuestionId,
    translateThisInvoiceQuestionId
} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
 
const expenseInvoiceFullType = "Medius.ExpenseInvoice.Entities.ExpenseInvoice, Medius.ExpenseInvoice.Common";
const orderbasedInvoiceFullType = "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice, Medius.OrderbasedInvoice.Common";
const ediDocumentImportFullType = "Medius.Core.Entities.Integration.EDIDocumentImport, Medius.Core.Common";
 
export const isTaskDocumentSupportedByCopilot = (taskDocument: ITaskDocument): boolean => {
    return !taskDocument.isLongTermArchived &&
    (
        taskDocument.$type() === expenseInvoiceFullType ||
        taskDocument.$type() === orderbasedInvoiceFullType ||
        (
            taskDocument.$type() === ediDocumentImportFullType &&
            (
                taskDocument.DocumentType() === expenseInvoiceFullType.split(',')[0] ||
                taskDocument.DocumentType() === orderbasedInvoiceFullType.split(',')[0]
            )
        )
    );
};
 
export function calculateAgentVisibility(isModuleEnabled: boolean, taskDocument: ITaskDocument): boolean {
    const taskDocumentSupportedByCopilot: boolean = isTaskDocumentSupportedByCopilot(taskDocument);
 
    if(taskDocumentSupportedByCopilot){
        triggerCanduCopilotCustomerPopup();
    }
 
    return  isModuleEnabled && taskDocumentSupportedByCopilot;
}
 
//this function returns the calculated bool based on medius copilot requirements R1.3 and R1.4
export function calculateOperational(documentType: string, taskName: string){
    if(
        (documentType === expenseInvoiceFullType && taskName === getLabelTranslation("#ExpenseInvoice/CodeAndAuthorizeTaskGroupDescription") ) ||
        (documentType === orderbasedInvoiceFullType &&
            ( taskName === getLabelTranslation("#OrderbasedInvoice/AnalyzeDeviationsTaskGroupDescription") ) ||  
            ( taskName === getLabelTranslation("#OrderbasedInvoice/authorizeDeviationsTaskGroupDescription") ) ||  
            ( taskName === getLabelTranslation("#OrderbasedInvoice/authorizeInvoiceAmountTaskGroupDescription") )
        )
    ){
        return true;
    }
 
    return false;
}

export function isDocumentImportType(taskDocument: ITaskDocument): boolean {
    return taskDocument.$type() === ediDocumentImportFullType;
}

export function shouldCopilotDisplayImageBasedQuestions(hashFiles: IHashFile[]): boolean {
    if (!hashFiles) return false;

    return  hashFiles &&
            hashFiles.length > 0 &&
            hashFiles.every((hashObj: { Type: () => string }) => hashObj.Type() !== copilotUnsupportedImageType);
}

export function scrollToBottom(lastMessageRef: React.MutableRefObject<any>){
    if(lastMessageRef.current){
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
}
 
export function getDelegatedMessage(messages: string[]){
    const filteredDelegationMessage = messages.filter(message => {
        const comparisonString = message;
 
        const originalString = getLabelTranslation("#Core/documentHasBeenDelegatedFrom_user");
        const regexString = originalString.replace("{0}", '(.*)');
        const regex = new RegExp(regexString);
        const matchFromUser = regex.test(comparisonString);
 
        const originalString2 = getLabelTranslation("#Core/documentHasBeenDelegatedFrom_role_user");
        const regexString2 = originalString2.replace("{0}", '(.*)').replace("{1}", '(.*)');
        const regex2 = new RegExp(regexString2);
        const matchFromRoleUser = regex2.test(comparisonString);
 
        return (matchFromUser || matchFromRoleUser);
    });
   
    return filteredDelegationMessage.length > 0
        ? filteredDelegationMessage[0] + `. ${getLabelTranslation("#Core/temporaryDelegationShortDescription")}.`
        : "";
}
 
export function getEscalationMessage(messages: string[]){
    const personalRoleEscalationMessage = getLabelTranslation("#Core/escalationMessage_personalRole");
    const roleEscalationMessage = getLabelTranslation("#Core/escalationMessage_role");
 
    let foundPersonalEscalationMessage = false;
    let foundRoleEscalationMessage = false;
 
    for(const message of messages){
        if(message === personalRoleEscalationMessage){
           foundPersonalEscalationMessage = true;
           break;
        }
   
        const regexRoleEscalation = roleEscalationMessage.replace("{0}", "(.*)");
        const regex = new RegExp(regexRoleEscalation);
   
        const matchFromRoleEscalation = regex.test(message);
        if(matchFromRoleEscalation){
            foundRoleEscalationMessage = true;
            break;
        }
    }
   
    if(foundPersonalEscalationMessage || foundRoleEscalationMessage){
        return getLabelTranslation("#Core/escalationShortDescription");
    }
 
    return "";
}
 
export function getIsNotificationWindowVisible(){
    const durationDateString = localStorage.getItem("copilotNotificationWindowHideDuration");
 
    if(durationDateString === null)
        return true;
   
    const currentDate = new Date();
    const requestDate = new Date(durationDateString);
    return currentDate > requestDate;
}

export function getIsInvoiceImageTooltipVisible(){
    const isTooltipHidden = sessionStorage.getItem("isCopilotInvoiceImageTooltipHidden");
    return isTooltipHidden === null;
}

export function focusOnElement(elementRef: React.MutableRefObject<any>){
    elementRef.current && elementRef.current.focus();    
}
 
export function adjustPayloadBasedOnCopilotEventType(type: string, payload: CopilotEventPayload){
    let scenarioAdjustedPayload: CopilotEventPayload = null;
 
    switch (type) {
        case copilotEvents.PredefinedQuestionFromInvoiceImageTooltip:
        case copilotEvents.PredefinedQuestionFromNotificationWindow:
        case copilotEvents.FreeTextQuestion:
            scenarioAdjustedPayload = payload;
            break;
        case copilotEvents.TranslateThisInvoice:
            scenarioAdjustedPayload = {questionId: translateThisInvoiceQuestionId};
            break;
        case copilotEvents.SummarizeThisInvoice:
            scenarioAdjustedPayload = {questionId: summarizeThisInvoiceQuestionId};
            break;
        default: break;
    }
 
    return scenarioAdjustedPayload;
}
 
export function calculateCopilotSidebarAnchorCoords(isInFullscreen: boolean): React.CSSProperties{
    return isInFullscreen ?
        { position: "absolute", top: "-20px", right: "-44px" } :
        { position: "absolute", top: "-10px", right: "-24px" };
}
 
export const messageHasAnythingToDisplay = (message: IMessage) => {
    return message?.content ||
    message?.operationalInvoiceContextDto ||
    message?.nonOperationalInvoiceContextDto ||
    message?.supplierWidgetDto ||
    message?.isError;
};
 
export const updateAreaHeight = (areaRef: React.RefObject<HTMLTextAreaElement>, prompt: string) => {
    if (!areaRef.current) return;
    if(prompt != ""){
        const area = areaRef.current;
        area.style.height = '';
        const scrollHeight = Math.ceil(area.scrollHeight);
        area.style.height = `${scrollHeight-8}px`;
    } else {
        const area = areaRef.current;
        area.style.height = '16px';
    }
};
 
// Track copilot usage (this will impact showing showing engagement modal)
// Engagement modal will be shown when user does not use copilot for certain time.
export const trackSentCopilotPromptEventInCandu = (streamCallScenario: StreamCallScenario) =>
    (window as any).Candu?.getEventing().track('CopilotAdoption.SentCopilotPrompt', { source: streamCallScenario })
        .then(() => (window as any).Candu.refreshMembership());
 
export const triggerCanduCopilotCustomerPopup = () =>
    (window as any).Candu?.triggerModal({ slug: 'customers-with-copilot-popup' });