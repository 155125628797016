///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/CreateInvoiceViewModelExtension"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/mediusUtils";
import * as dateUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import PageableCollection =  require("Core/Medius.Core.Web/Scripts/Paging/PageableCollection");
import * as paymentDetailsDataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/paymentDetails";
import { PaymentDetailsHandlingType } from "Core/Medius.Core.Web/Scripts/AdminPages/Company/PaymentDetailsHandlingType";
import { CashDiscountViewModelFactory } from "Core/Medius.Core.Web/Scripts/Models/cashDiscountViewModelFactory";
import { SupplierInvoiceDatesUpdater } from "Core/Medius.Core.Web/Scripts/Models/supplierInvoiceDatesUpdater";
import { isNullOrUndefined, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");

function resolvePreferredPaymentDate(model:any) {
    const preferredPaymentDate = ko.observable(model.PreferredPaymentDate());

if (model.ImportedPreferredPayment() && model.ImportedPreferredPayment().Date()) {
        preferredPaymentDate(model.ImportedPreferredPayment().Date());
    }

    return preferredPaymentDate;
}

export function AddCreateInvoiceViewModelExtension(invoiceViewModel:any, context:any, amountsSectionViewModel:any, customUpdateCompanyDependantData:any) {
    invoiceViewModel.IsDraft = utils.getDocumentContext(context).IsDraft();
    invoiceViewModel.IsPreferredPaymentDateImported = !invoiceViewModel.IsDraft && invoiceViewModel.ImportedPreferredPayment() !== null && invoiceViewModel.ImportedPreferredPayment().Date() !== null;
    invoiceViewModel.HasAccessToPaymentDetails = ko.observable(false);

    invoiceViewModel.inDocumentImport = false;

    const preferredPaymentDate = resolvePreferredPaymentDate(invoiceViewModel);
    const dueDate = ko.observable(invoiceViewModel.DueDate());

    invoiceViewModel.IsDueDateSetManually = ko.observable(false);
    invoiceViewModel.IsPreferredPaymentDateSetManually = ko.observable(false);

    // We have to clear `PreferredPaymentDate` to not send to Backend, as it is computed property.
    invoiceViewModel.PreferredPaymentDate(null);

    invoiceViewModel.ComputedDueDate = ko.computed({
        read: () => {
            return dueDate();
        },
        write: (value:any) => {
            if(isNotNullOrUndefined(value) && !value.isComputed) {
                invoiceViewModel.IsDueDateSetManually(true);
            }
            if (isNotNullOrUndefined(value) && value.isComputed) {
                // need to set this to default(DateTime) due to required field validator
                // and the lookup DueDateLookupSetup is expecting default(DateTime) to recalculate the date during import
                invoiceViewModel.DueDate(dateUtils.getDefault());
                dueDate(value.computedDate);
            }
            else {
                invoiceViewModel.DueDate(value);
                dueDate(value);
            }
        }
    });

    invoiceViewModel.ComputedPreferredPaymentDate = ko.computed({
        read: () => {
            return preferredPaymentDate();
        },
        write: (value:any) => {
            invoiceViewModel.IsPreferredPaymentDateSetManually(true);

            if (value === null) {
                invoiceViewModel.ImportedPreferredPayment(null);
                preferredPaymentDate(null);
            } else {
                invoiceViewModel.ImportedPreferredPayment(CashDiscountViewModelFactory.create(value, null));
                preferredPaymentDate(value);
            }
            invoiceViewModel.ManuallySetPreferredPayment(null);
        }
    });

    invoiceViewModel.optionsCaptionText = globalization.getLabelTranslation("#Core/LBL_CHOOSE");

    invoiceViewModel.updateInvoiceLineCodingLineMapping = () => {
        if (!invoiceViewModel.hasCoding())
            return;

        if (invoiceViewModel.IsInvoiceLinesLoaded()) {
            invoiceViewModel.updateInvoiceLineCodingLineHandling();
        }
        invoiceViewModel.isInvoiceLinesLoadedSub = invoiceViewModel.IsInvoiceLinesLoaded.subscribe(
            () => {
                invoiceViewModel.isInvoiceLinesLoadedSub.dispose();
                invoiceViewModel.isInvoiceLinesLoadedSub = null;

                invoiceViewModel.updateInvoiceLineCodingLineHandling();
            }
        );
    };

    const invoiceLineCodingLineMap:any[] = [];

    invoiceViewModel.updateInvoiceLineCodingLineHandling = () => {
        invoiceViewModel.Lines().forEach((invoiceLine:any) => {
            const codingLines = _.filter(invoiceViewModel.Accounting().DimensionStrings(),
                (codeString) => { return codeString.InvoiceLineNumber() === invoiceLine.LineNumber(); });

            if (isNotNullOrUndefined(codingLines))
                invoiceLineCodingLineMap[invoiceLine.ViewId()] = codingLines.map((c) => { return c.ViewId(); });
        });
    };

    invoiceViewModel.synchronizeCodingLineInvoiceLineNumbers = () => {
        if (!invoiceViewModel.hasCoding())
            return;

        invoiceViewModel.Lines().forEach((invoiceLine:any) => {
            const codingLinesViewIds = invoiceLineCodingLineMap[invoiceLine.ViewId()];

            codingLinesViewIds.forEach((cv:any) => {
                invoiceViewModel.Accounting().DimensionStrings().forEach((codeString:any) => {
                    if (codeString.ViewId() === cv
                        && codeString.InvoiceLineNumber() !== invoiceLine.LineNumber()) {
                        codeString.InvoiceLineNumber(invoiceLine.LineNumber());
                    }
                });
            });
        });
    };

    invoiceViewModel.Lines.extend({
        addItemsSelectionSupport: {}
    });

    invoiceViewModel.checkIfHasAccessToPaymentDetails = async (companyId:any) => {
        const uri = `payment-details-configuration/shouldShowPaymentDetails/${companyId}`;
        try {
            const data = await rest.get<boolean>(uri);
            invoiceViewModel.HasAccessToPaymentDetails(data);
        }
        catch (error) {
            handleError(error);
        }
    };

    invoiceViewModel.supplierExistsInCompany = (companyId:any) => {
        if (isNullOrUndefined(invoiceViewModel.Supplier()) || invoiceViewModel.Supplier().Id() === 0)
            return;

        const params = {
            companyId: companyId,
            supplierId: invoiceViewModel.Supplier().Id()
        },
            successCallback = (res:boolean) => {
                if (res === false) {
                    notification.error(globalization.getLabelTranslation("#Enterprise/supplierNotFound_SupplierName_Company",[invoiceViewModel.Supplier().Name(), invoiceViewModel.Company().Name()]));
                }
            };

        utils.ajax("SupplierExistsInCompany", "MasterDataService", params, successCallback);
    };

    invoiceViewModel.updateCompanyDependantData = customUpdateCompanyDependantData 
        ? customUpdateCompanyDependantData 
        : (newCompanyId:any, _:any) => {
            const params = {
                companyId: newCompanyId,
                customFields: invoiceViewModel.CustomFieldsFromEdi()
            },
                successCallback = (data:any) => {
                    if (!invoiceViewModel) {
                        return;
                    }
                    const perspectiveVM = context.create(data.LinesCustomFields.Perspective);
                    invoiceViewModel.LinesCustomFieldsPerspective(perspectiveVM);
                    invoiceViewModel.CustomFields(context.create(data.CustomFields));
                    invoiceViewModel.UseTaxCodes(data.UseTaxCodes);
                    invoiceViewModel.UseTaxCodesOnHead(data.UseTaxCodesOnHead);
                    invoiceViewModel.UseTwoTaxIndicators(data.UseTwoTaxIndicators);
                    invoiceViewModel.PostingAndPaymentDatesAlignedByDefault(
                        data.PostingAndPaymentDatesAlignedByDefaultPerDocumentType
                            .find((x: any) => x.DocumentType == type.getTypeName(invoiceViewModel.$type()))
                            .PostingAndPaymentDatesAlignedByDefault ?? false
                    );
                    invoiceViewModel.TaxIndicator1Label(data.TaxIndicator1Label);
                    invoiceViewModel.TaxIndicator2Label(data.TaxIndicator2Label);
                    invoiceViewModel.PaymentDetailsHandlingType(data.PaymentDetailsHandlingType);
                    invoiceViewModel.UseStraightToArchive(data.UseStraightToArchive);
                    invoiceViewModel.ShowVatDate(data.ShowVatDate);
                    invoiceViewModel.supplierExistsInCompany(newCompanyId);
                    invoiceViewModel.checkIfHasAccessToPaymentDetails(newCompanyId);
                    invoiceViewModel.Lines().forEach((line:any) => {
                        line.CustomFields().Perspective(perspectiveVM);
                    });

                    amountsSectionViewModel.updateCompanyDependentData(data.TaxFieldsConfiguration);
                };

            utils.ajax("GetCompanyDependantData", "MasterDataService", params, successCallback);
        };

    if (invoiceViewModel.Company() && invoiceViewModel.Company().Id() !== 0) {
        context.getData().companyId = invoiceViewModel.Company().Id();
        invoiceViewModel.updateCompanyDependantData(invoiceViewModel.Company().Id(), true);
    }

    invoiceViewModel.SelectedLines = invoiceViewModel.Lines.getSelectedItems();

    invoiceViewModel.removeLines = () => {
        invoiceViewModel.SelectedLines().forEach((line:any) => {
            invoiceViewModel.removeCodingLines(line);
            invoiceViewModel.Lines.remove(line);

            invoiceViewModel.synchronizeCodingLineInvoiceLineNumbers();
        });
    };

    invoiceViewModel.removeCodingLines = (invoiceLine:any) => {
        if (!invoiceViewModel.hasCoding())
            return;

        const codingLinesViewIds = invoiceLineCodingLineMap[invoiceLine.ViewId()];

        codingLinesViewIds.forEach((cv:any) => {
            invoiceViewModel.Accounting().DimensionStrings().forEach((codeString:any) => {
                if (codeString.ViewId() === cv)
                    invoiceViewModel.Accounting().DimensionStrings.remove(codeString);
            });
        });
    };

    invoiceViewModel.selectLine = (line:any) => {
        if (!line.IsSelected()) {
            ko.utils.arrayForEach(invoiceViewModel.Lines(), (item:any) => {
                if (item === line)
                    item.IsSelected(true);
                else
                    item.IsSelected(false);
            });
        }
    };

    if (!invoiceViewModel.InvoiceDate()) {
        invoiceViewModel.InvoiceDate(dateUtils.today());
    }
    if (!invoiceViewModel.PreliminaryBookingDate()) {
        invoiceViewModel.PreliminaryBookingDate(invoiceViewModel.InvoiceDate());
    }
    if (!invoiceViewModel.FinalBookingDate()) {
        invoiceViewModel.FinalBookingDate(invoiceViewModel.InvoiceDate());
    }

    invoiceViewModel.preliminaryBookingDateSub = invoiceViewModel.PreliminaryBookingDate.subscribe((newValue:any) => {
        invoiceViewModel.FinalBookingDate(newValue);
    });

    invoiceViewModel.UseTaxCodes = ko.observable(false);

    invoiceViewModel.UseTaxCodesOnHead = ko.observable(false);

    invoiceViewModel.UseTwoTaxIndicators = ko.observable(false);

    invoiceViewModel.PostingAndPaymentDatesAlignedByDefault = ko.observable(false);

    invoiceViewModel.TaxIndicator1Label = ko.observable(null);

    invoiceViewModel.TaxIndicator2Label = ko.observable(null);

    invoiceViewModel.PaymentDetailsHandlingType = ko.observable(PaymentDetailsHandlingType.Disabled);
    invoiceViewModel.ShouldDisplayPaymentDetails = ko.pureComputed(() => {
        const pdType = invoiceViewModel.PaymentDetailsHandlingType();
        return (pdType === PaymentDetailsHandlingType.UsePaymentDetails || pdType === PaymentDetailsHandlingType.ValidatePaymentDetails) && invoiceViewModel.HasAccessToPaymentDetails();
    });

    invoiceViewModel.UseStraightToArchive = ko.observable(false);

    invoiceViewModel.ShowVatDate = ko.observable(false);

    invoiceViewModel.ShowTaxIndicator1 = ko.computed(() => {
        return invoiceViewModel.UseTaxCodes() || invoiceViewModel.UseTaxCodesOnHead();
    });

    invoiceViewModel.ShowTaxIndicator2 = ko.computed(() => {
        return invoiceViewModel.UseTaxCodes() && invoiceViewModel.UseTwoTaxIndicators();
    });

    invoiceViewModel.IsLineSelected = ko.computed(() => {
        return invoiceViewModel.SelectedLines().length > 0;
    });

    invoiceViewModel.ShowStraightToArchive = ko.computed(() => {
        return invoiceViewModel.UseStraightToArchive() && utils.getDocumentContext(context).IsInEDI();
    });

    invoiceViewModel.canBeReclassified = ko.observable(true);

    invoiceViewModel.showCustomFields = ko.pureComputed(() => {
        return invoiceViewModel.CustomFields() && invoiceViewModel.CustomFields().Perspective && invoiceViewModel.CustomFields().AreAnyFieldsActive;
    });

    invoiceViewModel.invoiceDateSub = invoiceViewModel.InvoiceDate.subscribe(() => {
        if (invoiceViewModel.Amount() === null)
            invoiceViewModel.updateAmounts();
    });

    invoiceViewModel.companySub = invoiceViewModel.Company.subscribe((newCompany:any) => {
        if (invoiceViewModel.Amount() === null)
            invoiceViewModel.updateAmounts();
        invoiceViewModel.PaymentTerm(null);
        invoiceViewModel.PaymentDetails(null);
        context.getData().companyId = isNotNullOrUndefined(newCompany) ? newCompany.Id() : 0;
        if (newCompany) {
            invoiceViewModel.updateCompanyDependantData(newCompany.Id());
            invoiceViewModel.VatDate(null);
        }
    });

    //do not inline it! it's overriden in PaymentRequestCreateStep
    invoiceViewModel.onCurrencyChanged = () => {
        invoiceViewModel.updateAmounts();
    };

    invoiceViewModel.selectedCurrencySub = invoiceViewModel.amountsSectionViewModel.SelectedCurrency.subscribe(() => {
        invoiceViewModel.onCurrencyChanged();
    });


    invoiceViewModel.isUpdating = false;


    invoiceViewModel.updateAmounts = () => {
        if (!amountsSectionViewModel.SelectedCurrency())
            return;

        amountsSectionViewModel.updateAmounts();

        const newCurrencyCode = amountsSectionViewModel.SelectedCurrency().Code();
        ko.utils.arrayForEach(invoiceViewModel.Lines(), (line:any) => {
            line.isUpdating = true;
            utils.updateAmountCurrencyCode(line.Amount, newCurrencyCode);
            utils.updateUnitPriceCurrencyCode(line.UnitPrice, newCurrencyCode);
            line.isUpdating = false;
        });

        if (invoiceViewModel.Accounting() && invoiceViewModel.Accounting().TotalNet) {
            utils.updateAmountCurrencyCode(invoiceViewModel.Accounting().TotalNet, newCurrencyCode);
            if (invoiceViewModel.Accounting().DimensionStrings()) {
                invoiceViewModel.Accounting().DimensionStrings().forEach((codeString:any) => {
                    codeString.AmountInfo().updateAmounts(newCurrencyCode);
                });
            }
            if (invoiceViewModel.Accounting().BalanceCodeString()) {
                invoiceViewModel.Accounting().BalanceCodeString().AmountInfo().updateAmounts(newCurrencyCode);
            }
        }
        invoiceViewModel.isUpdating = false;
    };

    invoiceViewModel.companyId = ko.computed(() => {
        if (invoiceViewModel.Company()) {
            return invoiceViewModel.Company().Id();
        }
        return 0;
    });

    invoiceViewModel.supplierId = ko.computed(() => {
        if (invoiceViewModel.Supplier()) {
            return invoiceViewModel.Supplier().Id();
        }
        return 0;
    });

    invoiceViewModel.supplierSub = invoiceViewModel.Supplier.subscribe(() => {
        amountsSectionViewModel.updateCurrencyOnSupplierChange(invoiceViewModel.Supplier);

        invoiceViewModel.PaymentTerm(null);
        invoiceViewModel.PaymentDetails(null);
        invoiceViewModel.paymentDetailsDataProvider.resetSupplier(invoiceViewModel.supplierId());
    });

    invoiceViewModel.hasCoding = () => {
        return isNotNullOrUndefined(ko.unwrap(invoiceViewModel.Accounting))
            && isNotNullOrUndefined(ko.unwrap(invoiceViewModel.Accounting().DimensionStrings));
    };

    invoiceViewModel.PageableLines = new PageableCollection(invoiceViewModel.Lines, null);

    if (invoiceViewModel.hasCoding()) {
        invoiceViewModel.codingLinesSub = invoiceViewModel.Accounting().DimensionStrings.subscribe(invoiceViewModel.updateInvoiceLineCodingLineMapping);
    }

    invoiceViewModel.paymentDetailsDataProvider = paymentDetailsDataProviderFactory.create(invoiceViewModel.supplierId());

    const updatePreferredPaymentDate = (response:any) => {
        preferredPaymentDate(response.preferredPaymentDate);
    };
    invoiceViewModel.invoiceDatesUpdater = utils.getDocumentContext(context).IsInEDI()
        ? SupplierInvoiceDatesUpdater.forEdiStep(invoiceViewModel, updatePreferredPaymentDate)
        : SupplierInvoiceDatesUpdater.forCreateStep(invoiceViewModel, updatePreferredPaymentDate);

    const isDueDateComputed = () => {
        return invoiceViewModel.DueDate().getFullYear() === 1;
    };

    if (!invoiceViewModel.DueDate() || isDueDateComputed() || !invoiceViewModel.ComputedPreferredPaymentDate()) {
        invoiceViewModel.invoiceDatesUpdater.apply();
    }

    invoiceViewModel.translations = {
        ValidationErrorTaxGroupDoesNotExist: globalization.getLabelTranslation("#Enterprise/ValidationErrorTaxGroupDoesNotExist")
    };

    invoiceViewModel.dispose = () => {
        invoiceViewModel.PageableLines.dispose();
        invoiceViewModel.supplierSub.dispose();
        invoiceViewModel.companyId.dispose();
        invoiceViewModel.supplierId.dispose();
        invoiceViewModel.companySub.dispose();
        invoiceViewModel.selectedCurrencySub.dispose();
        invoiceViewModel.invoiceDateSub.dispose();
        invoiceViewModel.invoiceDatesUpdater.dispose();
        invoiceViewModel.IsLineSelected.dispose();
        invoiceViewModel.ShowTaxIndicator2.dispose();
        invoiceViewModel.ShowTaxIndicator1.dispose();
        invoiceViewModel.preliminaryBookingDateSub.dispose();
        invoiceViewModel.showCustomFields.dispose();
        invoiceViewModel.amountsSectionViewModel.dispose();

        if (invoiceViewModel.hasCoding()) {
            invoiceViewModel.codingLinesSub.dispose();
        }
        if (isNotNullOrUndefined(invoiceViewModel.isInvoiceLinesLoadedSub)) {
            invoiceViewModel.isInvoiceLinesLoadedSub.dispose();
        }

        invoiceViewModel = null;
    };
}