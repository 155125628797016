///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/asyncTemplate"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/lib/ajax";
import {getCustomTemplate} from "Core/Medius.Core.Web/Scripts/customTabs/customTabs";
import { remediumMigratedViews } from "Core/Medius.Core.Web/Scripts/remediumMigratedViews";
import * as _ from "underscore";
import { isRemediumViewHostingEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

const mediusBoundViewKey = "medius-bound-model";

function hasBoundDataChanged(element:any, options:any) {
    const boundData = koUtils.domData.get(element, mediusBoundViewKey);
    if (!boundData) {
        return true;
    }
    return _(options).chain().map(function (v, k) {
        return boundData[k] !== v;
    }).any(_.identity).value();
}

function cleanChildren(element:any) {
    $(element).children().each(function () {
        ko.removeNode(this);
    });
}

function parseBinding(binding:any):any {
    switch (typeof binding) {
        case "function":
            return parseBinding(binding());
        case "string":
            return {
                view: binding,
                model: null,
                afterRender: null,
                tabMenuContainer: null,
                taskButtonsContainer: null
            };
        case "object":
            _(binding).each(function (v, k) {
                binding[k] = koUtils.unpack(v);
            });
            return binding;
        default:
            throw new Error("Invalid type of parameter in binding: " + typeof binding);
    }
}

function executeHandler(handler:any, ...args:any) {
    /*jshint validthis:true */
    if (!handler) {
        return;
    }
    if (typeof handler !== "function") {
        throw new Error("Passed handler must be a function");
    }

    const call = Array.prototype.slice.call([handler, ...args], 1);
    handler.apply(this, call);
}

function prepareContext(currentContext:any, options:any) {
    return options.model ?
        currentContext.createChildContext(options.model) :
        currentContext;
}

function fetchView(viewPath: any) {
    let pathAction;

    if (isRemediumViewHostingEnabled() && remediumMigratedViews.includes(viewPath)) {
        pathAction = path.toAction("Get", "~/remedium-view/Template");
    }
    else {
        pathAction = path.toAction("Get", "~/Template");
    }
    pathAction = [pathAction, viewPath].join("/");

    return ajax(pathAction, {
        type: "GET",
        dataType: 'html',
        async: true,
        cache: true
    });
}

/*
* Workaround for jScrollPane adding the div to the nodes on which it has been defined.
* In such case, view content content must be applied to this node instead of the original element.
*/
function getLeafOrOriginal(element:any) {
    const leaf = $(element).find(":not(:has(*))");

    if (leaf.length > 1) {
        throw new Error("Found multiple leaf nodes, binding cannot be applied");
    } else if (leaf.length === 1) {
        return leaf[0];
    }
    return element;

}

function processView(target:any, html:any) {
    ko.cleanNode(target);
    $(target).empty();
    return $(html).appendTo(target);
}

function processCustomView(target:any, template:any) {
    ko.cleanNode(target);
    $(target).empty();
    const templateContent = document.getElementById(template).innerHTML;
    return $(templateContent).appendTo(target);
}

function applyView(element:any, bindingContext:any, options:any, loadComplete:any) {
    ko.applyBindingsToDescendants(bindingContext, element);
    koUtils.domData.set(element, mediusBoundViewKey, options);
    executeHandler(options.afterRender, element, options);
    loadComplete.resolve();
}

function handleFailure(target:any) {
    $('<div class="alert alert-error">' +
        globalization.getLabelTranslation("#Core/couldNotFindViewForDocTypeInfo") +
        '</div>').appendTo(target);
}

const asyncTemplate = {
    init: function (element:any) {
        koUtils.addDisposeCallback(element, function () {
            koUtils.domData.set(element, mediusBoundViewKey, null);
        });
        return { controlsDescendantBindings: true };
    },
    update: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any, context:any) {
        const binding = bindingAccessor();
        const options = parseBinding(binding);
        const viewPath = ko.utils.unwrapObservable(options.view);
        const target = getLeafOrOriginal(element);

        if (hasBoundDataChanged(element, options)) {
            const bindingContext = prepareContext(context, options);
            cleanChildren(target);
            const loadComplete = $.Deferred();
            if (bindingContext.$loadComplete) {
                bindingContext.$loadComplete.push(loadComplete);
            }

            const customTemplate = getCustomTemplate(viewPath);

            if (customTemplate) {
                processCustomView(target, customTemplate);
                applyView(element, bindingContext, options, loadComplete);
            } else {
                fetchView(viewPath)
                    .pipe(function (html:any) { processView(target, html); })
                    .pipe(function () { applyView(element, bindingContext, options, loadComplete); })
                    .fail(function () { handleFailure(target); });
            }
        }
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("asyncTemplate", asyncTemplate);
}