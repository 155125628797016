///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PromptInput" />
import * as React from "react";
import { IconActionCloseRegular, IconCommunicationPaperPlaneFill } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { LegalQuestionsContextMenu } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/LegalQuestionsContextMenu";
import { FeedbackModal } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/FeedbackModal";
import { updateAreaHeight } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { useCopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext";

interface IPromptInputProps{
    promptInputRef: React.MutableRefObject<any>
    sendFreetextQuestion: (freetextQuestion: string, isFollowupQuestion: boolean) => Promise<void>;
    sendPredefinedQuestion: (predefinedQuestion: string, isRetry?: boolean) => Promise<void>
}

export function PromptInput({ promptInputRef, sendFreetextQuestion, sendPredefinedQuestion }: IPromptInputProps){
    const { 
        documentId,
        workflowStepName,
        invoiceType,
        setIsStreamActive,
        requestTimeout,
        abortController,
        isStreamActive,
        conversationId
    } = useCopilotContext();
    const [prompt, setPrompt] = React.useState("");
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState(false);

    React.useEffect(() => {
        updateAreaHeight(promptInputRef, prompt);
    }, [prompt, promptInputRef]);

    const handlePromptInputChange = (e: any) => {
        setPrompt(e.target.value);
        updateAreaHeight(promptInputRef, e.target.value);
    };

    const handlePromptInputKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            sendFreetextQuestion(prompt, false);
            setPrompt("");
        }
    };

    const isPromptInputDisabled = () => {
        if (workflowStepName != "EndTask" && workflowStepName != "Archive"){
            return isStreamActive;
        }
        return true;
    };

    const handleAbort = () => {
        if (isStreamActive) {
            abortController.abort();
            clearTimeout(requestTimeout);
            setIsStreamActive(false);
        }
    };

    const handleSubmitPrompt = () => {
        if(prompt.length === 0 || isStreamActive)
            return;
        sendFreetextQuestion(prompt, false);
        setPrompt("");
    };

    return (
        <>
        <div className="mediusAgent__promptInput">
            <LegalQuestionsContextMenu 
                buttonClickCb={sendPredefinedQuestion} 
                onOpenFeedbackModal={() => setIsFeedbackModalOpen(true)}
                isFeedbackModalOpen={isFeedbackModalOpen}
            />
            <textarea
                className="mediusAgent__promptInput__textarea"
                value={prompt}
                placeholder={getLabelTranslation("#Core/askMeAnythingAboutThisInvoice")}
                ref={promptInputRef}
                onChange={handlePromptInputChange}
                onKeyDown={handlePromptInputKeyDown}
                disabled={isPromptInputDisabled()}
                data-testid="apaAgentPromptInput"
            />
            {isStreamActive ?
                <div
                    className="mediusAgent__promptInput__chatIcon"
                    onClick={handleAbort}
                    data-testid="apaAgentCancelRequestButton"
                >
                    <IconActionCloseRegular size="medium" color="brand" />
                </div>
                : <div
                    className="mediusAgent__promptInput__chatIcon"
                    onClick={handleSubmitPrompt}
                    data-testid="apaAgentSendMessageButton"
                >
                    <IconCommunicationPaperPlaneFill size="medium" color="brand" />
                </div>
            }
            </div>
            <FeedbackModal 
                isOpen={isFeedbackModalOpen} 
                onClose={() => setIsFeedbackModalOpen(false)} 
                documentId={documentId} 
                conversationId={conversationId} 
                invoiceType={invoiceType} 
                workflowStepName={workflowStepName}
            />
        </>
    );
}