/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/cart/cart"/>

import { Attachment } from "./attachment";
import { CodingAndTax } from "./coding";
import { FreeTextFormFieldType } from "./freeTextItem";

export type Cart = Readonly<{
    cartLines: CartLine[];
    details: CartDetails;
}>;

export enum CartLineType {
    Catalog,
    FreeText,
    PunchOut,
    FreeTextForm
}

export type CartLine = Readonly<{
    id: string,
    item: CartItem,
    quantity: CartQuantity,
    codingLines: CartCodingLine[],
    remainingAmountToCode: number;
    distributeAmountEvenly: boolean,
    taxCodes: CartTaxCode[],
    taxIndicators: CartTaxIndicator[]
    templateId?: number,
    templateLineId?: number,
    quantityResolution: number,
    unitPriceResolution: number,
    amountResolution: number,
    additionalInformation: string,
    deliveryDate: string,
    deliveryAddress: Address;
    isDeliveryAddressSelectedByUser?: boolean;
    databaseId?: number
    dimensionApprovalObjectId?: number;
    fromSplit?: boolean;
}>;

export type CartItem = Readonly<{
    lineId: string,
    lineType: CartLineType,
    productId: number,
    description: string,
    details: string,
    category: CartCategory,
    contract: CartContract,
    partNumber: string,
    supplier: CartSupplier,
    unitPrice: CartUnitPrice,
    unit: CartUnit,
    isQuantityBased: boolean,

    imageHash: string,
    imageType: string,

    isPreferredItem: boolean,
    isPreferredSupplier: boolean,

    freeTextFormId?: number,
    freeTextFormFields?: CartFreeTextFormField[]

    purchasePolicies: CartPurchasePolicyData[]
}>;

export type CartPurchasePolicyData = {
    id: number,
    name: string,
    description: string,
    link: string,
    hash: string,
    isConfirmationRequired: boolean
};

export type CartUnitPrice = Readonly<{
    currencyId: number;
    currencyCode: string;
    value: number;
}>;

export type CartUnit = Readonly<{
    id: number,
    description: string
}>;

export type CartCategory = {
    id: number;
    code: string;
    description: string;
    setManually?: boolean;
};

export type CartSupplier = {
    id: number;
    name: string;
};

export type CartContract = {
    id: number;
    title: string;
};

export type CartQuantity = {
    value: number,
};

export type CopySource = Readonly<{
    sourceDocumentIdentifier: string,
    copyingUserId: number,
    copiedTimestamp: string
}>;

export type CartDetails = Readonly<{
    noteToSupplier: NoteToSupplier | undefined;
    deliveryDate: string | null;
    invoiceAddress: Address;
    deliveryAddress: Address;
    comments: Comment[],
    attachments: Attachment[],
    entityViewId: string,
    shippingMarks: string,
    ourReferenceRole: OurReferenceRole,
    orderTypeId: number,
    buyerRoleId: number,
    goodsReceiptByRoleId: number,
    purchasePolicyConfirmationPending: boolean,
    isDeliveryAddressSelectedByUser: boolean,
    copySource: CopySource
}>;

export type OurReferenceRole = Readonly<{
    ourReferenceRoleId: number,
    ourReferenceRoleOwnerId?: number,
}>;

export type NoteToSupplier = Readonly<{
    note: string;
    userFirstLastName: string;
    userId: number | undefined;
    date: string;
}>;

export type CartCodingLine = Readonly<{
    amount: number;
    dimensionNames: CartDimensionName[];
    dimensionValues: CartDimensionValue[];
    databaseId?: number;
}>;

export type CartTaxIndicator = Readonly<{
    label: string;
    indicatorNumber: number;
}>;

export type CartFreeTextFormField = Readonly<{
    databaseId?: number;
    fieldId: number;
    fieldType: FreeTextFormFieldType;
    fieldValue: any;
}>;

export type CartDimensionValue = Readonly<{
    id: number | undefined;
    dimensionNumber: number;
    value: string;
    description: string;
    isFixed: boolean;
    isReadOnly: boolean;
    errors: string[];
}>;

export type CartDimensionName = Readonly<{
    dimensionNumber: number;
    dimensionTitle: string;
    dimensionName: string;
    dimensionMaxLength?: number;
}>;

export type CartTaxCode = Readonly<{
    id: number | undefined;
    code: string;
    indicatorNumber: number;
    description: string;
}>;

export type Address = Readonly<{
    id: number,
    addressType: string,
    recipient?: string,
    addressLine: string,
    addressLine2?: string,
    division?: string,
    building?: string,
    city?: string,
    zip?: string,
    state?: string,
    country?: string,
    phone?: string,
    email?: string,
    fax?: string,
    homepage?: string,
    location?: string,
    name?: string,
    limitingLocation?: LimitingLocation
}>;

export type LimitingLocation = Readonly<{
    id: number,
    name: string
}>;

export type CompanyAddressesDto = Readonly<{
    homeAddress: Address,
    deliveryAddress: Address,
    invoiceAddress: Address
}>;

export type Comment = Readonly<{
    localId: number;
    id: number;
    comment: string;
    userFirstLastName: string;
    userId: number | undefined;
    date: string;
}>;

export type Requisition = {
    lines: RequisitionLine[];
    commonSupplierId: number;
};

export type RequisitionLine = {
    templateLineId?: number,
    item: ItemData,
    supplier: SupplierData,
    lineType: CartLineType,
    category: CategoryData,
    quantity: RequisitionLineQuantityData,
    unitPrice: RequisitionLineUnitPriceData,
    isQuantityBased: boolean,
    supplierContract: RequisitionLineContractData,
    additionalInformation: string,
    codingAndTax: CodingAndTax,
    isPreferredSupplier: boolean,
    imageHash: string,
    imageType: string,
    isPreferredItem: boolean,
    details: string
};

export type SupplierData = {
    id: number,
    name: string,
    currency: CurrencyData
};

export type CurrencyData = {
    id: number,
    code: string
};

export type CategoryData = {
    id: number,
    code: string,
    description: string
};

export type ItemData = {
    productId?: number,
    partNumber: string,
    partDescription: string,
    purchasePolicies: CartPurchasePolicyData[]
};

export type RequisitionLineQuantityData = {
    unitId: number,
    unitDescription: string,
    value: number
};

export type RequisitionLineUnitPriceData = {
    currencyId: number,
    currencyCode: string,
    value: number
};

export type RequisitionLineContractData = {
    id: number,
    title: string
};

export type ProductCoding = {
    amount: number,
    dimensionNames: CartDimensionName[],
    dimensionValues: CartDimensionValue[]
};

export type CodingLineBudgetPosition = {
    budgetKeyId: number,
    budgetKeyName: string,
    ceilingType: CeilingType,
    remaining?: number,
    consumed: number,
    currencyCode: string,
    dimensionValueIds: number[],
    isBlockingSubmission: boolean
};

export type CeilingType = "HardCeiling" | "SoftCeiling" | "None";