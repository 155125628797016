/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewer"/>
import { observable, pureComputed, observableArray } from "knockout";
import * as uxLog from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewerUxLog";
import * as langMapper from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewerLangMapper";
import { ImageSelector } from "Core/Medius.Core.Web/Scripts/components/pdfViewer/imageSelector/imageSelector";
import { PdfFile } from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfFile";
import { create as createSendEmail } from "Core/Medius.Core.Web/Scripts/Medius/core/image/sendEmail";
import { getCurrent, getLanguageCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";

import { InvoiceImageTooltip } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/InvoiceImageTooltip/InvoiceImageTooltip";
import * as copilotServices from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { shouldCopilotDisplayImageBasedQuestions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { translateTheInvoiceContextToMyLanguage, summarizeTheInvoiceContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";

export class PdfViewer {
    private hashFiles: any[];
    private documentType: string;
    private documentId: number;
    public currentFile = observable<PdfFile>(null);
    private pdfJsLangCode = "";
    private imageSelector = new ImageSelector();

    public company: any;
    public sendEmailForm = observable(null);
    public isImageSelectorVisible = observable(true);
    public pdfViewerUrl: ko.Computed<string>;
    private supportedImageTypes = ["EDIImage", "InvoiceImage", "DocumentImage", "MigratedInvoiceImage", "SupplierContractImage", "SupplierStatementBucket"];

    private selectedImageSub = this.imageSelector.selectedImage.subscribe(
        val => {
            if (val && val.Hash && val.Hash !== this.getCurrentFileHash()) {
                this.setCurrentFileByHash(val.Hash);
            }
        });

    public copilotPredefinedQuestions = observableArray<IPredefinedQuestion>([]);

    // Copilot section
    private isImageSupportedByCopilotImageTooltip = observable<boolean>(false);

    public invoiceImageTooltip = pureComputed(() => ({
        functionComponent: InvoiceImageTooltip,
        props: {
            predefinedQuestions: this.copilotPredefinedQuestions().filter(predefinedQuestion => {
                if(
                    predefinedQuestion.value === summarizeTheInvoiceContext ||
                    predefinedQuestion.value === translateTheInvoiceContextToMyLanguage
                ) return true;
                else return false;
            })
        }
    }));

    constructor(documentId?: number, documentType?: string, hashFiles: any[] = [], companyId?: number) {
        this.hashFiles = hashFiles.filter(hashObj => this.supportedImageTypes.includes(hashObj.Type()));

        if (documentType) {
            this.documentType = documentType.split(",", 1)[0].trim();
        } else {
            this.documentType = null;
        }

        this.documentId = documentId;
        if (this.hashFiles && this.hashFiles.length > 0) {
            this.imageSelector.loadImages(documentId, documentType, this.hashFiles);
            this.setCurrentFileByIndex(0);
            this.createGlobals(companyId);
        }

        this.pdfViewerUrl = pureComputed(() => {
            if (this.currentFile()) {
                return this.currentFile().pdfViewerUrl();
            }
            return "about:blank";
        });

        // Copilot section
        this.isImageSupportedByCopilotImageTooltip(
            shouldCopilotDisplayImageBasedQuestions(this.hashFiles)
        );

        copilotServices.getPredefinedQuestions().then(questions => {
            this.copilotPredefinedQuestions(questions);
        });
    }

    public getCurrentFileHash() {
        if (this.currentFile()) {
            return this.currentFile().hashFile().Hash();
        }
        return null;
    }

    public getDocumentId() {
        return this.documentId;
    }

    public setCurrentFileByHash(hash: string) {
        const foundHashObj = this.hashFiles.find(hashObj => hashObj.Hash() === hash);
        if (foundHashObj) {
            this.setCurrentFile(foundHashObj);
        }
    }

    public setCurrentFileByIndex(index: number) {
        this.setCurrentFile(this.hashFiles[index]);
    }

    public removeFileByIndex(index: number) {
        this.hashFiles.splice(index, 1);

        if (this.hashFiles.length > 0) {
            this.setCurrentFileByIndex(0);
        } else {
            this.currentFile(null);
        }
    }

    public addFile(hashObj: any) {
        this.hashFiles.push(hashObj);
    }

    private setCurrentFile(hashObj: any) {
        this.currentFile(new PdfFile(this.documentId, this.documentType, hashObj));
        this.imageSelector.selectImage(hashObj.Hash());
    }

    private createGlobals(companyId?: number) {
        if (getCurrent()) {
            const langCode = getLanguageCode();
            this.pdfJsLangCode = langMapper.mapXiToPdfjs(langCode);
        }

        (window as any).PdfViewerGlobals = {
            getCurrentUserLanguageCode: () => this.pdfJsLangCode,
            logImageDownload: () => { uxLog.logImageDownload(this.documentType); },
            logImagePrint: () => { uxLog.logImagePrint(this.documentType); },
            sendEmail: () => {
                const emailModel = createSendEmail(this.currentFile(), companyId, this.documentType);
                this.sendEmailForm(emailModel);
                emailModel.showDialog();
            },
        };
    }

    public dispose() {
        this.selectedImageSub.dispose();
        this.pdfViewerUrl.dispose();
    }
}
