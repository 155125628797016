///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/BulkAdminIdentityProvidersComponent"/>

import { RadioButton, Text } from "@medius/ui-controls";
import * as React from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface IProps {
  onEnableIdentityProviderChanged: (isActive: boolean) => void;
  onDisableIdentityProviderChanged: (isActive: boolean) => void;
}

export function BulkAdminIdentityProvidersComponent({
  onEnableIdentityProviderChanged,
  onDisableIdentityProviderChanged,
}: IProps) {
  const [enableIdentityProvider, setEnableIdentityProvider] =
    React.useState(false);
  const [disableIdentityProvider, setDisableIdentityProvider] =
    React.useState(false);

  const handleEnableIdentityProviderChange = () => {
    const currentValue = !enableIdentityProvider;
    onEnableIdentityProviderChanged(currentValue);
    setEnableIdentityProvider(currentValue);

    if (currentValue) {
      onDisableIdentityProviderChanged(!currentValue);
      setDisableIdentityProvider(!currentValue);
    }
  };

  const handleDisableIdentityProviderChange = () => {
    const currentValue = !disableIdentityProvider;
    onDisableIdentityProviderChanged(currentValue);
    setDisableIdentityProvider(currentValue);

    if (currentValue) {
      onEnableIdentityProviderChanged(!currentValue);
      setEnableIdentityProvider(!currentValue);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row-fluid">
        <RadioButton
          data-testid="enable-local-login-radio"
          onFocus={handleEnableIdentityProviderChange}
          checked={enableIdentityProvider}
          value="value"
          label={translate("#Core/enableLocalLogin")}
        />
      </div>

      <div className="row-fluid span4">
        <Text variant="small">
          {translate("#Core/enableLocalLoginDescription")}
        </Text>
      </div>

      <div className="row-fluid">
        <RadioButton
          data-testid="disable-local-login-radio"
          checked={disableIdentityProvider}
          value="value"
          onFocus={handleDisableIdentityProviderChange}
          label={translate("#Core/disableLocalLogin")}
        />
      </div>
    </div>
  );
}
