/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/documentOrder"/>
const documentOrder: {[key: string]: number} = {
    
    "Medius.ExpenseInvoice.Entities.PaymentRequest":                                        1,
    "Medius.Core.Platform.Inbox.Draft.DraftInboxEntity":                                    2,
    "Medius.PurchaseToPay.Platform.CCF.Inbox.CaptureDocumentInboxEntity":                   3,
    "Medius.PurchaseToPay.Platform.EdiTaskHandling.Inbox.EdiDocumentImportInboxEntity":     4,
    "Medius.ExpenseInvoice.Entities.ExpenseInvoice":                                        5,
    "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice":                                  6,
    "Medius.Apa.Persistence.SupplierStatement.Entities.SupplierStatement":                  7,
    "Medius.Apa.Persistence.PrepaymentInvoice.PrepaymentSupplierInvoice":                   8,
    "Medius.ContractbasedInvoice.Entities.ContractbasedInvoice":                            9,
    "Medius.Procurement.Entities.PurchaseRequisition":                                     10,
    "Medius.Procurement.Entities.PurchaseOrderDocument":                                   11,
    "Medius.PurchaseToPay.Entities.AdjustmentRequest.AdjustmentRequest":                   12,
    "Medius.PurchaseToPay.DocumentApproval.Entities.DocumentApproval":                     13,
    "Medius.PurchaseToPay.Entities.ShortPayment.CreditMemo":                               14,
    "Medius.Procurement.Platform.Inbox.PurchaseRequisitionInboxEntity":                    15,
    "Medius.Contract.Entities.SupplierContractDocument":                                   16,
    "Medius.PurchaseToPay.Entities.SupplierInvoice":                                       17,
    "Medius.Core.QA.Entities.SeleniumData.CompanyRelatedQaDocument":                       18,
    "Medius.Core.QA.Entities.BuisnessRepositoryData.CompanyRelatedDocument":               19,
    "Medius.Core.QA.Entities.DocumentWithFetchOnDemand.FetchOnDemandQaDocument":           20,
    "Medius.Core.QA.Entities.SeleniumData.QaDocumentWithAmount":                           21,
    "Medius.Core.QA.Entities.SeleniumData.ReclassifiableDocumentA":                        22,
    "Medius.Core.QA.Entities.SeleniumData.ReclassifiableDocumentB":                        23,
    "Medius.Core.Entities.QA.EntityMappingTests.SomeDocument":                             24
};

export function getDocumentOrderForType(type: string) {
    // for non standard document types (e.g. from Selenium tests) value which will place document at the end
    return documentOrder[type] ? documentOrder[type] : 25;
}

export function getAllDocumentTypes() {
    return Object.keys(documentOrder);
}
