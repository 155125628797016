/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/customTabs"/>

import { shouldRenderContractsTab } from "Core/Medius.Core.Web/Scripts/customTabs/rules/shouldRenderContractsTabRule";
import { translate } from "../lib/globalization";
import { ITaskDocument } from "./interfaces";
import { prepaymentType } from "Core/Medius.Core.Web/Scripts/shared/prepaymentType";

const customTabsList = [
    {
        "$type": "Medius.Core.Entities.NewInbox.TaskTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": "Medius.PurchaseToPay.Entities.SupplierInvoice",
        "TabName": translate("#PurchaseToPay/contractsTabName"),
        "ViewName": "CustomTabs/ContractsTab",
        "Position": 100,
        "IsActive": true,
        "IsDefault": false,
        "Id": -1,
    },
    {
        "$type": "Medius.Core.Entities.NewInbox.TaskTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": "Medius.Core.Entities.Integration.EDIDocumentImport",
        "TabName": translate("#Core/comments"),
        "ViewName": "CustomTabs/CommentsTab",
        "Position": 101,
        "IsActive": true,
        "IsDefault": false,
        "Id": -2,
    },
    {
        "$type": "Medius.Core.Entities.NewInbox.TaskTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": "Medius.Core.Entities.Integration.EDIDocumentImport",
        "TabName": translate("#Core/fileAttachments"),
        "ViewName": "CustomTabs/Attachments",
        "Position": 102,
        "IsActive": true,
        "IsDefault": false,
        "Id": -3,
    },
    {
        "$type": "Medius.Core.Entities.NewInbox.TaskTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": "Medius.Core.Entities.Integration.EDIDocumentImport",
        "TabName": translate("#Core/history"),
        "ViewName": "CustomTabs/WorkflowHistory",
        "Position": 103,
        "IsActive": true,
        "IsDefault": false,
        "Id": -4,
    },
    {
        "$type": "Medius.Core.Entities.NewInbox.TaskTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": "Medius.Core.Entities.Integration.EDIDocumentImport",
        "TabName": translate("#Core/links"),
        "ViewName": "CustomTabs/Links",
        "Position": 104,
        "IsActive": true,
        "IsDefault": false,
        "Id": -5,
    }
];

const customDraftTabs = [
    {
        "$type": "Medius.Core.Entities.DocumentRegistration.DocumentTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": prepaymentType,
        "TabName": translate("#Core/hashFiles"),
        "ViewName": "CustomTabs/HashFiles",
        "Position": 2,
        "IsActive": true,
        "IsDefault": true,
        "LeftSide": true,
        "Id": -5,
    },
    {
        "$type": "Medius.Core.Entities.DocumentRegistration.DocumentTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": prepaymentType,
        "TabName": translate('#Core/edit'),
        "ViewName": "CustomTabs/Create",
        "Position": 99,
        "IsActive": true,
        "IsDefault": true,
        "LeftSide": false,
        "Id": -6,
    },
    {
        "$type": "Medius.Core.Entities.DocumentRegistration.DocumentTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": prepaymentType,
        "TabName": translate("#Core/comments"),
        "ViewName": "CustomTabs/Comments",
        "Position": 100,
        "IsActive": true,
        "IsDefault": false,
        "LeftSide": false,
        "Id": -7,
    },
    {
        "$type": "Medius.Core.Entities.DocumentRegistration.DocumentTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": prepaymentType,
        "TabName": translate("#Core/fileAttachments"),
        "ViewName": "CustomTabs/Attachments",
        "Position": 101,
        "IsActive": true,
        "IsDefault": false,
        "LeftSide": false,
        "Id": -8,
    },
    {
        "$type": "Medius.Core.Entities.DocumentRegistration.DocumentTab, Medius.Core.Common",
        "IsSeparateInFullscreen": false,
        "ForType": prepaymentType,
        "TabName": translate("#Core/links"),
        "ViewName": "CustomTabs/Links",
        "Position": 102,
        "IsActive": true,
        "IsDefault": false,
        "LeftSide": false,
        "Id": -9,
    }
];

const isOfTypePrepayment = (document: ITaskDocument) => document.WrappedDocumentType === prepaymentType;
const displayPrepaymentType = "Display/" + prepaymentType;

const customTabsMap = new Map([
    ["Display/Medius.PurchaseToPay.Entities.SupplierInvoice/CustomTabs/ContractsTab", { template: "Contract/components/contracts.html", isVisible: (document: ITaskDocument) => shouldRenderContractsTab(document) }],
    ["Display/Medius.Contract.Entities.Configuration.ContractCompanyConfiguration/MergedContract", { template: "Contract:AdminPages/Company/Contract/contract.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.Configuration.ContractSupplierConfiguration/MergedContract", { template: "Contract:AdminPages/Supplier/Contract/contract.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Data.Document/DocumentImageTab", {template: "Core:Views/Display/Medius.Data.Document/DocumentImageTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ExpenseInvoice.Entities.ExpenseInvoice/DocumentImageTab", {template: "Core:Views/Display/Medius.Data.Document/DocumentImageTab.html", isVisible: (document: ITaskDocument) => true}],
    ["Display/Medius.OrderbasedInvoice.Entities.OrderbasedInvoice/DocumentImageTab", {template: "Core:Views/Display/Medius.Data.Document/DocumentImageTab.html", isVisible: (document: ITaskDocument) => true}],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/DocumentImageTab", {template: "Core:Views/Display/Medius.Data.Document/DocumentImageTab.html", isVisible: (document: ITaskDocument) => true}],
    ["Display/Medius.Core.Entities.Integration.EDIDocumentImport/DocumentImageTab", {template: "Core:Views/Display/Medius.Data.Document/DocumentImageTab.html", isVisible: (document: ITaskDocument) => true}],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/Create", {template: "Contract:components/taskHandling/createStep/template.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/ContractImageTab", {template: "Contract:Views/Display/Medius.Contract.Entities.SupplierContractDocument/ContractImageTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTab", {template: "Contract:Views/Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTabDocumentImport", {template: "Contract:Views/Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTabDocumentImport.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTabDraft", {template: "Contract:Views/Display/Medius.Contract.Entities.SupplierContractDocument/EditInvoicingPlanTabDraft.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Contract.Entities.SupplierContractDocument/ViewInvoicingPlanTab", {template: "Contract:Views/Display/Medius.Contract.Entities.SupplierContractDocument/ViewInvoicingPlanTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/DeviationAuthorizeTab", {template: "ContractbasedInvoice:templates/Deviations.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/InvoiceDetailsTab", {template: "ContractbasedInvoice:templates/Details.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/Create", {template: "ContractbasedInvoice:templates/Create.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/SplitHeaderTaxTab", {template: "ContractbasedInvoice:templates/SplitHeaderTax.html", isVisible: (document: ITaskDocument) => true }],
    [displayPrepaymentType + "/Create", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/createStep/template.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Core.Entities.Integration.EDIDocumentImport/CustomTabs/CommentsTab", { template: "Core:Medius/core/comments/comments.html", isVisible: (document: ITaskDocument) => isOfTypePrepayment(document) }],
    ["Display/Medius.Core.Entities.Integration.EDIDocumentImport/CustomTabs/Attachments", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/createStep/attachments.html", isVisible: (document: ITaskDocument) => isOfTypePrepayment(document) }],
    ["Display/Medius.Core.Entities.Integration.EDIDocumentImport/CustomTabs/WorkflowHistory", { template: "Core:Medius/core/history/historyWithBinding.html", isVisible: (document: ITaskDocument) => isOfTypePrepayment(document) }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/ManualConnectionTab", { template: "Core:templates/ManualConnectionTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.ContractbasedInvoice.Entities.ContractbasedInvoice/SplitTaxTab", { template: "Core:templates/Invoice/SplitTax/SplitTaxTab.html", isVisible: (document: ITaskDocument) => true }],
    ["Display/Medius.Core.Entities.Integration.EDIDocumentImport/CustomTabs/Links", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/shared/tabs/linksTab.html", isVisible: (document: ITaskDocument) => isOfTypePrepayment(document) }],    
    ["Display/Medius.Core.Entities.User/BulkAdminIdentityProviders", { template: "Core:templates/Administration/Bulk/BulkAdminIdentityProviders.html", isVisible: (document: ITaskDocument) => true }]    
]);



const customDraftTabsMap = new Map([
    [displayPrepaymentType + "/CustomTabs/HashFiles", {template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/shared/tabs/hashFiles.html", isVisible: (documentViewModel: any) => true }],
    [displayPrepaymentType + "/CustomTabs/Comments", { template: "Core:Medius/core/comments/comments.html", isVisible: (documentViewModel: any) => true }],
    [displayPrepaymentType + "/CustomTabs/Attachments", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/createStep/attachments.html", isVisible: (documentViewModel: any) => true }],
    [displayPrepaymentType + "/CustomTabs/Links", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/shared/tabs/linksTab.html", isVisible: (documentViewModel: any) => true }],
    [displayPrepaymentType + "/CustomTabs/Create", { template: "Core:components/PrepaymentInvoice/taskHandling/prepayment/createStep/template.html", isVisible: (documentViewModel: any) => true }],
]);

export const getCustomTabs = (): any => {
    return customTabsList;
};

export const getCustomDraftTabs = (): any => {
    return customDraftTabs;
};

export const isDraftTabVisible = (viewPath: string, documentViewModel: any) => {
    return customDraftTabsMap.get(viewPath)?.isVisible(documentViewModel);
};

export const isTabVisible = (viewPath: string, document: ITaskDocument) => {
    return customTabsMap.get(viewPath)?.isVisible(document);
};

export const getCustomTemplate = (viewPath: string) => {
    return customTabsMap.get(viewPath)?.template || customDraftTabsMap.get(viewPath)?.template;
};