/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/configuration"/>

export interface CurrentCompany {
    company: Company,
    configuration: CompanyConfiguration,
    procurementConfiguration: ProcurementConfiguration
}

export interface Company {
    id: number,
    name: string
}

export interface ActiveDimension {
    dimensionName: string,
    title: string,
    id: number,
    maxLength?: number
}

export interface TaxIndicator {
    indicatorNumber: number,
    label: string
}

export interface CompanyConfiguration {
    activeDimensions: ActiveDimension[],
    taxIndicators: TaxIndicator[],
    restrictUsageToLeafLevelCategoriesOnly: boolean
}

export interface ProcurementConfiguration {
    availableRequisitionTypes: RequisitionTypes[];
    defaultRequisitionType: RequisitionTypes;
    categoryPreCodingActive: boolean;
    isAccessToPunchOutCatalogsAllowed: boolean;
    isCreatingFreeTextItemsAllowed: boolean;
    isOnlyOneSupplierAllowed: boolean;
    totalAmountAsDefault: boolean;
    locationsSelectedUserType?: LocationUserType;
    isUserDefaultAddressHasPriority?: boolean;
    defaultFreeTextForm: number;
    storeAmountInUnitPriceForServiceLines: boolean;
    allowForSplitCoding: boolean;
}

export interface RequisitionTypes {
    id: number;
    internalPoPdfAccess: boolean;
    isActive: boolean;
    name: string;
}

export enum LocationUserType {
    LoggedIn = 0,
    OurReference = 1
}