///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces"/>

export enum Role {
    assistant = "assistant",
    user = "user",
    system = "system"
}

export interface IMessage{
    content: string;
    role: Role;
    supplierWidgetDto?: ISupplierWidgetDto | null;
    sources: ISourceUrl[];
    operationalInvoiceContextDto?: IOperationalInvoiceContextDto | null;
    nonOperationalInvoiceContextDto?: IInvoiceContextDto | null;
    conversationId: string;
    documentId: number;
    date: string;
    isError?: boolean;
    taskId: number;
    responseToPredefinedQuestionId?: number;
}

export interface IAgentIconComponentProps{
    documentId: number;
    taskId: number;
    taskName: string;
    isOperational: boolean;
    workflowStepName : string;
    invoiceType: string;
    getDelegationMessage: () => string;
    getEscalationMessage: () => string;
    predefinedQuestions: IPredefinedQuestion[];
    isDocumentImport: boolean
}

export interface ISourceUrl {
    url: string;
    label: string;
}

export interface IAgentFeedbackComponentProps{
    documentId: number;
    conversationId: string;
    workflowStepName : string;
    invoiceType: string;
}

export interface ISupplierWidgetDto {
    name: string;
    responsibleName: string | null;
    address: string | null;
    contact: IContact;
    nonPoInvoicesProcessedLastMonthCount: number;
    poInvoicesProcessedLastMonthCount: number;
    nonPoInvoicesProcessedThisMonthCount: number;
    poInvoicesProcessedThisMonthCount: number;
    nonPoInvoicesProcessedIn12MonthsCountAverage: number;
    poInvoicesProcessedIn12MonthsCountAverage: number;
    paymentTerm: string;
    dueDate: string;
    cashDiscount1: CashDiscount;
    cashDiscount2: CashDiscount;
}

export interface IInvoiceContextDto{
    documentId: number;
    latestComments: ICommentDto[];
    previousInvoices: IPreviousInvoiceDto[];
    allPreviousInvoicesCount: number;
    fullCodingLinesAuthorizationHistory?: DescribedValue<IUser[][]>;
    fullDeviationsAuthorizationHistory?: DescribedValue<IUser[][]>;
    fullDocumentAmountAuthorizationHistory?: DescribedValue<IUser[][]>;
}

export interface IOperationalInvoiceContextDto extends IInvoiceContextDto {
    documentTypeDescription: string | null;
    invoiceNumber: string | null;
    totalAmount: IMoney;
    invoiceDate: string;
    paymentTerm: string;
    dueDate: string;
    cashDiscount1: CashDiscount;
    cashDiscount2: CashDiscount;
    currentStep: string | null;
    supplierName: string;
    currentUserAuthorizationHistory: DescribedValue<IUser[][]>;
    predictedNextApprovers: string[];
    amountToApprove: IMoney;
    latestComments: ICommentDto[];
    previousInvoices: IPreviousInvoiceDto[];
    allPreviousInvoicesCount: number;
    invoiceCodingLines: IInvoiceCodingLineDto[];
    invoiceDeviations: IInvoiceDeviationDto[];
    isInvoiceAssignedToUser: boolean;
}

export interface IInvoiceDeviationDto {
    amount: IMoney;
    type: string | null;
    approvalStatus: InvoiceLineApprovalStatus;
    additionalChange: DescribedValue<string>;
}

export interface IInvoiceCodingLineDto {
    amount: IMoney;
    codingDimensions: ICodingDimensions;
    approvalStatus: InvoiceLineApprovalStatus;
}

export interface ICodingDimensions {
    Dimension1 : IDimension;
    Dimension2 : IDimension;
    Dimension3 : IDimension;
    Dimension4 : IDimension;
    Dimension5 : IDimension;
    Dimension6 : IDimension;
    Dimension7 : IDimension;
    Dimension8 : IDimension;
    Dimension9 : IDimension;
    Dimension10 : IDimension;
    Dimension11 : IDimension;
    Dimension12 : IDimension;
    FreeTextDimension1 : IFreeTextDimension;
    FreeTextDimension2 : IFreeTextDimension;
    FreeTextDimension3 : IFreeTextDimension;
    FreeTextDimension4 : IFreeTextDimension;
    FreeTextDimension5 : IFreeTextDimension;
}

export interface IDimension {
    value: string | null;
    description: string | null;
}

export interface IFreeTextDimension {
    value: string | null;
}

export interface IContact {
    telephone: string | null;
    fax: string | null;
    homepage: string | null;
    email: string | null;
}

export interface IUser {
    userName: string;
    firstName: string;
    lastName: string;
}

export interface IMoney {
    value: number;
    currency: string;
}

export interface IPreviousInvoiceDto {
    id: number;
    invoiceNumber: string;
    invoiceDate: string;
    totalAmount: IMoney;
    approvalStatus: InvoiceApprovalStatus;
    hasUserAccess: boolean;
    authorizationHistoryUsersPerStep: DescribedValue<IUser[][]>;
}

export interface ICommentDto {
    value: string;
    createdDate: string;
    createdBy: IUser;
}

export interface IEntityLogDto{
    message: string;
    messagePropertiesJson: string;
    topic: string;
    topicPropertiesJson: string;
    date: string
}

export interface CashDiscount {
    date: string | null;
    discountPercent: number | null;
}

export enum InvoiceLineApprovalStatus {
    Approved = "approved",
    Rejected = "rejected",
    NoActionTaken = "noActionTaken"
}

export enum InvoiceApprovalStatus {
    Approved = "approved",
    Rejected = "rejected",
    PartiallyApproved = "partiallyApproved",
    Invalidated = "invalidated"
}

export interface IPredefinedQuestion {
    categorizationId: number | null,
    value: string
}

export type StreamCallScenario = "freetext" | "predefined";

export interface CopilotEventPayload{
    questionId: number;
    prompt?: string;
}

export type HideDuration = "day" | "week" | "month" | "forever";

export interface CopilotEvent extends MessageEvent{
    data: {
        type: string,
        payload: CopilotEventPayload
    }
} 

export interface ITaskDocument {
    isLongTermArchived: boolean;
    $type: () => string;
    DocumentType?: () => string;
}

export interface IHashFile {
    Type: () => string;
}

export interface ILastChatRequestDetails{
    scenario: StreamCallScenario;
    conversationId: string;
    prompt: string;
}

export interface ICommentToSummarize {
    value: string;
    createdDate: Date;
    createdBy: string;
}

export interface DescribedValue<T> {
    description: string;
    value: T;
}