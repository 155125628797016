/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid"/>
import * as _ from "underscore";
import { isNullOrUndefined, isEmptyString, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as $ from "jquery";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as performanceLogFactory from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/base";
import { savePageSize, saveColumnVisible, loadPageSize, loadColumnsVisible } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/userConfigRepository";
import { ColumnPickerTemplate } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/ColumnPickerTemplate";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import { generate } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/pagination";
import { Multiselection } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/multiselection";
import * as parser from "Core/Medius.Core.Web/Scripts/Medius/components/grid/parser/default";
import { DataQuery } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataQuery/default";
import * as descriptionGenerator from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/description";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/defaults";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

const checkboxColumn = { Width: 30, Stretch: false, Resizable: false, Sortable: false, Searchable: false };

function getResultData(result: any) {
    return (_.isArray(result) &&
        result.length === 3 &&
        !isNullOrUndefined(result[2].status)) ? result[0] : result;
}

function countProperties(obj: any) {
    return _(obj).keys().length;
}

export class Grid {
    public reviewTooltip: string = getLabelTranslation("#Core/reviewTooltip");
    public onHoldTooltip: string = getLabelTranslation("#Core/onHoldTooltip");

    public _totalRows = ko.observable(null);
    public isDisposed = false;
    public options: any;
    public isPreloaded = false;
    public IsActive = ko.observable(false);
    public SelectAllShortcutName = "GridSelectAll";
    public RowUpShortcutName = "GridRowUp";
    public RowDownShortcutName = "GridRowDown";
    public IsDataFiltered = ko.observable(false);
    public Parser: any;
    public DataQuery: any;
    public DataSource: any;
    public IsLoading = ko.observable(false);
    public Columns = ko.observableArray([]);
    public VisibleColumns = ko.observableArray([]);
    public isColumnSelectorVisible = ko.observable(false);
    public Rows = ko.observableArray([]);
    public sortingOrder: string;
    public sortingColumn: any;
    public TotalRows: ko.Computed<number>;
    public NonfilteredTotalRows: any = ko.observable(null);
    public SelectedRow = ko.observable(null);
    public CurrentPage: ko.Observable<any>;
    public Pages = ko.observableArray([]);
    public LastPage: ko.Computed<number>;
    public ChosenPageSize: any;
    public AvailablePageSizes: any;
    public PageSizeStorageId: any;
    public MultiselectEnabled: any;
    public TotalLoadedRows: ko.Computed<number>;
    public multiselection: Multiselection;
    public SelectedIds: any;
    public TotalSelectedRows: ko.Computed<number>;
    public SelectAll: any;
    public toggleAll: any;
    public AllColumns: any;
    public AllVisibleColumns: any;
    public getRowSelectedObservable: any;
    public markRow: any;
    public toggleRow: any;
    public DataDescription: any;
    public IsSearchOn: ko.Computed<boolean>;
    public RowsClickable: any;
    public IsSortingOn: ko.Computed<boolean>;
    public IsPaginationOn: ko.Computed<boolean>;
    public IsEmpty: ko.Computed<boolean>;
    public IsDraggableRowsOn: ko.Computed<boolean>;
    public clickRow: (clickedRow: any, event: any) => boolean;
    public preparePropertiesForExport: () => Array<any>;
    public clickExport: () => any;
    public ChosenPageSizeSub: ko.Subscription;
    public tempTotalDataQueryKeywords: any;
    public dispose: () => any;
    public CompanyContextId: any;
    public SelectedCompany: any;
    public SelectedCreatedDateFilter: any;
    public SelectedChangeDateFilter: any;

    public ColumnPicker = ko.pureComputed(() => {
        const columns = this.Columns()
            .map((column: { Name: string, Selectable: boolean, Visible: ko.Observable<boolean> }) => ({
                name: column.Name,
                selectable: column.Selectable,
                visible: column.Visible()
            }));

        return ({
            functionComponent: ColumnPickerTemplate,
            props: {
                toggleColumnSelector: (visibility: boolean) => this.toggleColumnSelector(visibility),
                columns: columns,
                toggleAllColumns: (switchState: boolean) => {
                    this.Columns().forEach((column: any) => {
                        if (column.Visible() !== switchState && column.Name !== "") {
                            this.toggleColumn(column);
                        }
                    });
                },
                toggleColumn: (columnName: string) => {
                    const columnToBeToggled = this.Columns().find((column: any) => column.Name === columnName);
                    this.toggleColumn(columnToBeToggled);
                },
                allColumnsVisible: this.allColumnsVisible()
            }
        });
    });

    public allColumnsVisible = ko.computed(() => {
        const foundColumn = ko.utils.arrayFirst(this.Columns(), (column: any) => {
            return column.Visible() == false;
        });
        return isNullOrUndefined(foundColumn);
    });

    public constructor(dataSource: any, options: any, alwaysRefreshPageOnPageSizeChanged: boolean = true) {
        if (isNullOrUndefined(dataSource)) {
            throw new Error("Can't initialize Grid component without providing data source.");
        }
        this.options = defaults.get();
        helpers.mergeDefaults(options, this.options);

        this.Parser = this.options.parser || parser.create();
        if (this.options.dataQuery) {
            this.DataQuery = this.options.dataQuery;
            this.DataQuery.pageSize = this.options.pageSize;
        } else {
            this.DataQuery = new DataQuery(
                this.options.pageSize,
                this.options.keywords,
                this.options.sorting,
                this.options.limit);
        }

        this.DataSource = dataSource;
        this.sortingOrder = this.options.sortingOrder || "desc";
        this.sortingColumn = this.options.sortingColumn;

        this.TotalRows = ko.computed({
            read: () => {
                return this._totalRows();
            },
            write: (value: any) => {
                const parsed = parseInt(value, 10);

                if (isNaN(parsed)) {
                    this._totalRows(null);
                    logger.error("Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid: TotalRows is not a number (" + value + ")");
                } else {
                    this._totalRows(parsed);
                }
            }
        });

        this.CurrentPage = ko.observable(this.DataQuery.page);
        this.LastPage = ko.computed(() => {
            return parseInt(_.last(this.Pages()), 10);
        });

        //This cast to any was introduced in knockout upgrade to version 3.5.1. Not sure why, but it's needed for it to work
        this.ChosenPageSize = (ko.observable(this.DataQuery.pageSize) as any).extend({ integer: null });
        this.AvailablePageSizes = this.options.availablePageSizes;
        this.PageSizeStorageId = this.options.pageSizeStorageId;
        this.MultiselectEnabled = koUtils.unpack(this.options.multiselect);
        this.TotalLoadedRows = ko.computed(() => {
            return this.Rows().length;
        });

        if (this.MultiselectEnabled) {
            this.multiselection = new Multiselection(this, this.options.multiselectInitialPredicate);

            this.SelectedIds = this.multiselection.selectedIds;
            this.TotalSelectedRows = ko.computed(() => {
                return this.multiselection.selectedIds().length;
            });
            this.SelectAll = this.multiselection.SelectAll;
            this.toggleAll = this.multiselection.toggleAll;
            this.AllColumns = ko.computed(() => {
                const columns = [checkboxColumn];
                return columns.concat(this.Columns());
            });
            this.AllVisibleColumns = ko.computed(() => {
                const columns = [checkboxColumn];
                return columns.concat(this.VisibleColumns());
            });

            this.getRowSelectedObservable = this.multiselection.getRowSelectedObservable.bind(this.multiselection);
            this.markRow = this.multiselection.markRow.bind(this.multiselection);
            this.toggleRow = this.multiselection.toggleRow.bind(this.multiselection);
        } else {
            this.AllColumns = this.Columns;
            this.AllVisibleColumns = this.VisibleColumns;
            this.SelectAll = () => {
            };
            this.toggleRow = () => {
            };
        }

        this.DataDescription = ko.computed(() => {
            return descriptionGenerator.generate({
                totalNonfilteredRows: this.NonfilteredTotalRows(),
                totalRows: this.TotalRows(),
                totalVisibleRows: this.TotalLoadedRows(),
                totalSelectedRows: this.TotalSelectedRows ? this.TotalSelectedRows() : 0,
                pageSize: this.ChosenPageSize(),
                page: this.CurrentPage(),
                isFiltered: this.IsDataFiltered(),
                isMultiselect: this.MultiselectEnabled,
                singularForm: this.options.descriptionSingularForm,
                pluralForm: this.options.descriptionPluralForm,
                limit: this.options.limit
            });
        });

        this.IsSearchOn = ko.computed(() => {
            if (this.options.search) {
                const column = _(this.Columns()).find((item: any) => {
                    return !!item.Searchable;
                });
                return !!column;
            }

            return false;
        });

        this.RowsClickable = this.MultiselectEnabled || _.isFunction(this.options.onClickRow);

        this.IsSortingOn = ko.computed(() => {
            if (this.options.sort) {
                const column = _(this.Columns()).find((item: any) => {
                    return item.Sortable === true;
                });
                return (isNullOrUndefined(column)) ? false : true;
            }

            return false;
        });

        this.IsPaginationOn = ko.computed(() => {
            return (this.options.paging) ? true : false;
        });

        this.IsEmpty = ko.computed(() => {
            return (this.Rows().length === 0);
        });

        this.IsDraggableRowsOn = ko.computed(() => {
            return !!(this.options.draggable);
        });

        this.clickRow = (clickedRow: any, event: any) => {
            if (!this.RowsClickable || event && $(event.target).is('.multiselect *')) {
                return true;
            }

            this.SelectedRow(clickedRow);

            if (_.isFunction(this.options.onClickRow)) {
                this.options.onClickRow(clickedRow, event, self);
            }

            return true;
        };

        this.preparePropertiesForExport = () => {
            const properties: Array<any> = [];

            _.each(this.VisibleColumns(), (column) => {
                if (!isEmptyString(column.ValueSource)) {
                    properties.push(column.ValueSource);
                }
            });

            return properties;
        };

        this.clickExport = () => {
            let properties;

            if (this.options.exportData) {
                properties = this.preparePropertiesForExport();
                this.IsLoading(true);
                $.when(this.DataSource.exportData(this.DataQuery, properties)).always(() => {
                    this.IsLoading(false);
                });
            }
        };

        this.ChosenPageSizeSub = this.ChosenPageSize.subscribe((value: any) => {
            //self.options.dataQuery should be taken from options, not set separately
            if (isNotNullOrUndefined(this.DataQuery)) {
                this.DataQuery.pageSize = value;
                savePageSize(this.options.pageSizeStore, this, value);
            }
            
            if (this.isPreloaded || alwaysRefreshPageOnPageSizeChanged) {
                this.openPage(1);
            }
        });

        this.tempTotalDataQueryKeywords = null;
    }

    public toggleColumnSelector(visibility: boolean) {
        const beginState = this.isColumnSelectorVisible();

        this.isColumnSelectorVisible(visibility);

        if (beginState === false) {
            this.tempTotalDataQueryKeywords = countProperties(this.DataQuery.keywords);
            return;
        }

        if (this.tempTotalDataQueryKeywords !== countProperties(this.DataQuery.keywords)) {
            this.refresh();
        }

        this.tempTotalDataQueryKeywords = null;

        this.updateVisibleColumns();
        const parsedRows = this.Parser.parseRows(this.VisibleColumns.peek(), this.Rows.peek());
        this.Rows.removeAll(); // required to refresh columns since row.Columns is not observable any more
        this.Rows(parsedRows);
    }

    public updateVisibleColumns() {
        this.VisibleColumns(this.Columns.peek()
            .filter((column: any) => {
                return column.Visible();
            }));
    }

    public clickExportPdf() {
        let errorDetail,
            errorTitle;

        if (!this.options.downloadImagesToPdf) {
            return;
        }

        if (this.SelectedIds().length === 0) {
            errorDetail = globalization.getLabelTranslation("#Core/selectDocumentForExportToPdf");
            errorTitle = globalization.getLabelTranslation("#Core/emptyMultiselectionErrorTitle");

            notification.error(errorDetail, errorTitle);
            return;
        }

        this.DataSource.downloadImagesToPdf(this.DataQuery, this.SelectedIds());
    }

    public addColumn(column: any) {
        if (isNullOrUndefined(column)) {
            return false;
        }

        column = this.Parser.parseColumn(column, this.options.defaultColumnWidth);
        this.Columns.push(column);
        this.refresh();

        return true;
    }

    public removeColumn(column: any) {
        if (isNullOrUndefined(column)) {
            return false;
        }

        this.Columns.remove(column);
        this.refresh();

        return true;
    }

    public isTheOnlyVisibleColumn(column: any) {
        return column.Visible() && this.VisibleColumns().length === 1;
    }

    public toggleColumn(column: any) {
        if (!column || this.isTheOnlyVisibleColumn(column)) {
            return;
        }

        const newState = !column.Visible();
        saveColumnVisible(this.options.columnVisibleStore, column, newState);

        if (newState === false) {
            if (this.DataQuery.keywords[column.ValueSource]) {
                delete this.DataQuery.keywords[column.ValueSource];
            }
            column.searchValue(null);
        }

        column.Visible(newState);
        this.updateVisibleColumns();
    }

    public setColumns(columns: any) {
        const preparedColumns: Array<any> = [];

        if (!_.isArray(columns)) {
            return false;
        }

        _.each(columns, (column) => {
            column = this.Parser.parseColumn(column, this.options.defaultColumnWidth, this.options.tplCell);
            preparedColumns.push(column);
        });

        this.Columns(preparedColumns);
        this.updateVisibleColumns();
        this.refresh();

        return true;
    }

    public addRow(row: any) {
        if (isNullOrUndefined(row)) {
            return false;
        }

        row = this.Parser.parseRow(this.Columns(), row);
        this.Rows.unshift(row);
        this.SelectedRow(row);
        this.TotalRows(this.TotalRows() + 1);

        return true;
    }

    public removeRow(row: any): any {
        if (isNullOrUndefined(row)) {
            return false;
        }

        if (_.isFunction(this.options.onRemoveRow)) {
            this.options.onRemoveRow(row);
        }

        if (row.dispose) {
            row.dispose();
        }
        this.Rows.remove(row);
        this.TotalRows(this.TotalRows() - 1);

        this.refresh();

        return true;
    }

    public getRowById(rowId: any) {
        return _(this.Rows()).find((row: any) => {
            return koUtils.unpack(row.Id) === rowId;
        });
    }

    public getSelectedRows() {
        if (!this.MultiselectEnabled) {
            return [];
        }

        return _.chain(this.SelectedIds())
            .map((rowId) => {
                return this.getRowById(rowId);
            })
            .reject((row: any) => {
                return isNullOrUndefined(row);
            })
            .value();
    }

    public generatePages() {
        if (!this.IsPaginationOn()) {
            return [];
        }

        const pages = generate(this.TotalRows(), this.CurrentPage(), this.ChosenPageSize());
        this.Pages(pages);

        return pages;
    }

    public preload() {
        let parsedColumns: any;

        if (this.isPreloaded) {
            return $.when();
        }

        this.IsLoading(true);

        if (isNotNullOrUndefined(this.options)) {
            loadPageSize(this.options.pageSizeStore, this);
        }

        return $.when(this.DataSource.loadColumns(this.DataQuery))
            .pipe((columns) => {
                parsedColumns = this.Parser.parseColumns(columns, this.options.defaultColumnWidth, this.options.tplCell);
                return loadColumnsVisible(this.options.columnVisibleStore, parsedColumns);
            })
            .done(() => {
                this.isPreloaded = true;
                this.Columns(parsedColumns);
                this.updateVisibleColumns();
                this.openPage(1);
            })
            .fail((jqXhr) => {
                backendErrorHandler.handleAnyError(jqXhr);
                this.IsLoading(false);
            });
    }

    public openPage(page: number) {
        this.IsLoading(true);
        this.DataQuery.page = page;

        return $.when(
            this.DataSource.load(this.DataQuery),
            this.DataSource.getTotalRows(this.DataQuery)
        )
            .always(() => {
                if (this.isDisposed) {
                    return $.Deferred().reject("GRID_DISPOSED");
                }
                this.CurrentPage(this.DataQuery.page);
            })
            .done((rows, totalRows) => {
                const parsedRows = this.Parser
                    .parseRows(this.VisibleColumns.peek(), getResultData(rows));

                this.destroyRowsOnOpenPage();

                if (this.MultiselectEnabled) {
                    this.multiselection.addCheckboxes(parsedRows);
                }

                this.Rows(parsedRows);
                this.TotalRows(getResultData(totalRows));
                if (isNullOrUndefined(this.NonfilteredTotalRows())) {
                    this.NonfilteredTotalRows(this.TotalRows());
                }

                this.generatePages();
            })
            .fail((jqXhr) => {
                backendErrorHandler.handleAnyError(jqXhr);
            })
            .always(() => {
                this.IsLoading(false);
            });
    }

    public openPrevPage() {
        const prevPage = this.CurrentPage() - 1;

        if (prevPage > 0) {
            this.openPage(prevPage);
        }
    }

    public openNextPage() {
        const nextPage = this.CurrentPage() + 1;

        if (nextPage <= this.LastPage()) {
            this.openPage(nextPage);
        }
    }

    public refresh() {
        const measurementNeeded = !!this.options.measureUxCategory;
        let performanceLog: any;

        if (measurementNeeded) {
            performanceLog = performanceLogFactory.start(this.options.measureUxCategory);
        }

        if (this.options.unselectAllRowsWhenRefresh) {
            this.SelectedIds([]);
            this.multiselection.selectedRows([]);
            this.multiselection.selectedRowsByIds = {};
        }

        return this.openPage(this.CurrentPage()).done(() => {
            if (measurementNeeded) {
                performanceLog.stop();
            }
        });
    }

    public searchByColumn(columnIndex: any, keywords: any) {
        if (columnIndex < 0) {
            return false;
        }

        const valueSource = this.resolveColumnValueSource(columnIndex);

        if (isEmptyString(keywords)) {
            delete this.DataQuery.keywords[valueSource];
        } else {
            this.DataQuery.keywords[valueSource] = keywords;
        }

        this.updateIsDataFiltered();
        this.openPage(1);
    }

    public updateIsDataFiltered() {
        const keywordsTotal = _(this.DataQuery.keywords).keys().length;
        this.IsDataFiltered(keywordsTotal > 0);
    }

    public sortByColumn(column: any, order: any): false | void {
        if (!column || isEmptyString(order) || !_.contains(["asc", "desc"], order)) {
            return false;
        }

        this.sortingColumn = column;
        this.sortingOrder = order;
        const valueSource = (column.OrderBySource) ? column.OrderBySource : column.ValueSource;

        this.DataQuery.sorting = {};
        this.DataQuery.sorting[valueSource] = order;

        this.refresh();
    }

    public resolveColumnValueSource(columnIndex: any) {
        const column = this.VisibleColumns()[columnIndex] || {};
        return column.ValueSource;
    }

    public resolveColumnValueSourceType(columnIndex: any) {
        const column = this.VisibleColumns()[columnIndex] || {};
        return column.ValueSourceType;
    }

    public destroyRows() {
        _(this.Rows.peek()).each((row) => {
            if (row.dispose) {
                row.dispose();
            }
        });
    }

    public destroyRowsOnOpenPage() {
        this.destroyRows();
    }

    public destroy() {
        if (this.DataSource) {
            this.DataSource.destroy();
        }

        this.destroyRows();
        this.Rows.removeAll();

        this.TotalRows.dispose();
        this.LastPage.dispose();
        this.IsSearchOn.dispose();
        this.IsSortingOn.dispose();
        this.Parser.dispose();
        this.IsPaginationOn.dispose();
        this.IsEmpty.dispose();
        this.IsDraggableRowsOn.dispose();
        this.TotalLoadedRows.dispose();
        this.SelectedRow(null);

        if (this.options) {
            this.options.onClickRow = null;
            this.options.onRemoveRow = null;
            this.options = null;
        }

        this.ChosenPageSizeSub.dispose();
        this.ChosenPageSize.dispose();
        this.DataDescription.dispose();

        if (this.MultiselectEnabled) {
            this.AllColumns.dispose();
            this.AllVisibleColumns.dispose();
            this.TotalSelectedRows.dispose();
            this.getRowSelectedObservable = null;
            this.markRow = null;
            this.toggleRow = null;
            this.SelectAll = null;
            this.toggleAll = null;
            this.SelectedIds = null;
            this.multiselection.destroy();
        }

        this.isPreloaded = false;
        this.DataSource = null;
        this.DataQuery = null;
        this.Columns = null;
        this.isDisposed = true;
    }
}

export function create(dataSource: any, options: any) {
    return new Grid(dataSource, options);
}
