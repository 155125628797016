/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/finalApprovalFraudPopup"/>
import * as ko from "knockout";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { getRiskDetails, canHandleRisks, getRiskStatus } from "./service";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { FinalApprovalPopupTemplate } from "./FinalApprovalPopupTemplate";
import { FinalApprovalPopupInconclusiveTemplate } from "./FinalApprovalPopupInconclusiveTemplate";
import { RiskFactorCode, RiskFactorReasonCode } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";


export class FinalApprovalFraudPopup {
    private Id : number;
    private HandleFinalApproveAction : () => void;

    private showFinalApprovalPopup: ko.Observable<boolean> = ko.observable();
    private showIsInconclusiveFinalApprovalPopup: ko.Observable<boolean> = ko.observable();
    private unhandledRiskFactors: ko.ObservableArray<any> = ko.observableArray();
    private riskStatus: ko.Observable<any> = ko.observable();

    constructor(documentId : number, handleFinalApproveAction: () => void) {
        this.Id = documentId;
        this.HandleFinalApproveAction = handleFinalApproveAction;
    }

    private onFinalApprovalPopupConfirm = () => {
        this.HandleFinalApproveAction();
        this.showFinalApprovalPopup(false);
    };

    private updateRiskDetails = async () => {
        try {            
            const riskFactors = (await getRiskDetails(this.Id)).invoiceDetails.riskFactors.filter(riskFactor => 
                riskFactor.code !== RiskFactorCode.FirstTimeInvoice && 
                riskFactor.code !== RiskFactorCode.FromEmailAddressChanged && 
                riskFactor.code !== RiskFactorCode.RandomizedExtraApproval && 
                (!riskFactor?.handledInfo || riskFactor?.handledInfo.reasonCode == RiskFactorReasonCode.NoReason));
            this.unhandledRiskFactors(riskFactors ?? []);
        }
        catch(error) {
            handleError(error);
        }
    };

    private updateRiskStatus = async() => {
        try {
            const riskStatus = await getRiskStatus(this.Id);
            this.riskStatus(riskStatus);
        }
        catch(error) {
            handleError(error);
        }
    };

    private handlePopupsWithRiskStatus = () => {
        if(this.riskStatus()) {
            this.handlePopups();
        } else {
            this.updateRiskStatus()
            .then(() => {
                this.handlePopups();
            })
            .catch((error:any) => {
                handleError(error);
            });
        }
    };

    private handlePopups = () => {
        this.updateRiskDetails()
        .then(() => {
            if(this.unhandledRiskFactors().length > 0) {
                this.handlePopupsWhenInvoiceHasRiskFactors();
            }
            else {
                this.handlePopupsWhenInvoiceHasNoRiskFactors();
            }
        })
        .catch((error:any) => {
            handleError(error);
        });
    };

    private handlePopupsWhenInvoiceHasRiskFactors = () => {
        if(this.riskStatus()?.isInconclusive) {
            this.showIsInconclusiveFinalApprovalPopup(true);
            return;
        }
        this.handleFinalApprovalPopup();
    };
    
    private handleFinalApprovalPopup = () => {
        this.canUserSeePopup(this.Id)
        .then(canDisplayPopup => {
            if(!canDisplayPopup) {
                this.HandleFinalApproveAction();
                return;
            }
            this.showFinalApprovalPopup(true);
        })
        .catch((error:any) => {
            handleError(error);
        });
    };
    
    private handlePopupsWhenInvoiceHasNoRiskFactors = () => {
        if(this.riskStatus()?.isInconclusive) {
            this.showIsInconclusiveFinalApprovalPopup(true);
            return;
        }
        this.HandleFinalApproveAction();
    };
    
    private canUserSeePopup = (taskId: number) => {
        const isFraudAndRiskProtectionEnabled = store.getState().accesses.enabledModules.includes(Module.FraudAndRiskProtection);
        if(!isFraudAndRiskProtectionEnabled) {
            return Promise.resolve(false);
        }
        return canHandleRisks(taskId)
            .then(hasAccessKey => {
                return hasAccessKey;
            })
            .catch(error => {
                handleError(error);
            });
    };
    
    private canUserSeeInconclusivePopup = (taskId: number) => {
        const isFraudAndRiskProtectionEnabled = store.getState().accesses.enabledModules.includes(Module.FraudAndRiskProtection);
        if(!isFraudAndRiskProtectionEnabled) {
            return Promise.resolve(false);
        }
        return canHandleRisks(taskId)
            .then(_ => {
                return true;
            })
            .catch(error => {
                handleError(error);
            });
    };

    public onFinalApproveButton = () => {
        this.canUserSeeInconclusivePopup(this.Id)
            .then(canDisplayPopup => {
                if(!canDisplayPopup) {
                    this.HandleFinalApproveAction();
                    return;
                }
                this.handlePopupsWithRiskStatus();
            })
            .catch(error => {
                handleError(error);
            }); 
    };

    public FinalApprovalPopup = ko.pureComputed(() => {
        return ({
            functionComponent: FinalApprovalPopupTemplate,
            props: {
                    showPopup: this.showFinalApprovalPopup(),
                    onConfirm: this.onFinalApprovalPopupConfirm,
                    onCancel: () => this.showFinalApprovalPopup(false),
                    unhandledRiskFactors: this.unhandledRiskFactors()
                }
            });
        });
    
    public FinalApprovalPopupInconclusive = ko.pureComputed(() => {
        return ({
            functionComponent: FinalApprovalPopupInconclusiveTemplate,
            props: {
                    showPopup: this.showIsInconclusiveFinalApprovalPopup(),
                    onConfirm: () => {
                        if(this.unhandledRiskFactors().length > 0) {                            
                            this.showIsInconclusiveFinalApprovalPopup(false);
                            this.handleFinalApprovalPopup();
                        }
                        else {
                            this.showIsInconclusiveFinalApprovalPopup(false);
                            this.HandleFinalApproveAction();
                        }
                    },
                    onCancel: () => this.showIsInconclusiveFinalApprovalPopup(false),
                }
            });
        });
}