/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/currentCompanySlice"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Company, CompanyConfiguration, CurrentCompany, ProcurementConfiguration } from "./configuration";

const initialState: CurrentCompany = {
  company: undefined,
  configuration: { activeDimensions: [], taxIndicators: [], restrictUsageToLeafLevelCategoriesOnly: false },
  procurementConfiguration: {
    availableRequisitionTypes: [], categoryPreCodingActive: false,
    defaultRequisitionType: null,
    isAccessToPunchOutCatalogsAllowed: false,
    isCreatingFreeTextItemsAllowed: false,
    isOnlyOneSupplierAllowed: false,
    totalAmountAsDefault: false,
    defaultFreeTextForm: 0,
    storeAmountInUnitPriceForServiceLines: false,
    allowForSplitCoding: false
  }
};

export const currentCompanySlice = createSlice({
  name: 'currentCompany',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setCompanyConfiguration: (state, action: PayloadAction<CompanyConfiguration>) => {
      state.configuration = action.payload;
    },
    setProcurementConfiguration: (state, action: PayloadAction<ProcurementConfiguration>) => {
      state.procurementConfiguration = action.payload;
    },
    clearCompanyConfiguration: (state) => {
      state.configuration = initialState.configuration;
    },
    clearProcurementConfiguration: (state) => {
      state.procurementConfiguration = initialState.procurementConfiguration;
    }
  }
});

export const { set,
  setCompanyConfiguration, setProcurementConfiguration,
  clearCompanyConfiguration, clearProcurementConfiguration } = currentCompanySlice.actions;

export function currentCompanySelector(s: RootState) { return s.procurement.currentCompany.company; }
export function currentCompanyConfigurationSelector(s: RootState) { return s.procurement.currentCompany.configuration; }
export function currentProcurementConfigurationSelector(s: RootState) { return s.procurement.currentCompany.procurementConfiguration; }
