///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/CompanyTree/companyTree"/>

import * as React from "react";
import { useEffect, useState } from "react";
import { Card, Company, CompanyTreeNode, CompanyTreeView, TreeViewDataMethodEnum } from "@medius/ui-controls";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface CompanyTreeResponseNode {
  internalId: number,
  companyId: string,
  globalId: string,
  name: string,
  isActive: boolean,
  descendants: CompanyTreeResponseNode[]
}

const getCompanyIdFromUrl = () => {
  const segments = window.location.href.split('/');
  const lastSegment = segments[segments.length - 1];
  const parsed = parseInt(lastSegment);
  return isNaN(parsed) ? undefined : parsed;
};

const setCompanyIdInUrl = (id: string) => {
  const currentUrl = window.location.href;
  const updatedUrl = currentUrl.replace(/\/\d+/, '') + `/${id}`;
  window.location.assign(updatedUrl);
};

const mapToCompanyTreeNode = (descendants: CompanyTreeResponseNode[]): CompanyTreeNode[] => {
  return descendants.map(node => ({
    companyId: node.companyId,
    globalId: node.globalId,
    companyTreeIdentifier: node.internalId,
    name: node.name,
    isActive: node.isActive,
    descendants: mapToCompanyTreeNode(node.descendants)
  }));
};

export function CompanyTree() {
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(getCompanyIdFromUrl());

  // useEffect(() => {
  //   const handleUrlChange = () => setSelectedCompanyId(getCompanyIdFromUrl());

  //   window.addEventListener('hashchange', handleUrlChange);

  //   return () => {
  //     window.removeEventListener('hashchange', handleUrlChange);
  //   };
  // }, []);

  useEffect(() => {
    if (selectedCompany) setCompanyIdInUrl(selectedCompany?.companyTreeIdentifier.toString());
  }, [selectedCompany]);

  const getCompanyTree = async (): Promise<CompanyTreeNode[]> => {
    try {
      const response = await get("remedium-api/companies/tree");
      const json = await response.json();
      return mapToCompanyTreeNode([json]);
    }
    catch (e) {
      handleError(e);
    }
  };

  return (
    <Card>
      <CompanyTreeView
        description={translate("#Core/company.treeViewDescription")}
        dataMethodType={TreeViewDataMethodEnum.AllItemsAsynchronous}
        getAllItems={getCompanyTree}
        selectedCompanyTreeIdentifier={selectedCompanyId}
        onSelectedCompanyChanged={setSelectedCompany}
        data-testid="company-tree"
      />
    </Card>
  );
}
