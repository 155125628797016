/// <amd-module name="Core/Medius.Core.Web/Scripts/appConfigData"/>

export const applicationVersion = "applicationVersion";
export const installedAppsHash = "installedAppsHash";
export const userVoiceEnabled = "userVoiceEnabled";
export const qaEnvironmentNoteVisible = "qaEnvironmentNoteVisible";
export const currentAppDisplayVersion = "currentAppDisplayVersion";
export const deploymentName = "deploymentName";
export const pdfJsVersion = "PdfJsVersion";

export const applicationInsightsInstrumentationKey = "applicationInsightsInstrumentationKey";
export const applicationInsightsSamplingPercentage = "applicationInsightsSamplingPercentage";

export const documentationUrl = "documentationUrl";
export const advancedSearchOperatorsDocumentationUrl = "advancedSearchOperatorsDocumentationUrl";
export const releaseNotesUrl = "releaseNotesUrl";
export const benchmarksUrl = "benchmarksUrl";
export const privacyPolicyUrl = "privacyPolicyUrl";
export const logOffUrl = "logOffUrl";
export const switchProviderUrl = "switchProviderUrl";
export const supportPortalUrl = "supportPortalUrl";
export const homePageSurveyUrl = "homePageSurveyUrl";
export const cfoDashboardDocumentationUrl = "cfoDashboardDocumentationUrl";
export const knownIssuesUrl = "KnownIssuesUrl";

export const ccfBaseUrl = "ccfBaseUrl";
export const ccfHost = "ccfHost";
export const payAppUrl = "payAppUrl";
export const simAppUrl = "simAppUrl";
export const analyticsAppUrl = "analyticsAppUrl";
export const loginApiUrl = "loginApiUrl";

export const lockManagerRepeatTime = "lockManagerRepeatTime";
export const lockManagerStartingAquiresNumber = "lockManagerStartingAquiresNumber";

export const isAccessManagementEnabled = "IsAccessManagementEnabled";
export const MigratedWebApiEndpointsList = "MigratedWebApiEndpointsList";
export const CanduToken = "CanduToken";
export const isEmbedded = "IsEmbedded";