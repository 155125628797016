///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CopilotCommentsTooltip" />
import * as React from "react";
import { 
    Button
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { CopilotButton } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton";
import { MediusCopilotInteractionType, trackInteractionEvent } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights";
import { sendCommentsSummaryRequestAndStreamResponse } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { ICommentToSummarize, IMessage, Role } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { TimeoutErrorMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/TimeoutErrorMessage";
import { messageHasAnythingToDisplay } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { AiThinkingIcon } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AiThinkingIcon";
import { CommentsSummaryMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CommentsSummaryMessage";
import { copilotEvents } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";

interface ICopilotCommentsTooltipProps {
    documentId: number;
    comments: ICommentToSummarize[];
}

export function CopilotCommentsTooltip({ documentId, comments }: ICopilotCommentsTooltipProps) {
    const [isStreamActive, setIsStreamActive] = React.useState(false);
    const [isTimeoutError, setIsTimeoutError] = React.useState(false);
    const [processedMessage, setProcessedMessage] = React.useState<IMessage>();
    const [areCommentsTranslated, setAreCommentsTranslated] = React.useState(false);
    const [areCommentsBeeingTranslated, setAreCommentsBeeingTranslated] = React.useState(false);

    React.useEffect(() => {      
        const handleMessage = (event: MessageEvent) => {
            const { type } = event.data;

            switch (type) {
                case copilotEvents.TranslateComments:
                    setAreCommentsBeeingTranslated(true);
                    break;
                case copilotEvents.CommentsTranslated:
                    setAreCommentsTranslated(true);
                    setAreCommentsBeeingTranslated(false);
                    break;
                case copilotEvents.ShowOriginalComments:
                    setAreCommentsTranslated(false);
                    setAreCommentsBeeingTranslated(false);
                    break;
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const sendTranslateCommentsEvent = () => {
        window.postMessage({ type: copilotEvents.TranslateComments }, "*");
        setAreCommentsBeeingTranslated(true);
    };

    const sendHideCommentsTranslationEvent = () => {
        window.postMessage({ type: copilotEvents.ShowOriginalComments }, "*");
        setAreCommentsTranslated(false);
    };


    const handleStreamEnd = () => {
        setIsStreamActive(false);
    };

    const addErrorMessage = () => {
        setProcessedMessage({
            isError: true,
            content: "",
            role: Role.assistant,
            conversationId: null,
            documentId: documentId,
            date: new Date().toISOString(),
            sources: [],
            taskId: null
        });
    };

    const handleMessageChunk = (message: IMessage) => {
        setProcessedMessage({
            ...message,
            content: getLabelTranslation(message.content),
            role: Role.assistant,
            conversationId: null,
            documentId: documentId,
            taskId: null,
        });
    };

    const sendChatRequest = async (
    ) => {
        setIsStreamActive(true);
        setIsTimeoutError(false);
        setProcessedMessage(null);

        const abortControllerInstance = new AbortController();

        const timeout = setTimeout(() => {
            abortControllerInstance.abort();
            handleStreamEnd();
            setIsTimeoutError(true);
        }, 60000);

        try {
            await sendCommentsSummaryRequestAndStreamResponse(
                documentId,
                comments,
                handleMessageChunk,
                handleStreamEnd,
                abortControllerInstance.signal,
                timeout,
            );
        } catch (error) { addErrorMessage(); }
    };

    return (<>
        <div className="copilotCommentsButtons__buttons">
            <CopilotButton 
                close-ai-agent-modal="false"
                isMainCopilotButton={false} 
                data-testid="" 
                label={getLabelTranslation("#Core/copilotSummarize")} 
                onClick={() => {
                    trackInteractionEvent(MediusCopilotInteractionType.CommentsTooltipButtonClick);
                    sendChatRequest();
                }}
            />
            {areCommentsBeeingTranslated ? (
                <Button
                    close-ai-agent-modal="false"
                    label={getLabelTranslation("#Core/copilotCommentsAreBeeingTranslated")} 
                    variant="tertiary"
                    disabled={true}
                />
            ) : areCommentsTranslated ? (
                <Button
                    close-ai-agent-modal="false"
                    label={getLabelTranslation("#Core/copilotHideTranslations")} 
                    variant="tertiary"
                    onClick={() => {
                        trackInteractionEvent(MediusCopilotInteractionType.CommentsTooltipButtonClick);
                        sendHideCommentsTranslationEvent();
                    }}
                />
            ) : (
                <CopilotButton 
                    close-ai-agent-modal="false"
                    isMainCopilotButton={false} 
                    data-testid="" 
                    label={getLabelTranslation("#Core/copilotTranslate")} 
                    onClick={() => {
                        trackInteractionEvent(MediusCopilotInteractionType.CommentsTooltipButtonClick);
                        sendTranslateCommentsEvent();
                    }}
                />
            )}
        </div>
        {(processedMessage || isStreamActive || isTimeoutError) && 
            <div className="copilotCommentsButtons__answerContainer">
                <div className="copilotCommentsButtons__answerContainerInner">
                { isTimeoutError && <TimeoutErrorMessage/> }
                { (isStreamActive && !processedMessage?.content) && 
                    <div className="copilotCommentsButtons__loader">
                        <AiThinkingIcon />
                    </div>
                }
                { messageHasAnythingToDisplay(processedMessage) &&
                    <CommentsSummaryMessage
                        message={processedMessage} 
                    />
                }
                </div>
            </div>
        }
    </>);
}